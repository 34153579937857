import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Autocomplete,
    Backdrop,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    DialogContentText,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import Axios from "../../../../utils/Axios";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { gridSpacing } from "../../../../utils/store/constant";
import { TaskListHead, TaskListToolbar } from "../../sections/@dashboard/Task";

import { useSelector } from "react-redux";




const TABLE_HEAD = [
    { id: "fullname", label: "Họ tên", alignRight: false, },
    { id: "email", label: "Email", alignRight: false },
    { id: "199", label: "", alignRight: true },

];


const CustomButton = styled
    (Button)(({ theme }) => ({
        backgroundColor: '#5351ed', // màu nền button
        color: 'white', // màu chữ button
        fontWeight: 'bold',
        borderRadius: 15,
        marginBottom: 18,
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#f7f9fa', // màu nền khi hover vào button
            color: 'red', // màu chữ khi hover vào button
        },
    }));

const scrollbar = {
    "::-webkit-scrollbar": {
        width: "5px",
    },
    ":hover::-webkit-scrollbar-thumb": {
        " -webkit-border-radius": "5px",
        borderRadius: "5px",
        background: "#dee2e3",
    },
    "::-webkit-scrollbar-thumb:window-inactive": {
        background: "#dee2e3",
    },
};


export const DialogMember = ({
    open,
    setOpen,
    project
}) => {

    const [showLoading, setShowLoading] = useState(false);
    const [listUserId, setListUserId] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [listUserInProject, setListUserInProject] = useState([]);
    const [selected, setSelected] = useState([]);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [countPage, setCountPage] = useState(0);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [idProject, setIdProject] = useState(project?._id);
    const [selectedOne, setSelectedOne] = useState([]);
    const [user, setUser] = useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogDetail, setOpenDialogDetail] = React.useState(null);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const userLoginDetail = useSelector((state) => state.userLoginDetail.data);


    const handleReset = () => {
        setListUserId([]);
        setSelectedValue([]);
    };



    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        handleReset();
    }, [])

    useEffect(() => {
        getAllUser(project._id, 0, 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [inputValues, setInputValues] = useState({
        listUser: "",
    });

    const [errors, setErrors] = useState({
    });

    const handleOnInput = (event) => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const getAllUser = async (id, page, limit, sortOrder, property) => {
        setShowLoading(() => true);
        const response = await Axios.WorkProject.getListUserNotInWorkProject(
            id,
            page,
            limit,
            sortOrder,
            "",
            property
        );

        if (response.status === 200) {
            setShowLoading(() => false);
            setListUser(response.data.listItem);
            setPage(page);
            setLimit(limit);
        } else {
            setShowLoading(() => false);
            if (response.status === 403) {
                toast.error("Không có quyền xem!");
            } else {
                toast.error("Lấy danh sách người dùng thất bại!");
            }

        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getListUserInProject(project._id, newPage, 10, -1, "");
    };

    const handleChangeRowsPerPage = (event) => {
        getListUserInProject(project._id, 0, event.target.value, -1, "");
        setLimit(parseInt(event.target.value, 10));
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        let validForm = true;

        if (listUserId.length === 0) {
            setErrors({ listUser: "Thành viên phải bắt buộc nhập!" })
            validForm = false;
        } else {

        }

        if (validForm) {
            addMember();
        } else {
            toast.error("Vui lòng điền đầy đủ thông tin!");
        }
    };

    const addMember = async () => {
        setShowLoading(() => true);
        let data = {};
        data.workProjectId = project._id;
        data.listUserId = listUserId;
        const response = await Axios.WorkProject.addMember(data);
        if (response.status === 200) {
            setShowLoading(() => false);
            handleReset();
            toast.success("Thêm thành viên thành công");
            getAllUser(project._id, page, limit);
            getListUserInProject(project._id, page, limit);
        } else {
            toast.error("Thêm thành viên thất bại!");
            setShowLoading(() => false);

        }
    };

    const getListUserInProject = async (id, page, limit, sortOrder, property) => {
        setShowLoading(() => true);
        const response = await Axios.WorkProject.getListUserInProject(
            id,
            page,
            limit,
            sortOrder,
            "",
            property
        );
        if (response.status === 200) {
            setShowLoading(() => false);
            setSelected([]);
            setListUserInProject(response.data);
            setCountPage(parseInt(response.data.totalItem));
        } else {
            setShowLoading(() => false);
            toast.error("Lấy danh sách dự án thất bại!");
        }
    };


    const handleChangeListUser = (target) => {
        let arr = [];
        target.forEach(element => {
            let id = element.split("_")[0];
            arr.push(id)
        });
        if (arr.length > 0) {
            setErrors({})
        }
        setListUserId(arr);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = ((orderBy === property || orderBy !== property) && order === "asc");
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = listUserInProject?.listItem?.map((n) => n._id);
            setSelected(newSelecteds.filter(id => id !== userLoginDetail._id));
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, _id) => {

        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        if (userLoginDetail._id !== _id) {
            setSelected(newSelected);
        }
    };

    const handleOpenMenu = (event, value) => {
        let arr = [];
        setOpenDialogDetail(event.currentTarget);
        arr.push(value._id);
        setSelectedOne(arr);
        setUser(value.user);
    };

    const handleCloseMenu = () => {
        setOpenDialogDetail(null);
    };

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteUser = async () => {
        setShowLoading(() => true);
        let data = {};
        data.workProjectId = project._id;
        data.listUserId = selectedOne;
        const response = await Axios.WorkProject.removeUserFromProject(data);
        if (response.status === 200) {
            setShowLoading(() => false);
            getListUserInProject(idProject, page, limit, -1, "");
            setOpenDialogDetail(false);
            setOpenDialog(false);
            handleReset();
            getAllUser(project._id, page, limit);
            toast.success("Xoá thành viên thành công!");
        } else {
            setShowLoading(() => false);
            toast.error("Xoá thành viên thất bại!");
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="1000">
                <Grid container spacing={gridSpacing} sx={{ maxHeight: 700 }}>
                    <Grid item xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} sm={6} md={12}>
                                <form onSubmit={handleOnSubmit}>
                                    <DialogTitle sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800 }}>Thêm thành viên - ( {project?.name} )</DialogTitle>
                                    <Divider />

                                    <DialogContent>
                                        <DialogContentText />
                                        <Grid container spacing={2} sx={{ width: 1300, minWidth: 1100, }}>
                                            <Grid item xs={11}>
                                                <Autocomplete
                                                    name="listUser"
                                                    multiple
                                                    options={listUser.map((option) => option._id + '_' + option.fullname + ' - ( ' + option.email + ' )')}
                                                    value={selectedValue}
                                                    freeSolo
                                                    renderOption={(props, option) => {
                                                        const parts = option.split("_"); // Tách theo dấu "_"
                                                        // const _id = parts[0];

                                                        const nameAndEmail = parts[1];

                                                        const nameAndEmailParts = nameAndEmail.split("-"); // Tách theo dấu "-"
                                                        const fullname = nameAndEmailParts[0]; // Chuỗi name là phần tử đầu tiên trong mảng nameAndEmailParts
                                                        const email = nameAndEmailParts[1];
                                                        return (
                                                            <li style={{ fontSize: '14px' }} {...props}>
                                                                {fullname} &nbsp;
                                                                <span >{email}</span>
                                                                <p></p>
                                                            </li>

                                                        );
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedValue(newValue);
                                                        handleChangeListUser(newValue);
                                                    }}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip
                                                                label={option.split("_")[1]} {...getTagProps({ index })} color="default" />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name="listUser"
                                                            {...params}
                                                            variant="standard"
                                                            label="Thêm thành viên"
                                                            sx={{ ml: 3.5, width: '95%', }}
                                                            error={errors.listUser ? true : false}
                                                            onInput={handleOnInput}
                                                            helperText={
                                                                errors.listUser ? (
                                                                    errors.listUser
                                                                ) : (
                                                                    <Box component="span" sx={{ color: "white" }}>
                                                                        &emsp;
                                                                    </Box>
                                                                )
                                                            }
                                                        />
                                                    )}

                                                    sx={{ width: '100%' }}

                                                />

                                            </Grid>
                                            <Grid item xs={1} sx={{ mt: 0.5 }}>
                                                <CustomButton type="submit" variant="contained"
                                                    sx={{ float: 'right', paddingLeft: 3 }}
                                                >
                                                    <Iconify icon={"material-symbols:add"} />
                                                    Thêm
                                                </CustomButton>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ mt: -1.5, height: 85 }}>
                                            <TaskListToolbar
                                                numSelected={selected.length}
                                                selected={selected}
                                                onChange={getListUserInProject}
                                                page={page}
                                                limit={limit}
                                                idProject={idProject}
                                                getAllUser={getAllUser}
                                            />
                                        </Grid>
                                        <TableContainer
                                            sx={{
                                                minWidth: 10,
                                                maxHeight: 375,
                                                ...scrollbar,
                                                borderRadius: 2,
                                                borderRight: 1,
                                                borderLeft: 1,
                                                borderBottom: 1,
                                                borderColor: "#dee2e3",
                                                ml: 2.5,
                                                width: "97.5%"
                                            }}
                                        >
                                            <Table>
                                                <TaskListHead
                                                    headLabel={TABLE_HEAD}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    rowCount={listUserInProject?.listItem?.length}
                                                    numSelected={selected?.length}
                                                    onRequestSort={handleRequestSort}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    onChange={getListUserInProject}
                                                    page={page}
                                                    limit={limit}
                                                    idProject={idProject}
                                                />
                                                <TableBody >
                                                    {listUserInProject?.listItem?.map((row) => {
                                                        const { _id, fullname, email } = row;
                                                        const selectedUser = selected.indexOf(_id) !== -1;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                key={_id}
                                                                tabIndex={-1}
                                                                role="checkbox"
                                                                selected={selectedUser}
                                                                sx={{ cursor: "pointer" }}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    {userLoginDetail._id !== _id ? <Checkbox
                                                                        checked={selectedUser}
                                                                        onChange={(event) => handleClick(event, _id)}
                                                                    /> : ""}

                                                                </TableCell>

                                                                <TableCell
                                                                    align="center"
                                                                    component="th"
                                                                    scope="row"
                                                                    padding="none"
                                                                    style={{ width: 140 }}
                                                                    onClick={(event) => handleClick(event, _id)}
                                                                >
                                                                    <Typography
                                                                        sx={{ marginLeft: 3, whiteSpace: "pre-line" }}
                                                                    >
                                                                        {fullname}
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell
                                                                    onClick={(event) => handleClick(event, _id)}
                                                                    style={{ width: 10, whiteSpace: "pre-line" }}
                                                                    align="center"
                                                                >
                                                                    {email}
                                                                </TableCell>
                                                                <TableCell align="right" sx={{ width: "5%" }}>
                                                                    {userLoginDetail._id !== _id ? <IconButton
                                                                        size="large"
                                                                        color="inherit"
                                                                        onClick={(e) => handleOpenMenu(e, row)}
                                                                    >
                                                                        <Iconify icon={"eva:more-vertical-fill"} />
                                                                    </IconButton> : ""}

                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {listUserInProject?.listItem?.length !== 0 ?
                                            <TablePagination
                                                sx={{ float: "left", mt: -2 }}
                                                rowsPerPageOptions={[10, 25, 50]}
                                                component="div"
                                                count={countPage}
                                                rowsPerPage={limit}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                labelRowsPerPage="Số hàng mỗi trang"
                                            />
                                            : <Box sx={{ marginTop: '40px', marginLeft: '20px', textAlign: 'center', fontSize: '20px', width: "98.5%", height: '100px', }}>Danh sách trống</Box>}
                                        <DialogActions sx={{ p: "0 24px 12px 24px" }}>
                                            <CustomButton
                                                sx={{ background: "#f72533", mt: 10, position: 'fixed' }}
                                                onClick={handleClose} variant="contained">Hủy</CustomButton>
                                        </DialogActions>


                                    </DialogContent>



                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Popover
                    open={Boolean(openDialogDetail)}
                    anchorEl={openDialogDetail}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                        sx: {
                            p: 1,
                            width: 180,
                            "& .MuiMenuItem-root": {
                                px: 1,
                                typography: "body2",
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >
                    <MenuItem onClick={handleClickOpen} sx={{ color: "red !important" }}>
                        <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                        Xoá
                    </MenuItem>

                </Popover>

            </Dialog>

            <div>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle width={400} id="alert-dialog-title">
                        <Typography
                            component="div"
                            sx={{ fontSize: 20 }}
                            variant="subtitl1"
                        >
                            {"Xác nhận xoá?"}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography
                                component="span"
                                sx={{ fontSize: 20, fontFamily: "bold", color: 'black' }}
                            >
                                <span style={{ color: "red", fontWeight: "bold" }}>
                                </span>{" "}
                                {user?.fullname}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButton onClick={deleteUser}>Xác nhận</CustomButton>
                        <CustomButton onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
                            Huỷ
                        </CustomButton>
                    </DialogActions>
                </Dialog>
            </div>



            <Backdrop
                sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color="primary" sx={{ zIndex: 9 }} />
            </Backdrop>
        </>
    );
};





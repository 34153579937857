import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import Axios from "../../../../utils/Axios";
import { toast } from "react-toastify";
import useValidator from "../../../../utils/Validator";
import DateTimeOfDay from "../../../../utils/DateTimeOfMessage/DateTimeOfDay";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";


const CustomButton = styled
  (Button)(({ theme }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));


const styleInputFullField = {
  width: "100%",
  mb: 3,
  ml: 2,
};

const content = [
  { content: "Đủ ngày công", value: "X" },
  { content: "Nửa ngày công", value: "X/2" },
  { content: "Nghỉ phép", value: "P" },
  { content: "Học tập, công tác", value: "H" },
  { content: "Thai sản", value: "ThS" },
  { content: "Không lương", value: "KL" },
  { content: "Remote", value: "R" },
];

const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      {children}
    </Grid>
  );
};

export const TimekeepingDialog = ({
  open = false,
  setOpen,
  timekeeping,
  onChange,
  month,
  validateNote,
  type
}) => {

  useEffect(() => {
    setErrors({
      content: "",
    })
    setTimekeepingReg({
      note: "",
    })
  }, [timekeeping])

  const { validateTimeKeeping } = useValidator();
  
  const [timekeepingReg, setTimekeepingReg] = useState({
    date: "",
    content: "",
    note: "",
  });


  const [showLoading, setShowLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    date: "",
    content: "",
    note: "",
  });

  const [errors, setErrors] = useState({
    date: "",
    content: "",
    note: "",
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateTimeKeeping(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      attenDance();
    } else {

    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateTimeKeeping(name, value) });
  };

  const attenDance = async () => {
    setShowLoading(() => true);
    timekeepingReg.date = timekeeping;
    const response = await Axios.Timekeeping.attenDance(timekeepingReg);
    if (response.status === 200) {
      onChange(month,type);
      toast.success("Điểm danh thành công");
      setOpen(false);
      setShowLoading(() => false);
    } else {
      setShowLoading(() => false);
      toast.error("Điểm danh thất bại!");
    }
  };

  const handleChange = (e, target) => {

    setTimekeepingReg({
      ...timekeepingReg,
      content: target?.value,
    });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} >
        <form onSubmit={handleOnSubmit}>
          <DialogTitle sx={{ textAlign: 'center', fontSize: 17, fontWeight: 800 }}>
            Chấm công - ( <DateTimeOfDay dateTime={timekeeping} /> )
          </DialogTitle>
          <Divider />
          <DialogContent >
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 500 }}>
              <LayoutFormTwoField>
                <Grid item xs={12}>
                  <Autocomplete
                    name="listcontent"
                    options={content}
                    getOptionLabel={(option) => option.content}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        name="content"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"bx:code-block"} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        variant="standard"
                        label="Nội dung"
                        placeholder="Chọn nội dung"
                        sx={styleInputFullField}
                        error={errors.content ? true : false}
                        helperText={
                          errors.content ? (
                            errors.content
                          ) : (
                            <Box component="span" sx={{ color: "white" }}>
                              &emsp;
                            </Box>
                          )
                        }
                      />
                    )}

                  />
                </Grid>
              </LayoutFormTwoField>
              <Box >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</Box>
              <LayoutFormTwoField>
                <Grid item xs={12} sx={{mt:-3}}>
                  <TextField
                    name={validateNote}
                    label="Ghi chú"
                    placeholder="Nhập Ghi Chú"
                    multiline
                    maxRows={5}
                    value={timekeepingReg?.note}
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"material-symbols:edit-note-sharp"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setTimekeepingReg({
                        ...timekeepingReg,
                        note: e.target.value,
                      });
                    }}
                    autoComplete="none"
                    sx={styleInputFullField}
                    error={errors.note ? true : false}
                    helperText={
                      errors.note ? (
                        errors.note
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>
              </LayoutFormTwoField>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton type="submit" variant="contained"> Điểm danh</CustomButton>
            <CustomButton
              sx={{ background: "#f72533" }}
              onClick={handleClose} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

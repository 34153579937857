import { useState } from "react";
import Axios from "./../../utils/Axios";

export default function useLogin() {
  const getAccount = () => {
    let accountString = localStorage.getItem("account");
    const account = JSON.parse(accountString);
    return account;
  };

  const [account, setAccount] = useState(getAccount());

  const saveAccount = async (account, email, password, remember) => {
    if (!account) {
      return;
    }
    account.remember = remember;
    if (remember === true) {
      account.email = email;
      account.password = password;
    }
    account.status = true;
    localStorage.setItem("account", JSON.stringify(account));
    setAccount(account);
    return;
  };

  const logout = () => {

    if (account.remember === false) {
      localStorage.removeItem("account");
    } else {
      let data = {}
      data.email = account.email
      data.password = account.password
      data.status = false;
      data.remember = account.remember;
      localStorage.setItem("account", JSON.stringify(data));
      setAccount(account);
    }
    Axios.User.logout();
    window.location = "/login";
  };

  return {
    account,
    setAccount: saveAccount,
    logout,
  };
}

import Axios from "./../../../../utils/Axios/Axios";

class TimekeepingManagement {
  
      static getAll = async (month,page,limit,sortOrder,search,sortProperty)=>
      Axios.get("/api/v1/report/timekeeping",{
        month,page,limit,sortOrder,search,sortProperty
      });

  }
  
export default TimekeepingManagement;
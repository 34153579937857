import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../../../components/iconify/Iconify';
import useValidator from '../../../utils/Validator';
import React, { useState } from "react";
import Axios from "../../../utils/Axios/Axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useLogin from '../../../utils/Login/useLogin';


const steps = ['Tìm tài khoản', 'Đặt lại mật khẩu', 'Nhập mã bảo mật'];


const styleInputFullField = {
  width: "100%",
  mt: 4,
 
};

export default function ForgotPasswordFrom() {
  const { logout } = useLogin();

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const { validate } = useValidator();
  const [user, setUser] = useState({});
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [second, setSecond] = useState(4);
  const navigate = useNavigate();



  const [buttonsDisabled, setButtonsDisabled] = useState([
    false,
    true,
    true,
  ]); // initial state: only first button is enabled

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    const newButtonsDisabled = buttonsDisabled.map((button, index) => {
      if (index === activeStep) {
        return true;
      } else if (index === activeStep + 1) {
        return false;
      } else {
        return button;
      }
    });
    setButtonsDisabled(newButtonsDisabled);

    handleNext();
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validate(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));
    if (validForm) {
      if (user.newPassword !== user.confirmpassword) {
        setErrors({
          confirmpassword: "Mật khẩu không khớp!",
        });
        return;
      } else {
        if (activeStep === 0) {
          accountSearch();
        }
        if (activeStep === 1) {
          confirmEmail();
        }
        if (activeStep === 2) {
          resetPassword();
        }
      }
    }

  }

  const accountSearch = async () => {
    const response = await Axios.postNoToken("/api/v1/auth/forgotPassword", { email: inputValues.email });
    if (response.data.status === 200) {
      handleComplete();
    } else {
      if (response.data.status === 400 && response.data.code === 5) {
        setErrors({ email: "Không tìm thấy người dùng!" })
      }
    }
  }

  const confirmEmail = async () => {
    let data = {};
    data.email = inputValues.email;
    data.code = inputValues.code;
    const response = await Axios.postNoToken("/api/v1/auth/confirmEmail", data);
    if (response.data.status === 200) {
      localStorage.removeItem("account");
      let account = {};
      let accessToken = response.data.data.accessToken;
      let refreshToken = response.data.data.refreshToken;
      account.accessToken = accessToken;
      account.refreshToken = refreshToken;
      localStorage.setItem("account", JSON.stringify(account));
      handleComplete();
    } else {
      if (response.data.status === 400 && response.data.code === 18) {
        setErrors({ code: "Mã để đặt lại mật khẩu sai hoặc hết hạn!" })
      }
    }
  }

  const resetPassword = async () => {
    const response = await Axios.put("/api/v1/auth/resetPassword", { newPassword: inputValues.newPassword });
    console.log('response- ', response)
    if (response.status === 200) {
      handleComplete();
      let secondsLeft = 4; // số giây cần giảm
      const countdown = setInterval(() => {
        if (secondsLeft > 0) {
          secondsLeft--; // giảm số giây
          setSecond(secondsLeft)
        } else {
          localStorage.removeItem("account");
          logout();
          clearInterval(countdown); // dừng hàm setInterval()
        }
      }, 1000); // thực hiện mỗi giây (1000ms)
      toast.success("Đặt lại mật khẩu thành công!");

    } else {
    }
  }

  const [inputValues, setInputValues] = useState({
    email: "",
    code: "",
    newPassword: "",
    confirmpassword: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    code: "",
    confirmpassword: "",
    newPassword: "",
  });


  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validate(name, value) });
  };


  const handleClick = () => {
    localStorage.removeItem("account");
    navigate(`/`);
  };


  return (
    <form onSubmit={handleOnSubmit}>

      <Box sx={{ width: '100%' }}>

        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                color="inherit"
                onClick={handleStep(index)}
                disabled={buttonsDisabled[index]} // disable button if necessary
              >
                <span style={{ color: 'black' }}>{label}</span>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ color: 'red', mt: 3, fontSize: 18, textAlign: 'center' }}>
                {/* Đặt lại mật khẩu thành công  */}
                Quay lại trang đăng nhập sau {second} giây
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep + 1 === 1 ?
                <div >
                  <TextField
                    name="email"
                    
                    placeholder="Nhập email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"entypo:mail"} sx={{ color: 'black' }} />
                        </InputAdornment>
                      ),
                    }}
                    value={inputValues?.email}
                    sx={{ ...styleInputFullField,  '& input': {
                      color: 'black',
                    },
                    '& fieldset.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#62b7fc',
                      borderWidth: 1,
                    },
                    ':hover': {
                      '& fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#62b7fc',
                        borderWidth: 1,
                      },
                    }, }}
                    error={errors.email ? true : false}
                    helperText={
                      errors.email ? (
                        errors.email
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onChange={(e) => {
                      setUser({ ...user, email: e.target.value });
                    }}
                    onInput={handleOnInput}
                    autoFocus
                  />
                </div> : <div >
                  {activeStep + 1 === 2 ?
                    <Box>
                      <Grid item xs={6}>
                        <Typography sx={{ mt: 4 }}>Gửi mã qua email: <span style={{ color: 'red', fontSize: 18, fontWeight: 600 }}>{user?.email}</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: -6 }}>
                        <TextField
                          name="code"
                          label={
                            <span style={{ color: 'blue' }}>Mã Code</span>
                          }
                          placeholder="Nhập Mã Code"
                          value={inputValues?.code}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"ic:baseline-qr-code-scanner"} sx={{color:'black'}} />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setUser({ ...user, code: e.target.value });
                          }}
                          autoComplete="none"
                          sx={{ width: '100%', mt: 1.5, '& input': {
                            color: 'black',
                          },
                          '& fieldset.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#62b7fc',
                            borderWidth: 1,
                          },
                          ':hover': {
                            '& fieldset.MuiOutlinedInput-notchedOutline': {
                              borderColor: '#62b7fc',
                              borderWidth: 1,
                            },
                          }, }}
                          error={errors.code ? true : false}
                          helperText={
                            errors.code ? (
                              errors.code
                            ) : (
                              <Box component="span" sx={{ color: "white" }}>
                                &nbsp;
                              </Box>
                            )
                          }
                          onInput={handleOnInput}
                          autoFocus
                        />
                      </Grid>
                    </Box>

                    :
                    <Box>
                      <Grid item xs={6} sx={{ mt: 4, mb: -2 }}>
                        <TextField
                          name="newPassword"
                          label={
                            <span style={{ color: 'blue' }}>Mật khẩu mới</span>
                          }
                          placeholder="Nhập Mật Khẩu"
                          type={showNewPassword ? "text" : "password"}

                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"carbon:password"} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setNewShowPassword(!showNewPassword)}
                                  edge="end"
                                >
                                  <Iconify
                                    icon={
                                      showNewPassword
                                        ? "eva:eye-fill"
                                        : "eva:eye-off-fill"
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setUser({ ...user, newPassword: e.target.value });
                          }}
                          autoComplete="none"
                          sx={{ width: '48%', mr: '3%','& input': {
                            color: 'black',
                          },
                          '& fieldset.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#62b7fc',
                            borderWidth: 1,
                          },
                          ':hover': {
                            '& fieldset.MuiOutlinedInput-notchedOutline': {
                              borderColor: '#62b7fc',
                              borderWidth: 1,
                            },
                          } }}
                          error={errors.newPassword ? true : false}
                          helperText={
                            errors.newPassword ? (
                              errors.newPassword
                            ) : (
                              <Box component="span" sx={{ color: "white" }}>
                                .
                              </Box>
                            )
                          }
                          onInput={handleOnInput}
                          autoFocus
                        />
                        <TextField
                          name="confirmpassword"
                          label={
                            <span style={{ color: 'blue' }}>Nhập lại mật khẩu mới</span>
                          }
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Nhập Lại Mật Khẩu"

                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"material-symbols:password"} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setConfirmShowPassword(!showConfirmPassword)
                                  }
                                  edge="end"
                                >
                                  <Iconify
                                    icon={
                                      showConfirmPassword
                                        ? "eva:eye-fill"
                                        : "eva:eye-off-fill"
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              confirmpassword: e.target.value,
                            });
                          }}
                          sx={{ width: "48%",'& input': {
                            color: 'black',
                          },
                          '& fieldset.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#62b7fc',
                            borderWidth: 1,
                          },
                          ':hover': {
                            '& fieldset.MuiOutlinedInput-notchedOutline': {
                              borderColor: '#62b7fc',
                              borderWidth: 1,
                            },
                          } }}
                          autoComplete="none"
                          error={errors.confirmpassword ? true : false}
                          helperText={
                            errors.confirmpassword ? (
                              errors.confirmpassword
                            ) : (
                              <Box component="span" sx={{ color: "white" }}>
                                .
                              </Box>
                            )
                          }
                          onInput={handleOnInput}
                        />
                      </Grid>
                    </Box>
                  }
                </div>}

              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1,color:'black' }}
                >
                  Trở về
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Box sx={{ color: 'red', mt: 1.1, mr: 3, cursor: 'pointer' }} onClick={handleClick}>Thoát</Box>

                {activeStep + 1 === 1 ? <Button sx={{color:'black'}} type='submit'>
                  Kế tiếp
                </Button> : ""}
                {activeStep + 1 === 2 ? <Button type='submit' sx={{color:'black'}}>
                  Tiếp tục
                </Button> : ""}
                {activeStep + 1 === 3 ? <Button type='submit' sx={{color:'black'}}>
                  Xác nhận
                </Button> : ""}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </form>

  );
}
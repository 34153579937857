

export default function DateTimeOfDay({ dateTime }) {
    let today = new Date(dateTime);

    let month = today.getMonth()+1
    if(month < 10){
      month = "0"+month;
    }
    let day = today.getDate();
    if(day < 10){
      day = "0"+day;
    }

    let date =
      day +
      " / " +
      month +
      " / " +
      today.getFullYear()

  return <span >{date}</span>;
}

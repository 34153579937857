/* eslint-disable react-hooks/rules-of-hooks */
import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "./layout";
import LoginPage from "./pages/LoginPage/LoginPage"
import useLogin from "./utils/Login/useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Axios from "../src/utils/Axios";
import { addLeaveDay } from '../src/utils/store/actions';
import Dashboard from "./components/views/dashboard";
import UserManagement from "./components/views/userManagement";
import Timekeeping from "./components/views/Timekeeping";
import Profile from "./pages/ProfilePage/Profile";
import ProjectManagement from "./components/views/projectManagement";
import PositionManagement from "./components/views/positionManagement";
import LeaveDay from "./components/views/LeaveDay";
import HolidayManagement from "./components/views/HolidayManagement";
import ProjectManagementSale from "./components/views/saleProjectManagement";
import TaskManagement from "./components/views/TaskManagement";
import TaskProject from "./pages/TaskProject/TaskProject";
import TimekeepingManagement from "./components/views/TimekeepingManagement"
import LeaveDetailPage from "./pages/LeaveManagement/LeaveDetailPage";
import LeaveManagement from "./components/views/LeaveManagement";
import ProjectDetailsPage from "./pages/ProjectDetailsPage/ProjectDetailsPage";
import SaleProjectDetailsPage from "./pages/SaleProjectDetail/ProjectSaleDetailsPage";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Home from "./pages/Home/Home";
import Page404 from "./pages/Authentication/Page404";
import LoadingLogin from "./pages/LoadingLogin/loadingLogin";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";

let arr = [];

export default function Router() {

  const { account } = useLogin();
  const dispatch = useDispatch();
  let listCheckRole = [];

  const userLoginDetail = useSelector((state) => state.userLoginDetail.data);
  userLoginDetail?.positionId?.listPermission?.forEach(element => {
    listCheckRole.push(element.permissionCode)
  });

  localStorage.setItem('url', window.location.pathname);

  let url = localStorage.getItem("url");
  arr.push(url);
  localStorage.setItem('url', arr[0]);

  useEffect(() => {
    if (listCheckRole.includes('quan-ly-phe-duyet')) {
      getAllApprove(0, 1, -1, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCheckRole.length !== 0])

  const getAllApprove = async (page, limit, sortOrder, property) => {
    const response = await Axios.LeaveDay.getAll(
      page,
      limit,
      sortOrder,
      "",
      property
    );

    if (response.status === 200) {
      dispatch(addLeaveDay(parseInt(response.data.totalItem)))

    } else {
      // toast.error("Lấy danh sách phê duyệt thất bại!");
    }
  };

  if (listCheckRole.length !== 0) {
    const routes = useRoutes([
      {
        path: "/",
        element: (!account || account.status === false) ? (
          <Navigate to="/login" />
        ) : (
          <Navigate to="/dashboard" />
        ),
      },
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/login",
        element: (!account || account.status === false) ? <LoginPage /> : <Navigate to="/dashboard" />,
      },
      {
        path: "/",
        element: (!account || account.status === false) ? <Navigate to="/login" /> : <MainLayout />,
        children: [
          {
            path: "/home",
            element: <Home />,
          },
          {
            path: "/dashboard",
            element: (listCheckRole.includes('quan-ly-du-an-xem') === true || listCheckRole.includes('quan-ly-nguoi-dung-xem') === true) ? <Dashboard listRole={listCheckRole} /> : <Navigate to="/home" />,
          },
          {
            path: "/quan-ly-du-an",
            element: (listCheckRole.includes('quan-ly-du-an') ? <ProjectManagement listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/quan-ly-tien-do",
            element: (listCheckRole.includes('quan-ly-tien-do') ? <TaskManagement listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/getListUserInProject/:_id",
            element: (listCheckRole.includes('quan-ly-tien-do') ?<TaskProject /> : <Navigate to="/home" />),
          },
          {
            path: "/projectDetailsPage/:_id",
            element: (listCheckRole.includes('quan-ly-du-an') ? <ProjectDetailsPage listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/saleProjectDetailsPage/:_id",
            element: (listCheckRole.includes('quan-ly-du-an-sale') ? <SaleProjectDetailsPage listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/changePassword",
            element: <ChangePassword />,
          },
          {
            path: "/quan-ly-nguoi-dung",
            element: (listCheckRole.includes('quan-ly-nguoi-dung') ? <UserManagement listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/profile/:_id",
            element: <Profile listRoleProfile={listCheckRole} />,
          },
          {
            path: "/quan-ly-cham-cong",
            element: (listCheckRole.includes('quan-ly-cham-cong') ? <Timekeeping /> : <Navigate to="/home" />),
          },
          {
            path: "/bao-cao-cham-cong",
            element: (listCheckRole.includes('bao-cao-cham-cong') ? <TimekeepingManagement /> : <Navigate to="/home" />),
          },
          {
            path: "/quan-ly-phe-duyet",
            element: (listCheckRole.includes('quan-ly-phe-duyet') ? <LeaveDay listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/quan-ly-chuc-vu",
            element: (listCheckRole.includes('quan-ly-chuc-vu') ? <PositionManagement listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/quan-ly-ngay-phep",
            element: (listCheckRole.includes('quan-ly-ngay-phep') ? <LeaveManagement listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/LeaveDetailPage/:_id",
            element: (listCheckRole.includes('quan-ly-ngay-phep') ? <LeaveDetailPage listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/quan-ly-ngay-le",
            element: (listCheckRole.includes('quan-ly-ngay-le') ? <HolidayManagement listRole={listCheckRole} /> : <Navigate to="/home" />),
          },
          {
            path: "/quan-ly-du-an-sale",
            element: (listCheckRole.includes('quan-ly-du-an-sale') ? <ProjectManagementSale listRole={listCheckRole} /> : <Navigate to="/home" />),
          },

        ],
      },
      {
        path: "/404",
        element: <Page404 />,
      },

      {
        path: "*",
        element: <Navigate to="/404" replace />,
      },
    ]);
    return routes;
  }else{
    const routes = useRoutes([
      {
        path: "/",
        element: (!account || account.status === false) ? (
          <Navigate to="/login" />
        ) : (
          <Navigate to="/dashboard" />
        ),
      },
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/login",
        element: (!account || account.status === false) ? <LoginPage /> : <Navigate to="/dashboard" />,
      },
      {
        path: "/",
        element: (!account || account.status === false) ? <Navigate to="/login" /> : <MainLayout />,
        children: [
          {
            path: "/home",
            element: <Home />,
          },
          {
            path: "/dashboard",
            element: <LoadingLogin />,
          },
          {
            path: "/quan-ly-du-an",
            element: <LoadingLogin />,
          },
          {
            path: "/quan-ly-tien-do",
            element: <LoadingLogin />,
          },
          {
            path: "/getListUserInProject/:_id",
            element: <LoadingLogin />,
          },
          {
            path: "/projectDetailsPage/:_id",
            element: <LoadingLogin />,
          },
          {
            path: "/saleProjectDetailsPage/:_id",
            element:<LoadingLogin />,
          },
          {
            path: "/changePassword",
            element: <LoadingLogin />,
          },
          {
            path: "/quan-ly-nguoi-dung",
            element: <LoadingLogin />,
          },
          {
            path: "/profile/:_id",
            element: <LoadingLogin />,
          },
          {
            path: "/quan-ly-cham-cong",
            element: <LoadingLogin />,
          },
          {
            path: "/bao-cao-cham-cong",
            element: <LoadingLogin />,
          },
          {
            path: "/quan-ly-phe-duyet",
            element: <LoadingLogin />,
          },
          {
            path: "/quan-ly-chuc-vu",
            element: <LoadingLogin />,
          },
          {
            path: "/quan-ly-ngay-phep",
            element:<LoadingLogin />,
          },
          {
            path: "/LeaveDetailPage/:_id",
            element:<LoadingLogin />,
          },
          {
            path: "/quan-ly-ngay-le",
            element: <LoadingLogin />,
          },
          {
            path: "/quan-ly-du-an-sale",
            element: <LoadingLogin />,
          },

        ],
      },
      {
        path: "/404",
        element: <Page404 />,
      },

      {
        path: "*",
        element: <Navigate to="/404" replace />,
      },
    ]);
    return routes;


  }
}
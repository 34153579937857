import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import React, { useEffect, useState } from "react";
import useValidator from "../../../../utils/Validator";
import { toast } from "react-toastify";
import Axios from "../../../../utils/Axios";
import useLogin from "../../../../utils/Login/useLogin";
import { styled } from "@mui/material/styles";

const styleInputFullField = {
  width: "100%",
  mt: 2,
};

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));


export const DialogCreateSaleProject = ({ open, setOpen, onChange, page, limit }) => {
  const { validateSaleProject } = useValidator();
  const { account } = useLogin();

  const [SaleProjectCreate, setSaleProjectCreate] = useState({
    companyName: "",
    address: "",
    contactPerson: "",
    phoneNumber: "",
    email: "",
    position: "",
    marketInformation: "",
    note: "",
    source: "",
    lastMarketInformation: "",
    lastContactDate: "",
  });

  const [showLoading, setShowLoading] = useState(false);


  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({
    companyName: "",
    address: "",
    contactPerson: "",
    phoneNumber: "",
    email: "",
    position: "",
    marketInformation: "",
    note: "",
    source: "",
    lastMarketInformation: "",
    lastContactDate: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setErrors({});
  }, [open])

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateSaleProject(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      create();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };


  const create = async () => {
    setShowLoading(() => true);
    let data = SaleProjectCreate;
    data.createdBy = account._id;

    const response = await Axios.SaleProject.create(data);
    if (response.status === 200) {
      setShowLoading(() => false);
      handleReset();
      toast.success("Tạo dự án thành công");
      setOpen(false);
      onChange(page, limit, -1, "");
    } else {
      toast.error("Tạo dự án thất bại!");
      setShowLoading(() => false);
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateSaleProject(name, value) });
  };

  const handleReset = () => {
    setSaleProjectCreate({
      companyName: "",
      address: "",
      contactPerson: "",
      phoneNumber: "",
      email: "",
      position: "",
      marketInformation: "",
      note: "",
      source: "",
      lastMarketInformation: "",
      lastContactDate: "",
    });
    setErrors({});
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle
            sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800}}
          >
            Báo Cáo Thị Trường
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <Box tag={"p"} sx={{ mt: 2, display: "flex", width: "100%" }}>
                <Box sx={{ width: "60%", ml: 1 }}>
                  <TextField
                    component="div"
                    name="companyName"
                    label="Tên Công Ty"
                    multiline
                    value={SaleProjectCreate?.companyName}
                    maxRows={5}
                    onChange={(e) =>
                      setSaleProjectCreate({
                        ...SaleProjectCreate,
                        companyName: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Tên Công Ty?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.companyName ? true : false}
                    helperText={
                      errors.companyName ? (
                        errors.companyName
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    sx={
                      (styleInputFullField,
                      {
                        ...styleInputFullField,
                        ml: 2,
                        width: "90%",
                        fontFamily: "inherit",
                      })
                    }
                    onInput={handleOnInput}

                  />
                  <TextField
                    component="div"
                    label="Địa chỉ"
                    multiline
                    maxRows={5}
                    value={SaleProjectCreate?.address}
                    onChange={(e) =>
                      setSaleProjectCreate({
                        ...SaleProjectCreate,
                        address: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Địa chỉ?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                      {
                        ...styleInputFullField,
                        ml: 2,
                        width: "90%",
                        fontFamily: "inherit",
                      })
                    }
                    onInput={handleOnInput}
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                  />

                  <TextField
                    component="div"
                    label="Người liên hệ"
                    multiline
                    maxRows={5}
                    value={SaleProjectCreate?.contactPerson}
                    onChange={(e) =>
                      setSaleProjectCreate({
                        ...SaleProjectCreate,
                        contactPerson: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Người liên hệ?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                      {
                        ...styleInputFullField,
                        ml: 2,
                        width: "90%",
                        fontFamily: "inherit",
                      })
                    }
                    onInput={handleOnInput}
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                  />
                  <TextField
                    component="div"
                    label="Số điện thoại"
                    value={SaleProjectCreate?.phoneNumber}
                    onChange={(e) =>
                      setSaleProjectCreate({
                        ...SaleProjectCreate,
                        phoneNumber: e.target.value,
                      })
                    }
                    type="number"
                    variant="standard"
                    placeholder="Số điện thoại?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                  />
                  <TextField
                    component="div"
                    label="Email"
                    value={SaleProjectCreate?.email}
                    onChange={(e) =>
                      setSaleProjectCreate({ ...SaleProjectCreate, email: e.target.value })
                    }
                    variant="standard"
                    placeholder="Email?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                   
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                  />

                </Box>

                <Box sx={{ width: "60%" }}>

                  <TextField
                    component="div"
                    // name="investor"
                    label="Chức vụ"
                    multiline
                    maxRows={5}
                    value={SaleProjectCreate?.position}
                    onChange={(e) =>
                      setSaleProjectCreate({
                        ...SaleProjectCreate,
                        position: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Chức vụ?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    onInput={handleOnInput}

                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }

                  />


                  <TextField
                    component="div"
                    label="Ghi chú"
                    multiline
                    maxRows={5}
                    value={SaleProjectCreate?.note}
                    onChange={(e) =>
                      setSaleProjectCreate({
                        ...SaleProjectCreate,
                        note: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Ghi chú?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                  />
                  <TextField
                    component="div"
                    label="Nguồn khách"
                    multiline
                    value={SaleProjectCreate?.source}
                    maxRows={5}
                    onChange={(e) =>
                      setSaleProjectCreate({
                        ...SaleProjectCreate,
                        source: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Nguồn khách?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                  />
                  <TextField
                    component="div"
                    label="Thông tin thị trường gần nhất"
                    multiline
                    maxRows={5}
                    value={SaleProjectCreate?.lastMarketInformation}
                    onChange={(e) =>
                      setSaleProjectCreate({
                        ...SaleProjectCreate,
                        lastMarketInformation: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Thông tin thị trường gần nhất?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                  
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                  />
                  <TextField
                    label="Ngày liên hệ gần nhất"
                    variant="standard"
                    type="date"
                    value={SaleProjectCreate?.lastContactDate?SaleProjectCreate?.lastContactDate:''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"uiw:date"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setSaleProjectCreate({ ...SaleProjectCreate, lastContactDate: e.target.value });
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    onInput={handleOnInput}
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                  />
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                <TextField
                  component="div"
                  multiline
                  maxRows={5}
                  value={SaleProjectCreate?.marketInformation}
                  label="Thông tin thị trường"
                  onChange={(e) =>
                    setSaleProjectCreate({
                      ...SaleProjectCreate,
                      marketInformation: e.target.value,
                    })
                  }
                  variant="standard"
                  placeholder="Thông tin thị trường?"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon={"material-symbols:edit-note-sharp"}
                        />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="none"

                  onInput={handleOnInput}
                  sx={
                    (styleInputFullField,
                      { ...styleInputFullField,ml:3, width: "94%" })
                  }
                  error={errors.no ? true : false}
                  helperText={
                    errors.no ? (
                      errors.no
                    ) : (
                      <Box component="span" sx={{ color: "white" }}>
                        &emsp;
                      </Box>
                    )
                  }
                />
              </Box>

            </Grid>

          </DialogContent>

          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            {SaleProjectCreate.companyName !== "" ||
              SaleProjectCreate.address !== "" ||
              SaleProjectCreate.contactPerson !== "" ||
              SaleProjectCreate.phoneNumber !== "" ||
              SaleProjectCreate.email !== "" ||
              SaleProjectCreate.position !== "" ||
              SaleProjectCreate.marketInformation !== "" ||
              SaleProjectCreate.note ||
              SaleProjectCreate.source ||
              SaleProjectCreate.lastContactDate !== "" ||
              SaleProjectCreate.lastMarketInformation !== "" ||
              Object.keys(errors).length !== 0 ? (
              <CustomButton
                onClick={handleReset}
                sx={{ background: "#f78b25" }}
                variant="contained">Làm mới</CustomButton>
            ) : (
              ""
            )}
            <CustomButton type="submit" variant="contained">Thêm mới</CustomButton>
            <CustomButton onClick={handleClose} sx={{ background: "#f72533" }} variant="contained">Hủy</CustomButton>

          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

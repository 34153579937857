import styled from "styled-components";

const DateTime = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: 13,
  // color: "#555555",
  // fontWeight:'bold'
}));

export default function DateTimeStartEnd({ start, end }) {

  let dateStart = new Date(start);
  let dateEnd = new Date(end);
  let dayStart = dateStart.getDate();


  if (dayStart < 10) {
    dayStart = "0" + dayStart;
  } 

  let monthStart = dateStart.getMonth() + 1
  if (monthStart < 10) {
    monthStart = "0" + monthStart;
  }

  let dayEnd = dateEnd.getDate();

  if (dayEnd < 10) {
    dayEnd = "0" + dayEnd;
  }

  let monthEnd = dateEnd.getMonth() + 1;

  if (monthEnd < 10) {
    monthEnd = "0" + monthEnd;
  } 
  let date;
  if(start !== undefined && end !==undefined){
    date =
    dayStart +
      " tháng " +
      monthStart
      + ' - ' + dayEnd + ' tháng ' + monthEnd;
  }
  if(end === undefined && start !== undefined){
    date =
    dayStart +
      " tháng " +
      monthStart;
  }
  if(start === undefined && end !==undefined){
    date = dayEnd + ' tháng ' + monthEnd;
  }



  return <DateTime>{date}</DateTime>;
}

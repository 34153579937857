import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "../../../../utils/Axios";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment-timezone';
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/vi';
import "./../index.scss"
import { toast } from "react-toastify";
import { TimekeepingDetailNote } from "./TimekeepingDetailNote";
import Iconify from "../../../../components/iconify";


moment.tz.setDefault('Etc/GMT+0');

const localizer = momentLocalizer(moment);


export const TimekeepingDetail = ({ open, setOpen, userId, fullname, month, year, defaultMonth }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [listEvent, setListEvent] = useState([]);
  const [openNote, setOpenNote] = useState(false);
  const [data, setData] = useState();
  const [yearNew, setYearNew] = useState(year);
  const [monthNew, setMonthNew] = useState(month);
  const [defaultCalendar, setDefaultCalendar] = useState(new Date(defaultMonth));

  function isWeekend(date) {
    const day = date.getDay();
    return day === 6 || day === 0;
  }

  function dayPropGetter(date) {
    let checkStart = new Date(date).getTime();
    const weekend = isWeekend(date);

    const eventsInSlot = listEvent.filter(
      (event) =>
        new Date(event.start).getTime() === checkStart
    );
    if (eventsInSlot.length > 0) {
      if (weekend === true) {
        return {
          className: 'weekend',
        };
      } else {
        return {
          className: 'background-event',
        };
      }

    } else {
      return {
        className: weekend ? 'weekend' : ''
      };
    }
  }

  const eventSelected = (data) => {
    setData(data);
    setOpenNote(true);
  };

  useEffect(() => {
    setDefaultCalendar(new Date(defaultMonth));

    setYearNew(year);
    setMonthNew(month);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open,defaultMonth])

  useEffect(() => {
    if (userId !== undefined) {
      getAllTimekeepingDetail(yearNew + '-' + monthNew, userId);
    }
  }, [userId, monthNew, yearNew, open, month, year,])

  const getAllTimekeepingDetail = async (month, userId) => {
    setShowLoading(() => true);
    let arr = [];
    const response = await Axios.Timekeeping.getAllTimekeepingDetail(month, userId);
    if (response.status === 200) {
      setShowLoading(() => false);
      response.data.forEach((element) => {
        let listEventObj = {};
        listEventObj._id = element._id;
        if (element.content === "P") {
          if (element.status === false) {
            listEventObj.title = "P - (ACP) ";
          } else {
            listEventObj.title = "P";
          }
        } else {
          listEventObj.title = element.content;
          if (element.content === "KL") {
            if (element.status === false) {
              listEventObj.title = "KL - (ACP) ";
            } else {
              listEventObj.title = "KL";
            }
          }
        }
        listEventObj.start = element.date;
        listEventObj.end = element.date;
        listEventObj.note = element.note;
        arr.push(listEventObj);
      });
    } else {
      toast.error("Lấy danh sách chấm công thất bại!");

      setShowLoading(() => false);
    }

    setListEvent(arr);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const toDay = (date) => {
    getMonthFromStringToday(new Date());
    // eslint-disable-next-line no-redeclare
    var date = new Date();
    let monthToday = date.getMonth() + 1;
    if (monthToday > 12) {
      monthToday = 1;
    }
  };


  function getMonthFromStringNext(date) {
    let dateString = date.label;
    const today = new Date();
    const dateFrmat = date.date;

    dateFrmat.setHours(23, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    let year = dateString.slice(-4);
    let month = parseInt(dateString.substring(6, dateString.length - 5)) + 1;
    if (month > 12) {
      year = parseInt(year) + 1;
      month = 1;
    }
    var result = year + "-" + month;
    setMonthNew(month);
    setYearNew(year);
    return result;
  }

  function getMonthFromStringPrev(date) {
    let dateString = date.label;
    const today = new Date();
    const dateFrmat = date.date;
    dateFrmat.setHours(0, 0, 0, 0);
    today.setHours(23, 0, 0, 0);

    let year = dateString.slice(-4);
    let month = (dateString.substring(6, dateString.length - 5)) - 1;
    if (month < 1) {
      year = year - 1;
      month = 12;
    }
    var result = year + "-" + month;
    setMonthNew(month);
    setYearNew(year);
    return result;
  }
  function getMonthFromStringToday(dateString) {

    var date = new Date(dateString);
    let month = date.getMonth() + 1;

    let year = date.getFullYear();
    if (month > 12) {
      year = year + 1;
      month = '1';
    }
    var result = year + "-" + month;
    setMonthNew(month);
    setYearNew(year);
    return result;
  }

  const prev = (props) => {

    getMonthFromStringPrev(props);
  };
  const next = (props) => {
    getMonthFromStringNext(props);
  };


  // function getMonthLabel(dateString) {
  //   let year = dateString.slice(-4);
  //   let month = (dateString.substring(6, dateString.length - 5));

  //   let result = "Tháng " + month + " - ( " + year + " )"
  //   return result;
  // }

  const CustomToolbar = (props) => {
    return (
      <>

        <Grid rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ height: '30px', marginBottom: 2, mt:1 }}>
          {/* {getMonthLabel(props.label)} */}
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <Box sx={{ mr: 1 }}> <button
              className="buttonNextPrev"
              style={{ width: 90, borderRadius: '20px 0px 0px 20px', cursor: "pointer", }}
              onClick={() => props.onNavigate("PREV") & prev(props)}
            >
              <Iconify icon={"grommet-icons:chapter-previous"} sx={{ width: '30%', mt: 0.2 }} />
            </button></Box>
            <Box sx={{ mr: 1 }}>
              <button
                className="buttonNextPrev"
                style={{ width: 90, cursor: "pointer", borderRadius: '20px' }}
                onClick={() => props.onNavigate("TODAY") & toDay(props)}
              >
                <Iconify icon={"fluent:calendar-today-16-regular"} sx={{ width: '30%', mt: 0.2 }} />
              </button>
            </Box>
            <button
              className="buttonNextPrev"
              style={{ width: 90, cursor: "pointer", borderRadius: '0px 20px 20px 0px' }}
              onClick={() => props.onNavigate("NEXT") & next(props)}
            >
              <Iconify icon={"grommet-icons:chapter-next"} sx={{ width: '30%', mt: 0.2 }} />
            </button>
          </Grid>
        </Grid>
      </>
    );
  };

  function Event(event) {
    return (
      <div style={{ textAlign: "center", height: 39, width: '100%', margin: "auto", verticalAlign: 'middle' }} >
        {event?.event?.note.includes('holiday-') ? <div
          className="contentTimekeepingDetail"
          style={{
            padding: 2,
            color: "black",
            borderRadius: 15,
            fontWeight: 660,
            backgroundImage: "linear-gradient(to top, #00e676 20%, #00e676 80%)"
          }}
        >
          {event.event.title}
          {event.event.note ? <span style={{ color: 'black' }}> ( Nghỉ lễ )</span> : ""}
        </div> : <Box>
          {event?.event?.title === "P" ? <div
            className="contentTimekeepingDetail"
            style={{
              padding: 2,
              borderRadius: 15,
              fontWeight: 660,
              backgroundImage: "linear-gradient(to top, #f50057 20%, #f50057 80%)",
            }}
          >
            {event.event.title}
            {event.event.note ? <span style={{ color: 'white' }}> ( Note )</span> : ""}
          </div> : <Box>
            {event?.event?.title === "H" ? <div
              className="contentTimekeepingDetail"
              style={{
                padding: 2,
                color: "white",
                borderRadius: 15,
                fontWeight: 660,
                backgroundImage: "linear-gradient(to top, #ff5722 20%, #ff5722 80%)",
              }}
            >
              {event.event.title}
              {event.event.note ? <span > ( Note )</span> : ""}
            </div> : <div
              className="contentTimekeepingDetail"
              style={{
                padding: 2,
                color: "red",
                borderRadius: 15,
                fontWeight: 660,
                backgroundImage: "linear-gradient(to top, #ccf3fc 20%, #cce3fc 80%)",
              }}
            >
              {event.event.title}
              {event.event.note ? <span > ( Note )</span> : ""}
            </div>}
          </Box>}
        </Box>}


      </div>
    );
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="800">
        <DialogTitle sx={{ textAlign: 'center', fontSize: 23, fontWeight: 800 }}>Chấm công tháng {monthNew} năm {yearNew} - ( <span >{fullname}</span> )
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText />
          <Grid container spacing={2} sx={{ maxWidth: 1500, minWidth: 1150, }}>
            <div className="caledar" style={{ height: "450pt", width: '95%', margin: 'auto', }}>
              <Calendar
                id="calendar"
                selectable
                components={{ event: Event, toolbar: CustomToolbar }}
                events={listEvent}
                localizer={localizer}
                defaultDate={defaultCalendar}
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    background: 'rgba(0, 0, 0, 0)',
                    textAlign: 'center',
                    marginTop:'-1%'
                  };
                  return {
                    style: newStyle,
                  };
                }}
                // style={{ background: 'white' }}
                dayPropGetter={dayPropGetter}
                onSelectEvent={eventSelected}
              />
            </div>

          </Grid>
        </DialogContent>

      </Dialog>
      <TimekeepingDetailNote
        open={openNote}
        setOpen={setOpenNote}
        data={data}
      />
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

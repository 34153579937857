import {  Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function TaskProjectSearchBox({ searchData }) {

  const { name } = searchData;
  const customization = useSelector((state) => state.customization);


  return (
    <>
      <Link
        to={`/getListUserInProject/${searchData._id}`}
        style={{ textDecoration: "none" }}
        className={customization.mode}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            cursor: 'pointer'
          }}
          className="searchBox"

        >

          <Box display={"flex"} maxWidth="70%" >
            <Box sx={{ maxWidth: "99%", ml: 2 }}>
              <Typography
                sx={{fontWeight: 600 }}
                variant="subtitle2"
                noWrap
                fontSize={16}
              >
                {name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Link>

    </>
  );
}

// @mui
import PropTypes from "prop-types";
import { Box, Stack, Card, Typography, CardHeader } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// utils
// components

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  return (
    <Card {...other} sx={{ borderRadius: 5, boxShadow: 10 }}>
      <CardHeader title={title} subheader={subheader} titleTypographyProps={{variant:'h4' }} />
      <Stack spacing={3} sx={{ p: 3, pr: 0, }}>
        {list?.map((news) => (
          <NewsItem sx={{ background: 'grey' }} key={news._id} news={news} />
        ))}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const { _id, processingTime, projectName, contractStatus } = news;
  const customization = useSelector((state) => state.customization);

  return (
    <Link to={`/projectDetailsPage/${_id}`} style={{ textDecoration: "none" }}
    className={customization.mode}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          boxShadow: 2,
          borderRadius: 5,
          padding: 0.6,
          mt: -3,
          mb: 3.5,
          mr: 3,
          paddingLeft: 3,
        }}

      >
        <Box sx={{ flexGrow: 1, }}>
          <Typography
            variant="body2"
            sx={{  fontSize: 18 }} >
            <span style={{  fontWeight: 700 }}>Dự án: </span>
            {projectName}
          </Typography>

          <Typography 
          variant="body2" 
          sx={{  fontSize: 18 }} >
            <span style={{  fontWeight: 700, }}>Hiện trạng: </span>
            {contractStatus.statusName}
          </Typography>
          <Typography
            variant="caption"
            sx={{ pr: 3, flexShrink: 0,  fontSize: 18 }}
          >
            <span style={{  fontWeight: 700, }}>Thời gian xử lý: </span>
            {processingTime}
          </Typography>
        </Box>
      </Stack>
    </Link>
  );
}

/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-self-assign */
import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import Iconify from "../../../components/iconify/Iconify";
import Axios from "../../../utils/Axios";
import { toast } from "react-toastify";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DateTimeStartEnd from "../../../utils/DateTimeOfMessage/DateTimeStartEnd";



export const DialogDetail = ({ open, setOpen, taskDetail, getListTaskStatus, listUser }) => {
  const [opendescription, setOpenDescription] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [task, setTask] = useState({});
  const [listUsers, setListUser] = useState(listUser);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [listUserId, setListUserId] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogTask, setOpenDialogTask] = React.useState(false);
  const [name, setName] = useState();
  const [listUserIdDelete, setListUserIdDelete] = useState([]);
  const [valueColor, setValueColor] = useState();
  const [idTask, setIdTask] = useState();

  useEffect(() => {
    setListUser(listUser);
  }, [listUser])


  const handleChange = (event) => {
    let obj = {}
    let color;
    if (event.target === undefined) {
      var priority = 0;
      if (event === "#ff1744") {
        priority = 1;
      }
      if (event === "#ffea00") {
        priority = 2;
      }
      if (event === "#2979ff") {
        priority = 3;
      }
      if (event === "#1de9b6") {
        priority = 4;
      }
      color = event
      obj.priority = priority;
    } else {
      var priority1 = 0;
      if (event?.target?.value === "#ff1744") {
        priority1 = 1;
      }
      if (event?.target?.value === "#ffea00") {
        priority1 = 2;
      }
      if (event?.target?.value === "#2979ff") {
        priority1 = 3;
      }
      if (event?.target?.value === "#1de9b6") {
        priority1 = 4;
      }
      color = event.target.value;
      obj.priority = priority1;
    }

    if (obj.priority === 1 && valueColor === "#ff1744" || obj.priority === 2 && valueColor === "#ffea00" || obj.priority === 3 && valueColor === "#2979ff" || obj.priority === 4 && valueColor === "#1de9b6") {
      let object = {};
      object.priority = '5'
      updateTaskLabel(object);
      setValueColor('')
    } else {
      updateTaskLabel(obj);
      setValueColor(color);
    }
  };

  const updateTaskLabel = async (data) => {
    const response = await Axios.TaskProject.updateTask(taskDetail._id, data);
    if (response.status === 200) {
      getListTaskStatus.getListTaskStatus();
    } else {
      toast.error("Cập nhật thất bại");
    }
  };

  const handleClearSelection = (event) => {
    if (event === valueColor) {
      setValueColor('')
      updateTaskLabel({ priority: '5' });
    }
  };


  // expireDate
  useEffect(() => {
    let obj = taskDetail;

    if (obj?.startDate !== undefined) {
      obj.startDate = formatDate(taskDetail?.startDate);

      let today = new Date(obj?.startDate);
      let month = today.getMonth() + 1
      if (month < 10) {
        month = "0" + month;
      } else {
        month = month;
      }
      let day = today.getDate();
      if (day < 10) {
        day = "0" + day;
      } else {
        day = day;
      }
    }

    if (obj?.expireDate !== undefined) {
      obj.expireDate = formatDate(taskDetail?.expireDate);

      let today = new Date(obj?.expireDate);
      let month = today.getMonth() + 1
      if (month < 10) {
        month = "0" + month;
      } else {
        month = month;
      }
      let day = today.getDate();
      if (day < 10) {
        day = "0" + day;
      } else {
        day = day;
      }

    }
    setTask(obj);

    let color;
    if (taskDetail?.priority === undefined) {
      color = "#ffffff";
    } else {
      if (taskDetail?.priority === "1") {
        color = "#ff1744";
      }
      if (taskDetail?.priority === "2") {
        color = "#ffea00";
      }
      if (taskDetail?.priority === "3") {
        color = "#2979ff";
      }
      if (taskDetail?.priority === "4") {
        color = "#1de9b6";
      }
    }
    setValueColor(color);



  }, [taskDetail, open])


  const formatDate = (date) => {
    let today = new Date(date);
    let month = today.getMonth() + 1
    if (month < 10) {
      month = "0" + month;
    } else {
      month = month;
    }
    let day = today.getDate();
    if (day < 10) {
      day = "0" + day;
    } else {
      day = day;
    }

    return today.getFullYear() + "-" + month + "-" + day;
  };


  const handleClose = () => {
    setOpen(false);
  };



  const updateTask = async (task) => {
    setOpenDescription(false);
    let data = {};
    if (task?.name !== taskDetail?.name) {
      data.name = task.name;
      const response = await Axios.TaskProject.updateTask(taskDetail._id, data);
      if (response.status === 200) {
        getListTaskStatus.getListTaskStatus();
      } else {
        toast.error("Cập nhật thất bại");
      }
    }

    if (task?.description !== taskDetail?.description) {
      data.description = task.description;
      const response = await Axios.TaskProject.updateTask(taskDetail._id, data);
      if (response.status === 200) {
        getListTaskStatus.getListTaskStatus();
      } else {
        toast.error("Cập nhật thất bại");
      }
    }

    if (valueColor !== task.backgroundColor) {
      data.backgroundColor = task.backgroundColor;
      const response = await Axios.TaskProject.updateTask(taskDetail._id, data);
      if (response.status === 200) {
        getListTaskStatus.getListTaskStatus();
      } else {
        toast.error("Cập nhật thất bại");
      }
    }

  };

  const updateTaskStartDate = async (task) => {
    const date = new Date(task);
    const isoDateString = date.toISOString();
    if (isoDateString !== taskDetail.startDate) {
      const response = await Axios.TaskProject.updateTask(taskDetail._id, { startDate: task });
      if (response.status === 200) {
        getListTaskStatus.getListTaskStatus();
      } else {
        toast.error("Cập nhật thất bại");
      }
    }


  };

  const updateTaskExpireDate = async (task) => {
    const date = new Date(task);
    const isoDateString = date.toISOString();
    if (isoDateString !== taskDetail.expireDate) {
      const response = await Axios.TaskProject.updateTask(taskDetail._id, { expireDate: task });
      if (response.status === 200) {
        getListTaskStatus.getListTaskStatus();
      } else {
        toast.error("Cập nhật thất bại");
      }
    }


  };

  const removeTimer = async () => {
    const response = await Axios.TaskProject.removeTimer(taskDetail._id);
    if (response.status === 200) {
      getListTaskStatus.getListTaskStatus();
      toast.success("Gỡ bỏ thành công");
    } else {
      toast.error("Gỡ bỏ thất bại");
    }
  };

  const addUserToTask = async () => {
    setShowLoading(() => true);
    let data = {};
    data.taskId = taskDetail._id;
    data.listUserId = listUserId;
    const response = await Axios.TaskProject.addUserToTask(data);
    if (response.status === 200) {
      setShowLoading(() => false);
      handleReset();
      toast.success("Thêm thành viên thành công");
      getListTaskStatus.getListTaskStatus();
    } else {
      setShowLoading(() => false);
      if (response.status === 400 && response.code === 27) {
        toast.error("Người dùng đã có trong công việc!");
      } else {
        toast.error("Thêm thành viên thất bại");
        setShowLoading(() => false);
      }

    }
  };
  const handleReset = () => {
    setListUserId([]);
    setSelectedValue([]);
  };


  const handleChangeListUser = (target) => {
    let arr = [];
    target.forEach(element => {
      let id = element.split("_")[0];
      arr.push(id)
    });
    setListUserId(arr);
  };

  const removeUserFromTask = async () => {
    setShowLoading(() => true);
    let data = {};
    data.taskId = taskDetail._id;
    data.listUserId = listUserIdDelete;
    const response = await Axios.TaskProject.removeUserFromTask(data);
    if (response.status === 200) {
      setShowLoading(() => false);
      handleReset();
      setOpenDialog(false);
      toast.success("Xoá thành viên thành công");
      getListTaskStatus.getListTaskStatus();
    } else {
      toast.error("Xoá thành viên thất bại");
      setShowLoading(() => false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenDialogTask(false);
  };

  const handleClickOpen = (id, name) => {

    let arrId = [];
    arrId.push(id);

    if (id !== undefined) {
      setListUserIdDelete(arrId);
      setName(name)
    } else {
      let arrIdAll = [];
      taskDetail.listUser.forEach(element => {
        arrIdAll.push(element._id);
      });
      setListUserIdDelete(arrIdAll);
      setName("Tất cả")

    }

    setOpenDialog(true);
  };

  const handleClickOpenTask = (data) => {
    setName(data.name)
    setIdTask(data._id);
    setOpenDialogTask(true);
  };


  const deleteTask = async () => {
    const response = await Axios.TaskProject.deleteTask(idTask);
    if (response.status === 200) {
      handleReset();
      setOpenDialogTask(false);
      getListTaskStatus.getListTaskStatus();
      toast.success("Xoá thẻ thành công");
    } else {
      toast.error("Xoá thẻ thất bại");
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="800">
        <DialogTitle sx={{ textAlign: 'center', fontSize: 23, width: 814 }}>
          <span >Chi tiết </span>( {taskDetail?.name} )
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText />
          <Grid container spacing={2} sx={{ minWidth: 680, width: 780, }} >
            <Grid item xs={12} >
              <div>
                <TextField
                  label="Tên task"
                  multiline
                  variant="standard"
                  value={task?.name}
                  onClick={(e) => {
                    setOpenDescription(false) & setTask(taskDetail)
                  }}
                  onChange={(e) =>
                    setTask({
                      ...task,
                      name: e.target.value,
                    })
                  }
                  sx={{
                    width: '85%',
                  }}
                  onBlur={(e) => { updateTask(task) }}
                />
                <Button
                  sx={{
                    backgroundColor: 'red', // màu nền button
                    color: 'white', // màu chữ button
                    fontWeight: 'bold',
                    borderRadius: 15,
                    paddingLeft: 3,
                    paddingRight: 3,
                    marginTop: 0,
                    boxShadow: 2,
                    ml: 2,
                    mt: 1,
                    '&:hover': {
                      backgroundColor: '#f50707', // màu nền khi hover vào button
                      color: 'white', // màu chữ khi hover vào button
                    },
                  }}
                  onClick={(e) => { handleClickOpenTask(taskDetail) }}
                >Xoá</Button>
              </div>

            </Grid>
            <Grid item xs={12} sx={{ width: 200 }}>
              <Box sx={{ ml: -2.1 }}>
                <Accordion sx={{ width: '100%', mt: -2, mb: 4 }} defaultExpanded>
                  <AccordionSummary sx={{ position: 'absolute' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontWeight: 600 }}>Ngày <DateTimeStartEnd start={taskDetail?.startDate} end={taskDetail?.expireDate} /> </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sx={{ mt: 4.5, ml: 18 }}>
                        <TextField
                          name="date"
                          label="Ngày bắt đầu"
                          variant="standard"
                          value={task?.startDate ? task?.startDate : ""}
                          type="date"
                          placeholder="Nhập ngày bắt đầu"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"uiw:date"} />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setTask({ ...task, startDate: e.target.value }) & updateTaskStartDate(e.target.value)
                          }}
                          sx={{
                            width: '40%',
                          }}
                          autoComplete="none"
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ mt: 4.5, ml: -20 }} >
                        <TextField
                          name="dateEnd"
                          label="Ngày hết hạn"
                          variant="standard"
                          value={task?.expireDate ? task?.expireDate : ""}
                          type="date"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"uiw:date"} />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setTask({ ...task, expireDate: e.target.value }) & updateTaskExpireDate(e.target.value)
                          }}
                          autoComplete="none"
                          sx={{
                            width: '63%',

                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ mt: 5.5, ml: -15 }} >
                        <Button
                          sx={{
                            backgroundColor: '#5e35b1', // màu nền button
                            color: '#ffff', // màu chữ button
                            fontWeight: 'bold',
                            borderRadius: 15,
                            paddingLeft: 3,
                            paddingRight: 3,
                            marginTop: 0,
                            boxShadow: 2,
                            ml: 5,
                            '&:hover': {
                              backgroundColor: '#673ab7', // màu nền khi hover vào button
                            },
                          }}
                          onClick={removeTimer}
                        >Gỡ bỏ</Button>
                      </Grid>

                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>

            </Grid>



            <Grid item xs={12} sx={{ width: 200 }}>
              <Box sx={{ ml: -2.1 }}>
                <Accordion sx={{ width: '100%', mb: 4, mt: -2 }} defaultExpanded>
                  <AccordionSummary sx={{ position: 'absolute' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontWeight: 600 }}>Thành viên ( {taskDetail?.listUser?.length} )</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box >
                      <span>
                        {taskDetail?.listUser?.length > 1 ? <Button sx={{
                          backgroundColor: 'red', // màu nền button
                          color: 'white', // màu chữ button
                          fontWeight: 'bold',
                          borderRadius: 15,
                          paddingLeft: 2,
                          paddingRight: 2,
                          boxShadow: 1,
                          fontSize: 12,
                          mt: 5,
                          mb: -1.5,
                          ml: -0.5,
                          '&:hover': {
                            backgroundColor: '#f50707', // màu nền khi hover vào button
                            color: 'white', // màu chữ khi hover vào button
                          },
                        }}
                          onClick={(e) => { handleClickOpen() }}
                        >Xoá tất cả</Button> : <p>&nbsp;</p>}
                      </span>
                      <p style={{ marginBottom: '20px' }}></p>
                      {taskDetail?.listUser.map((item, index) => {
                        return (
                          <span key={index} ><span >{index + 1}</span>.{item?.fullname}
                            <Iconify icon={"ri:delete-back-2-fill"}
                              sx={{
                                position: 'absolute',
                                mt: 0.3,
                                ml: 0.3,
                                cursor: "pointer",
                                borderRadius: 10,
                                width: 17,
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              onClick={(e) => { handleClickOpen(item._id, item.fullname) }}
                            />&ensp; &ensp; &ensp; </span>
                        )

                      })}

                      <Autocomplete
                        multiple
                        options={listUsers.map((option) => option._id + '_' + option.fullname + ' - ( ' + option.email + ' )')}
                        value={selectedValue}
                        freeSolo
                        renderOption={(props, option) => {
                          const parts = option.split("_"); // Tách theo dấu "_"
                          // const _id = parts[0];

                          const nameAndEmail = parts[1];

                          const nameAndEmailParts = nameAndEmail.split("-"); // Tách theo dấu "-"
                          const fullname = nameAndEmailParts[0]; // Chuỗi name là phần tử đầu tiên trong mảng nameAndEmailParts
                          const email = nameAndEmailParts[1];
                          return (
                            <li style={{ fontSize: '14px' }} {...props}>
                              {fullname} &nbsp;
                              <span >{email}</span>
                              <p></p>
                            </li>

                          );
                        }}
                        onChange={(event, newValue) => {
                          setSelectedValue(newValue);
                          handleChangeListUser(newValue);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option.split("_")[1]} {...getTagProps({ index })} color="default" />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Thêm thành viên"
                            sx={{ width: '90%', }}

                          // onInput={handleOnInput}
                          />
                        )}

                        sx={{ width: '90%', mt: 1 }}

                      />
                      <Button type="submit" variant="contained"
                        sx={{
                          height: "35px",
                          color: "white",
                          paddingLeft: 2,
                          paddingRight: 3,
                          mt: -5,
                          background: '#2979ff',
                          float: 'right',
                          "&:hover": {
                            background: "#edf5f4",
                            color: "red"
                          },
                          boxShadow: 2,
                          borderRadius: 1
                        }}
                        onClick={addUserToTask}
                      >
                        <Iconify icon={"material-symbols:add"} />
                        Thêm
                      </Button>
                    </Box>

                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ width: 200 }}>
              <Box sx={{ ml: -2.1 }}>
                <Accordion sx={{ width: '100%', mb: 4, mt: -2, }} defaultExpanded>
                  <AccordionSummary sx={{ position: 'absolute' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontWeight: 600 }}>Nhãn</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box >
                      <p style={{ marginBottom: '40px' }}></p>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={valueColor ? valueColor : ''}
                          onChange={handleChange}
                        >
                          <FormControlLabel onClick={(e) => { handleClearSelection('#ff1744') }} value="#ff1744" control={<Radio />} label="" /> <p onClick={(e) => { handleChange('#ff1744') }} style={{ cursor: 'pointer', background: '#ff1744', width: '65px', height: '30px', marginRight: '15px', marginLeft: '-18px', textAlign: 'center', lineHeight: '25px', color: 'white' }}>1</p>
                          <FormControlLabel onClick={(e) => { handleClearSelection('#ffea00') }} value="#ffea00" control={<Radio />} label="" /> <p onClick={(e) => { handleChange('#ffea00') }} style={{ cursor: 'pointer', background: '#ffea00', width: '65px', height: '30px', marginRight: '15px', marginLeft: '-18px', textAlign: 'center', lineHeight: '25px', color: 'black' }}>2</p>
                          <FormControlLabel onClick={(e) => { handleClearSelection('#2979ff') }} value="#2979ff" control={<Radio />} label="" /> <p onClick={(e) => { handleChange('#2979ff') }} style={{ cursor: 'pointer', background: '#2979ff', width: '65px', height: '30px', marginRight: '15px', marginLeft: '-18px', textAlign: 'center', lineHeight: '25px', color: 'black' }}>3</p>
                          <FormControlLabel onClick={(e) => { handleClearSelection('#1de9b6') }} value="#1de9b6" control={<Radio />} label="" /> <p onClick={(e) => { handleChange('#1de9b6') }} style={{ cursor: 'pointer', background: '#1de9b6', width: '65px', height: '30px', marginRight: '15px', marginLeft: '-18px', textAlign: 'center', lineHeight: '25px', color: 'black' }}>4</p>
                        </RadioGroup>
                      </FormControl>

                    </Box>

                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={1} sx={{ mt: 2.9, ml: 2 }}>
                <Iconify icon={"pajamas:text-description"} />
              </Grid>
              <Grid item xs={4} sx={{ ml: -4.4 }} >
                <Box sx={{ fontSize: 17, mt: 3, fontWeight: 600, position: 'relative' }}>
                  Mô tả
                </Box>
                {opendescription === false && task?.description?.length !== undefined && taskDetail?.description?.length !== 0 ?
                  <Button
                    sx={{
                      height: "30px",
                      color: "black",
                      paddingLeft: 2,
                      paddingRight: 2,
                      ml: 8,
                      background: '#faf7f5',
                      marginBottom: 2.5,
                      "&:hover": {
                        background: "#edf5f4",
                        color: "red"
                      },
                      mt: -3.2,
                      boxShadow: 2,
                      borderRadius: 1
                    }}
                    onClick={(e) => { setOpenDescription(true) }}
                  >

                    Chỉnh sửa
                  </Button>
                  : ""}


              </Grid>
            </Grid>
            {task?.description?.length === undefined && opendescription === false || task?.description?.length === 0 && opendescription === false ?
              <Grid item xs={12} sx={{ width: '0px' }} >
                <TextField
                  component="div"
                  name="intermediary"
                  variant="standard"
                  placeholder="Thêm mô tả chi tiết hơn..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={"fluent-mdl2:edit-create"} />
                      </InputAdornment>
                    )
                  }}
                  onClick={(e) => { setOpenDescription(true) }}
                  autoComplete="none"
                  sx={{
                    width: '93%',
                    ml: 2,
                  }}
                />
              </Grid>

              : ""}


            <Grid item xs={12} sx={{ mt: -2.5 }}>
              {opendescription === false && taskDetail?.description?.length !== undefined && task?.description?.length !== 0 ?
                <TextField
                  className="textField"
                  component="div"
                  name="intermediary"
                  multiline
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    )
                  }}
                  autoComplete="none"
                  sx={{
                    '& .MuiInput-underline:before': {
                      borderBottom: 'none'
                    },
                    '& .MuiInput-underline:after': {
                      borderBottom: 'none'
                    },
                    '& .MuiInput-underline:hover:before': {
                      borderBottom: 'none'
                    },
                    width: '95%',
                    ml: 2,

                  }}
                  value={task?.description}
                /> : ""}
              {opendescription === false ?
                "" :
                <TextField
                  component="div"
                  name="intermediary"
                  multiline
                  maxRows={13}
                  variant="standard"
                  placeholder="Nhập nội dung..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    )
                  }}
                  autoComplete="none"
                  sx={{
                    width: '95%',

                    border: '1px solid grey',
                    mt: 2,
                    padding: 2,
                    '& .MuiInput-underline:before': {
                      borderBottom: 'none'
                    },
                    '& .MuiInput-underline:after': {
                      borderBottom: 'none'
                    },
                    '& .MuiInput-underline:hover:before': {
                      borderBottom: 'none'
                    },
                  }}
                  value={task?.description}
                  onChange={(e) =>
                    setTask({
                      ...task,
                      description: e.target.value,
                    })
                  }
                  onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )
                  }
                  inputRef={(input) => input && input.focus()}
                  autoFocus
                />
              }
            </Grid>

            {opendescription === true ?
              <Grid item xs={12} sx={{ width: '30px' }}>
                <Button
                  sx={{
                    height: "35px",
                    color: "white",
                    paddingLeft: 2,
                    paddingRight: 3,
                    margin: "auto",
                    background: '#0062ff',
                    marginBottom: 2.5,
                    "&:hover": {
                      background: "#edf5f4",
                      color: "red"
                    },
                    mt: 1,
                    boxShadow: 2,
                    borderRadius: 1
                  }}
                  onClick={(e) => { updateTask(task) }}
                >
                  <Iconify icon={"material-symbols:add"} />
                  Lưu
                </Button>

                <Button
                  sx={{
                    height: "35px",
                    color: "white",
                    paddingLeft: 2,
                    paddingRight: 3,
                    margin: "auto",
                    background: 'red',
                    ml: 1,
                    // width: "95%",
                    marginBottom: 2.5,
                    "&:hover": {
                      background: "#edf5f4",
                      color: "red"
                    },
                    mt: 1,
                    boxShadow: 2,
                    borderRadius: 1
                  }}
                  onClick={(e => { setOpenDescription(false) & setTask(taskDetail) })}
                >
                  <Iconify icon={"ph:x"} />
                  Huỷ
                </Button>
              </Grid> : ""}
          </Grid>


        </DialogContent>
      </Dialog>

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận xoá?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{  width: "95%" }}>
              {name}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ mt: -3 }}>
            <Button
              sx={{
                backgroundColor: "#5351ed", // màu nền button
                color: "white", // màu chữ button
                fontWeight: "bold",
                borderRadius: 15,
                marginBottom: 1,
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 5,
                "&:hover": {
                  backgroundColor: "#f7f9fa", // màu nền khi hover vào button
                  color: "red" // màu chữ khi hover vào button
                }
              }}
              onClick={removeUserFromTask}
            >
              Xác nhận
            </Button>
            <Button
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: "red", // màu nền button
                color: "white", // màu chữ button
                fontWeight: "bold",
                borderRadius: 15,
                marginBottom: 1,
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 5,
                "&:hover": {
                  backgroundColor: "#f7f9fa", // màu nền khi hover vào button
                  color: "red" // màu chữ khi hover vào button
                }
              }}
            >
              Huỷ
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={openDialogTask}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận xoá?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{  width: "95%" }}>
              {name}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ mt: -3 }}>
            <Button
              sx={{
                backgroundColor: "#5351ed", // màu nền button
                color: "white", // màu chữ button
                fontWeight: "bold",
                borderRadius: 15,
                marginBottom: 1,
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 5,
                "&:hover": {
                  backgroundColor: "#f7f9fa", // màu nền khi hover vào button
                  color: "red" // màu chữ khi hover vào button
                }
              }}
              onClick={deleteTask}
            >
              Xác nhận
            </Button>
            <Button
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: "red", // màu nền button
                color: "white", // màu chữ button
                fontWeight: "bold",
                borderRadius: 15,
                marginBottom: 1,
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 5,
                "&:hover": {
                  backgroundColor: "#f7f9fa", // màu nền khi hover vào button
                  color: "red" // màu chữ khi hover vào button
                }
              }}
            >
              Huỷ
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

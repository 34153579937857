import Axios from "./../../../../utils/Axios/Axios";

class Project {

  static getAll = async (page, limit, sortOrder, search, sortProperty) =>
    Axios.get("/api/v1/project", {
      page, limit, sortOrder, search, sortProperty
    });

  static create = async (data) => Axios.post("/api/v1/project", data);

  static delete = async (data) => Axios.delete("/api/v1/project", data);

  static update = async (id, data) => Axios.put("/api/v1/project/" + id, data);

  static getOne = async (id) => Axios.get(`/api/v1/project/${id}`);

  static getAllContactStatus = async (id) => Axios.get(`/api/v1/project/getAllContractStatus`);

}

export default Project;
import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  Typography,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Backdrop,
  CircularProgress,
} from "@mui/material";
// component
import Iconify from "../../../../iconify";
import Axios from "../../../../../utils/Axios";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Box } from "@mui/system";
import SearchInput from "../search/SearchInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ProjectSearchBox from "../search/ProjectSearchBox";

// ----------------------------------------------------------------------
const CustomButton = styled
(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
        backgroundColor: '#f7f9fa', // màu nền khi hover vào button
        color: 'red', // màu chữ khi hover vào button
    },
}));
const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#dee2e3",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#dee2e3",
  },
};

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
  marginTop:'80px'

}));

// ----------------------------------------------------------------------

ProjectListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function ProjectListToolbar({
  numSelected,
  selected,
  onChange,
  page,
  limit,
  setListWork
}) {
  const [searchList, setSearchList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isFocusPopup, setFocusPopup] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleCloseDialogDelete = () => {
    deleteProject();
    setOpenDialog(false);
  };

  const searchAllWork = async () => {
    const response = await Axios.Project.getAll(0, 20, -1, "", "");
    if (response.status === 200) {
      setSearchList(response.data.listItem);
      setListWork(response.data)
    } else {

    }
  };

  const handleSearch = async (e) => {
    const response = await Axios.Project.getAll(0, 20, -1, e.target.value, "");
    if (response.status === 200) {
      setSearchList(response.data.listItem);
      setListWork(response.data)

    } else {
    }
  };

  const handleOpen = (event) => {
    searchAllWork();
    setOpen(event.currentTarget);
  };

  const handleClose = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
    }
  };
  const handleClose2 = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
      setFocusPopup(false);
    } else {
      setFocusPopup(false);
    }
  };

  const deleteProject = async () => {
    setShowLoading(() => true);
    const response = await Axios.Project.delete({ listId: selected });
    if (response.status === 200) {
      setShowLoading(() => false);
      onChange(page, limit, -1, "");
      toast.success("Xoá dự án thành công!");
    } else {
      setShowLoading(() => false);
      toast.error("Xoá dự án thất bại!");
    }
  };
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          borderRadius: 50,
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitl1">
          <span style={{fontWeight: "bold" }}>
            {numSelected}
          </span>{" "}
          dự án đã chọn
        </Typography>
      ) : (
        <Box onFocus={handleOpen} onBlur={handleClose}>
          <SearchInput position="start" onInput={handleSearch} />
          <Card
            hidden={!open}
            sx={{
              width: '65%',
              position: "fixed",
              mt: 1,
              p: 0,
              borderRadius: 2,
              boxShadow: "0 0 8px #976235",
              maxHeight: "40vh",
              zIndex: 99999,
            }}
          >
            <Box
              onBlur={handleClose}
              onMouseEnter={() => setFocusPopup(true)}
              onMouseLeave={handleClose2}
            >
              <CardHeader
                title={
                  <Typography fontWeight={600} fontSize={16}>
                    {"Danh Sách Tìm Kiếm"}
                  </Typography>
                }
                sx={{ pb: 2, height: 24 }}
              />
              <Divider />
              <CardContent
                sx={{
                  p: "0 !important",
                  maxHeight: "calc(40vh - (14*2px))",
                  overflow: "auto",
                  ...scrollbar,
                }}
              >
                 {searchList.length!==0?"":<span style={{pb:2,marginLeft:'5%'}}>( Thông tin tìm kiếm không có! )<p></p></span>}
                {searchList?.map((searchData, index) => (
                  <ProjectSearchBox key={index} searchData={searchData} setOpen={setOpen} />
                ))}
              </CardContent>
            </Box>
          </Card>
        </Box>
      )}

      {numSelected > 0 ? (
        <div>
          <Tooltip title="Xoá">
            <Button
              // variant="outlined"
              sx={{ color: "red", border: "none" }}
              onClick={handleClickOpen}
            >
              <Iconify icon="eva:trash-2-fill" />
            </Button>
          </Tooltip>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle width={400} id="alert-dialog-title">
              <Typography
                component="div"
                sx={{ fontSize: 20 }}
                variant="subtitl1"
              >
                {"Xác nhận xoá?"}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  component="span"
                  sx={{ fontSize: 20, fontFamily: "bold" }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {numSelected}
                  </span>{" "}
                  dự án đã chọn
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={handleCloseDialogDelete}>Xác nhận</CustomButton>
              <CustomButton onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
                Huỷ
              </CustomButton>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        ""
      )}
     <Backdrop
        sx={{ color: "#fff",background:'rgba(0, 0, 0, 0)',width:"50%",margin:'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </StyledRoot>
  );
}

import PropTypes from "prop-types";
// @mui
import {
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { useEffect } from "react";

// ----------------------------------------------------------------------


TimekeepingManagementListHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default function TimekeepingManagementListHead({
  order,
  orderBy,
  headLabel,
  rowCount,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  onChange,
  page,
  limit,
  month
}) {

  useEffect(() => {
    onChange(month, page, limit, order, orderBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, order || orderBy]);


  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ position: 'sticky', top: 0, zIndex: 999}}>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sx={{
              background: headCell.background,
              fontSize: headCell.fontSize,
              fontWeight: 'bold',
              color: headCell.color,
              borderRadius: headCell.border,
              position: headCell.position,
              left: headCell.left,
              right: headCell.right,
              zIndex: headCell.zIndex,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "fullname" || headCell.id === "positionName" ? <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.idSort}
              direction={orderBy === headCell.idSort ? order : "asc"}
              onClick={createSortHandler(headCell.idSort)}
              className="tableHeadLabel"
            >
              {headCell.label}
            </TableSortLabel> : <TableSortLabel
              className="TableSortLabelTimekeeping"
              hideSortIcon
            >
              {headCell.label}
            </TableSortLabel>}

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

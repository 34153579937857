import * as React from 'react';
import ForgotPasswordFrom from "./components/ForgotPasswordFrom";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { Container, Typography } from '@mui/material';
import Logo from '../../utils/Logo/Logo';
import bg from "../../bg-login.jpeg"


const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  backgroundImage: `url(${bg})`
}));

const StyledContent = styled("div")(({ theme }) => ({
  width:'100%',
  margin: "auto",
  padding: "30",
  minHeight: "100vh",
  fontSize: "2",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "12px",
  background: "#fff",
}));


export default function ForgotPasswordPage() {


  return (
    <>
      <Helmet>
        <title> ForgotPassword | Siginx </title>
      </Helmet>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography align="center" marginBottom={3}>
              <Logo
                sx={{
                  height: 150,
                }}
              />
            </Typography>

            <ForgotPasswordFrom />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
import Axios from "./../../../../utils/Axios/Axios";

class WorkProject {
  static getAllWorkProject = async (page, limit, sortOrder, search, sortProperty) =>
    Axios.get("/api/v1/workProject/getAllWorkProject", {
      page, limit, sortOrder, search, sortProperty
    });

  static createWorkProject = async (data) => Axios.post("/api/v1/workProject/createWorkProject", data);

  static deleteWorkProject = async (data) => Axios.delete("/api/v1/workProject/deleteWorkProject", data);

  static getListUserInProject = async (id, page, limit, sortOrder, search, sortProperty) =>
  Axios.get(`/api/v1/workProject/getListUserInWorkProject/${id}`, {
    page, limit, sortOrder, search, sortProperty
  });

  static getListUserNotInWorkProject = async (id, page, limit, sortOrder, search, sortProperty) =>
  Axios.get(`/api/v1/workProject/getListUserNotInWorkProject/${id}`, {
    page, limit, sortOrder, search, sortProperty
  });

  static updateWorkProject = async (id, data) => Axios.put("/api/v1/workProject/updateWorkProject/" + id, data);

  static removeUserFromProject = async (data) => Axios.put(`/api/v1/workProject/removeUserFromWorkProject`, data);

  static addMember = async (data) => Axios.put("/api/v1/workProject/addUserToWorkProject", data);

  static getOne = async (id) => Axios.get(`/api/v1/workProject/getWorkProject/${id}`);


}

export default WorkProject;
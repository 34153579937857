export default function useValidator() {
  const validateRequired = (name, value, error) => {
    if (!value || value === 0) {
      return false;
    }
    return true;
  };

  const validateEmail = (name, value, error) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(value)) {
      return false;
    }
    return true;
  };

  const validate = (name, value) => {
    if(name==="newpassword" || name==="newPassword" ){
      name="Mật khẩu mới"
    }
    if(name==="confirmpassword"){
      name="Xác nhận mật khẩu"
    }
    if(name==="fullname"){
      name="Họ tên"
    }
    if(name==="position"){
      name="Chức vụ"
    }
    if(name==="activeCode" ||name==="code" ){
      name="Mã Code"
    }
    const firstChar = name.substring(0, 1).toUpperCase();
    if (!validateRequired(name, value)) {
      return firstChar + name.substring(1) + " phải bắt buộc nhập!";
    }
    switch (name) {
      case "email":
        if (!validateEmail(name, value)) {
          return "Email không hợp lệ!";
        }
        break;
      default:
    }
  };

 

  const validateActiveUser = (name, value) => {
    if (!validateRequired(name, value)) {
      if(name==="activeCode"){
        name="Mã Code"
      }
      return  name + " phải bắt buộc nhập!";
    }
  };

  const validateCreateProject = (name, value) => {
    if (!validateRequired(name, value)) {
      if(name==="projectName"){
        name="Tên dự án"
      }
      if(name==="intermediary"){
        name="Đầu mối"
      }
      if(name==="investor"){
        name="Chủ đầu tư"
      }
      if(name==="technicalProgress"){
        name="Tiến độ kỹ thuật"
      }
      if(name==="solution"){
        name="Hướng xử lý"
      }
      if(name==="processingTime"){
        name="Thời gian xử lý"
      }
      if(name==="contractStatus"){
        name="Hiện trạng"
      }
      if(name==="installationProgress"){
        name="Tiến độ lắp đặt"
      }
      if(name==="issue"){
        name="Tồn đọng kỹ thuật / thiết bị"
      }
      if(name==="projectStatus"){
        name="Giao Hàng-Bàn Giao-Nghiệm Thu"
      }
      
      return  name + " phải bắt buộc nhập!";
    }
  };

  const validateTimeKeeping = (name, value) => {
    if (!validateRequired(name, value)) {
      if(name==="content"){
        name="Nội dung"
      }      
      if(name==="note"){
        name="Ghi chú"
      }    
      return  name + " phải bắt buộc nhập!";
    }
  };

  const validatePosition = (name, value) => {
    if (!validateRequired(name, value)) {
      if(name==="positionName"){
        name="Tên nhóm quyền"
      }      
      return  name + " phải bắt buộc nhập!";
    }
  };

  const validateHoliday = (name, value) => {
    if (!validateRequired(name, value)) {
      if(name==="name"){
        name="Tên ngày lễ"
      }
      if(name==="date"){
        name="Ngày"
      }
      if(name==="dateEnd"){
        name="Ngày kết thúc"
      }
      if(name==="note"){
        name="Ghi chú"
      }
      return  name + " phải bắt buộc nhập!";
    }
  };

  const validateWorkProject = (name, value) => {
    if (!validateRequired(name, value)) {
      if(name==="name"){
        name="Tên dự án"
      }    
      return  name + " phải bắt buộc nhập!";
    }
  };

  const validateSaleProject = (name, value) => {
    if (!validateRequired(name, value)) {
      if(name==="companyName"){
        name="Tên công ty"
      }    
      if(name==="companyName"){
        name="Tên công ty"
      }    
      return  name + " phải bắt buộc nhập!";
    }
  };
  


  return {
    validate,
    validateCreateProject,
    validateTimeKeeping,
    validateActiveUser,
    validatePosition,
    validateHoliday,
    validateWorkProject,
    validateSaleProject
  };
}

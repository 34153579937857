/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import {
  Backdrop,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { toast } from "react-toastify";
import useValidator from "../../../../utils/Validator";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";


const CustomButton = styled
(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
        backgroundColor: '#f7f9fa', // màu nền khi hover vào button
        color: 'red', // màu chữ khi hover vào button
    },
}));


const styleInputFullField = {
  width: "100%",
  mb: 3,
  ml: 2,
};

const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%", ml: 10 }}>
      {children}
    </Grid>
  );
};

const set1 = new Set();

export const PositionDialog = ({ open = false, setOpen,onChange }) => {
  const { validatePosition } = useValidator();

  const [showLoading, setShowLoading] = useState(false);
  const [listPermission, setListPermission] = useState([]);

  const [value, setValue] = React.useState('1');

  const [selected, setSelected] = React.useState([]);

  const [position, setPosition] = useState({});


  useEffect(() => {
    getAllPermission();
  }, [])

  useEffect(()=>{
    setErrors({});
    setSelected([])
    setValue('1')
    setPosition({})
  },[open])

  const getAllPermission = async () => {
    setShowLoading(() => true);
    const response = await Axios.Permission.getAll();
    if (response.status === 200) {
      setListPermission(response.data);
      setShowLoading(() => false);
    } else {
      toast.error("Lấy danh sách Permission thất bại!");
      setShowLoading(() => false);
    }
  };


  function getChildById(node, _id) {
    let array = [];

    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes._id);
      if (Array.isArray(nodes.childPermission)) {
        nodes.childPermission.forEach(node => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes, _id) {
      if (nodes._id === _id) {
        return nodes;
      } else if (Array.isArray(nodes.childPermission)) {
        let result = null;
        nodes.childPermission.forEach(node => {
          if (getNodeById(node, _id)) {
            result = getNodeById(node, _id);
          }
        });
        return result;
      }
      return null;
    }
    return getAllChild(getNodeById(node, _id));
  }

  function getOnChange(checked, nodes) {

    listPermission.forEach(element => {
      const allNode = getChildById(element, nodes._id);

      allNode.forEach(element => {
        set1.add(element);
      });

      if (checked === false) {
        if (allNode.includes(nodes._id)) {
          allNode.forEach(element => {
            set1.forEach((point) => {
              if (point === element) {
                set1.delete(point);
              }
            });
          });
        }
      } else {
      }
      setSelected([...set1]);
    });

  }
  const createPosition = async () => {
    position.listPermission = selected;
    setShowLoading(() => true);
    const response = await Axios.Position.create(position);
    if (response.status === 200) {
      setShowLoading(() => false);
      onChange(0, 10, -1, "");
      setPosition({});
      toast.success("Tạo chức vụ thành công");
      setOpen(false);
    } else {
      if(response.status === 400){
         setErrors({
          positionName: "Tên chức vụ đã tồn tại!",
      });
      }
      setShowLoading(() => false);
    }
  }

 

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes._id}
      nodeId={nodes._id}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={
                // eslint-disable-next-line array-callback-return
                selected.some(item => {
                  if (nodes.permissionLevel === 1) {
                    nodes.childPermission.forEach(element => {
                      element.childPermission.forEach(element1 => {
                        if (item === element1._id) {
                          let arrChild = [];
                          element.childPermission.forEach(child => {
                            arrChild.push(child._id);
                          });
                          let arr1 = selected.filter(ls => ls === arrChild[0]);
                          let arr2 = selected.filter(ls => ls === arrChild[1]);
                          let arr3 = selected.filter(ls => ls === arrChild[2]);
                          let arr4 = selected.filter(ls => ls === arrChild[3]);

                          if (selected.includes(element._id) === false) {
                            setSelected([...selected, element._id]);
                          }
                          if (selected.includes(nodes._id) === false) {
                            setSelected([...selected, nodes._id]);
                          }
                          if (arr1.length !== 0 && arr2.length !== 0 && arr3.length !== 0 && arr4.length !== 0) {
                            if (selected.includes(element._id) === false) {
                              setSelected([...selected, element._id]);
                            }
                          }
                        }
                      }
                      )

                      if (nodes.childPermission[1] !== undefined) {
                        if (selected.includes(nodes.childPermission[0]._id) === true && selected.includes(nodes.childPermission[1]._id) === true) {
                          if (selected.includes(nodes._id) === false) {
                            setSelected([...selected, nodes._id]);
                          }
                        }
                      } else {
                        if (selected.includes(nodes.childPermission[0]._id) === true) {
                          if (selected.includes(nodes._id) === false) {
                            setSelected([...selected, nodes._id]);
                          }
                        }
                      }
                    })
                  }
                  if (item === nodes._id) {
                    return true;
                  }
                }
                )
              }
              indeterminate={(function () {
                let isTrue = false;
                if (
                  !getChildById(nodes, nodes._id).every((element1) =>
                    selected.includes(element1)
                  ) &&
                  selected.includes(nodes._id)
                ) {
                  isTrue = true;
                }

                return isTrue;
              })()}

              onChange={
                event =>
                  getOnChange(event.currentTarget.checked, nodes)
              }
              onClick={e => e.stopPropagation()}
            />
          }
          label={<> {nodes.permissionName}</>}
          key={nodes._id}
        />
      }
    >
      {
        Array.isArray(nodes.childPermission)
          ? nodes.childPermission.map(node => renderTree(node))
          : null
      }
    </TreeItem >
  );



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [inputValues, setInputValues] = useState({
    positionName: "",
    positionNote:""
  });

  const [errors, setErrors] = useState({
    positionName: "",
    positionNote:""

  });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validatePosition(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
        createPosition();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validatePosition(name, value) });
  };


  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
        <div style={{ fontSize: 20.28, textAlign: 'center',padding:20 }}>Thêm nhóm quyền</div>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 1500, minWidth: 1350, height: 600 }}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="Thông tin chung" value="1" />
                      <Tab label="Phân quyền" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{ height: 300 }}>
                    <Grid item xs={12}>
                      <TextField
                        component="div"
                        name="positionName"
                        label="Tên nhóm quyền"
                        value={position?.positionName?position?.positionName:""}
                        variant="standard"
                        placeholder="Nhập tên nhóm quyền"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"material-symbols:edit-note"} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setPosition({ ...position, positionName: e.target.value });
                        }}
                        autoComplete="none"

                        sx={{
                          styleInputFullField, ...styleInputFullField,
                          // width: 350
                        }}
                        error={errors.positionName ? true : false}
                        helperText={
                          errors.positionName ? (
                            errors.positionName
                          ) : (
                            <Box component="span" sx={{ color: "white" }}>
&nbsp;
                            </Box>
                          )
                        }
                        onInput={handleOnInput}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        // name="fullname"
                        label="Ghi Chú"
                        value={position?.positionNote?position?.positionNote:""}
                        placeholder="Nhập ghi chú"
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"material-symbols:edit-note"} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setPosition({ ...position, positionNote: e.target.value });
                        }}
                        autoComplete="none"
                        multiline
                        rows={3}
                        sx={{
                          styleInputFullField, ...styleInputFullField,
                          // width: 580
                        }}
                        onInput={handleOnInput}
                      />
                    </Grid>

                  </TabPanel>
                  <TabPanel value="2" sx={{ height: 300 }}>
                    <LayoutFormTwoField>
                      {listPermission.map(item => {
                        try {

                          let idCheckboxParent1;
                          let idCheckboxParent2;
                          let idCheckboxParent3;
                          let idCheckboxParent4;
                          let idCheckboxParent5;
                          let idCheckboxParent6;
                          let idCheckboxParent7;
                          let idCheckboxParent8;
                          let idCheckboxParent9;
                          try {
                            idCheckboxParent1 = item.childPermission[0]._id;
                            idCheckboxParent2 = item.childPermission[1]._id;
                            idCheckboxParent3 = item.childPermission[2]._id;
                            idCheckboxParent4 = item.childPermission[3]._id;
                            idCheckboxParent5 = item.childPermission[4]._id;
                            idCheckboxParent6 = item.childPermission[5]._id;
                            idCheckboxParent7 = item.childPermission[6]._id;
                            idCheckboxParent8 = item.childPermission[7]._id;
                            idCheckboxParent9 = item.childPermission[8]._id;
                          } catch (error) {

                          }
                          return <Grid key={item._id} item xs={4}>
                            <TreeView
                              key={item._id}
                              defaultCollapseIcon={<ExpandMoreIcon />}
                              defaultExpanded={[item._id, idCheckboxParent1, idCheckboxParent2,idCheckboxParent3,idCheckboxParent4,idCheckboxParent5,idCheckboxParent6,idCheckboxParent7,idCheckboxParent8,idCheckboxParent9]}
                              defaultExpandIcon={<ChevronRightIcon />}
                            >
                              {renderTree(item)}
                            </TreeView>
                          </Grid>
                        } catch (error) {

                        }
                      })}

                    </LayoutFormTwoField>

                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>

            <CustomButton type="submit" variant="contained">Thêm mới</CustomButton>
            <CustomButton
                  sx={{ background: "#f72533"}}
                  onClick={handleClose} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff",background:'rgba(0, 0, 0, 0)',width:"50%",margin:'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

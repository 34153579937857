import Axios from "./../../../../utils/Axios/Axios";

class User {

    static getAll = async (page,limit,sortOrder,search,sortProperty) => 
    Axios.get("/api/v1/user",{
      page,limit,sortOrder,search,sortProperty
    });

    static changePassword = async (data) => Axios.put("/api/v1/auth/changePassword",data); 

    static register = async (data) => Axios.post("/api/v1/user",data);

    static getOne = async (id) => Axios.get(`/api/v1/user/${id}`);

    static getUserDetail = async (data) => Axios.get(`/api/v1/auth/userLoginDetail`,data);

    static delete = async (data) => Axios.delete("/api/v1/user",data);

    static update = async (id,data) => Axios.put("/api/v1/user/"+id,data);

    static resetPassword = async (data) => Axios.put("/api/v1/user/resetPassword",data);

    static activeUser = async (data) => Axios.put("/api/v1/auth/activeUser",data); 

    static updateUserLogin = async (data) => Axios.put("/api/v1/auth/editUserLogin",data);

    static logout = async (data) => Axios.delete("/api/v1/auth/logout",data);

  }
  
export default User;
// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './../NavGroup';
import menuItem from './../../menu-items';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    let listRole = [];
    const userLoginDetail = useSelector((state) => state.userLoginDetail.data);

    userLoginDetail?.positionId?.listPermission?.forEach(element => {
        listRole.push(element.permissionCode)
    });

    if (listRole.length !== 0) {
        if (!listRole.includes('quan-ly-du-an') && !listRole.includes('quan-ly-nguoi-dung')) {
            let index = menuItem.items[0].children.map((item) => item.id).indexOf("dashboard");
            if (index > -1) {
                menuItem.items[0].children.splice(index, 1);
            }
        }

        if (!listRole.includes('quan-tri-thong-tin')) {
            let index = menuItem.items[1].children.map((item) => item.id).indexOf("quan-tri-thong-tin")
            if (index > -1) {
                menuItem.items[1].children.splice(index, 1);
            }
        } else {
           
            if (!listRole.includes('quan-ly-cham-cong')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'quan-tri-thong-tin');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-cham-cong');
            }
            if (!listRole.includes('quan-ly-phe-duyet')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'quan-tri-thong-tin');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-phe-duyet');
            }
            if (!listRole.includes('quan-ly-du-an-sale')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'quan-tri-thong-tin');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-du-an-sale');
            }
            if (!listRole.includes('quan-ly-du-an')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'quan-tri-thong-tin');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-du-an');
            }
            if (!listRole.includes('quan-ly-tien-do')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'quan-tri-thong-tin');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-tien-do');
            }

        }
        if (!listRole.includes('quan-tri-he-thong')) {
            let index = menuItem.items[1].children.map((item) => item.id).indexOf("quan-tri-he-thong")
            if (index > -1) {
                menuItem.items[1].children.splice(index, 1);
            }
        } else {
            if (!listRole.includes('quan-ly-nguoi-dung')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'quan-tri-he-thong');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-nguoi-dung');
            }
            if (!listRole.includes('quan-ly-chuc-vu')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'quan-tri-he-thong');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-chuc-vu');
            }
            if (!listRole.includes('quan-ly-ngay-le')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'quan-tri-he-thong');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-ngay-le');
            }
            
        }
        if (!listRole.includes('nhan-su')) {
            let index = menuItem.items[1].children.map((item) => item.id).indexOf("nhan-su") //
            if (index > -1) {
                menuItem.items[1].children.splice(index, 1);
            }
        } else {
            if (!listRole.includes('bao-cao-cham-cong')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'nhan-su');
                obj.children = obj.children.filter(child => child.id !== 'bao-cao-cham-cong');
            }
            if (!listRole?.includes('quan-ly-phe-duyet')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'nhan-su');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-phe-duyet');
            }
            if (!listRole?.includes('quan-ly-ngay-phep')) {
                const obj = menuItem.items[1].children.find(item => item.id === 'nhan-su');
                obj.children = obj.children.filter(child => child.id !== 'quan-ly-ngay-phep');
            }
        }
    }


    const navItems = menuItem?.items?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} listRole={listRole} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    if (listRole.length !== 0) {
        return <>{navItems}</>;
    }
};

export default MenuList;
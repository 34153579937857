import Axios from "./../../../../utils/Axios/Axios";


class LeaveDay {

  static getAll = async (page, limit, sortOrder, search, sortProperty) =>
    Axios.get("/api/v1/approve", {
      page, limit, sortOrder, search, sortProperty
    });

  static approve = async (data) =>
    Axios.post("/api/v1/approve", data);

    static getAllUserWithTimekeepingPermission = async (page, limit, sortOrder, search, sortProperty) =>
    Axios.get("/api/v1/user/getAllUserWithTimekeepingPermission", {
      page, limit, sortOrder, search, sortProperty
    });


    static getLeaveDays = async (id) => Axios.get(`/api/v1/report/getLeaveDays/${id}`);

}

export default LeaveDay;
import PropTypes from 'prop-types';

// material-ui
import { Divider, Typography } from '@mui/material';
import List from '@mui/material/List';
// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import React from 'react';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item,listRole }) => {

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />; //====
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    return (
        <>
            <List
            >
                {items}
            </List>
            {(listRole.includes('quan-ly-du-an') || listRole.includes('quan-ly-nguoi-dung'))?<Divider sx={{ mt: 0.25, mb: 1.25 }} />:""}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;

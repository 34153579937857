import { useState } from "react";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
// components
import useValidator from "./../../../utils/Validator";
import Iconify from "./../../../components/iconify/Iconify";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useLogin from "../../../utils/Login/useLogin";
import Axios from "./../../../utils/Axios/Axios";
import ActiveUserDialog from "../../../components/views/userManagement/components/ActiveUserDialog"
// ----------------------------------------------------------------------

export const LoginForm = () => {
  const { account, setAccount } = useLogin();

  const { validate } = useValidator();

  const [showPassword, setShowPassword] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const [remember, setRemember] = useState(false);

  const [isShowActive, setIsShowActive] = useState(false);

  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });

  let url = localStorage.getItem("url");

  useEffect(() => {
    if (account) {
      setInputValues(account);
    } else {
      setInputValues({
        email: "",
        password: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (account) {
      if (account.remember === false) {
        setRemember(false);
      } else {
        setRemember(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithAccount = async () => {
    if (inputValues.email === "") {
      inputValues.email = account.email;
    }

    if (inputValues.password === "") {
      inputValues.password = account.password;
    }
    const response = await Axios.postNoToken("/api/v1/auth/login", inputValues);
    if (response.status === 400 && response.data.code === 3) {
      setErrors({
        email: "Không tìm thấy email!",
      });
      setShowLoading(() => false);
      return true;
    }
    if (response.status === 400 && response.data.code === 4) {
      setErrors({
        password: "Password không đúng!",
      });
      setShowLoading(() => false);
      return true;
    }
    if (response.status === 400 && response.data.code === 9) {
      setShowLoading(() => false);
      setIsShowActive(true);
      return true;
    } else {
      localStorage.removeItem("account");
      let account = {};
      let accessToken = response.data.data.accessToken;
      account.accessToken = accessToken;
      localStorage.setItem("account", JSON.stringify(account));

      const responseDetail = await Axios.get(
        "/api/v1/auth/userLoginDetail",
      );

      if (remember === false) {
        inputValues.password = '';
      }
      let listRole = [];
      let listDetail = [];
      if (responseDetail.status === 200) {
        localStorage.removeItem("account");
        response.data.data._id = responseDetail.data._id;

        responseDetail.data.positionId.listPermission.forEach(element => {
          listDetail.push(element.permissionCode)
        });

        setAccount(response.data.data, inputValues.email, inputValues.password, remember);
      }

      if (listDetail.includes("quan-ly-du-an") === true) {
        listRole.push("quan-ly-du-an")
      }
      if (listDetail.includes("quan-ly-nguoi-dung") === true) {
        listRole.push("quan-ly-nguoi-dung")
      }
      if (listDetail.includes("quan-ly-chuc-vu") === true) {
        listRole.push("quan-ly-chuc-vu")
      }
      if (listDetail.includes("quan-ly-cham-cong") === true) {
        listRole.push("quan-ly-cham-cong")
      }
      if (listDetail.includes("bao-cao-cham-cong") === true) {
        listRole.push("bao-cao-cham-cong")
      }
      if (listDetail.includes("quan-ly-phe-duyet") === true) {
        listRole.push("quan-ly-phe-duyet")
      }
      if (listDetail.includes("quan-ly-ngay-phep") === true) {
        listRole.push("quan-ly-ngay-phep")
      }
      if (listDetail.includes("quan-ly-ngay-le") === true) {
        listRole.push("quan-ly-ngay-le")
      }
      if (listDetail.includes("quan-ly-du-an-sale") === true) {
        listRole.push("quan-ly-du-an-sale")
      }
      if (listDetail.includes("quan-ly-tien-do") === true) {
        listRole.push("quan-ly-tien-do")
      }

      url = url + "";

      if (url === "/quan-ly-du-an") {
        if (listRole.includes('quan-ly-du-an') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }
      if (url === "/quan-ly-nguoi-dung") {
        if (listRole.includes('quan-ly-nguoi-dung') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }
      if (url === "/quan-ly-chuc-vu") {
        if (listRole.includes('quan-ly-chuc-vu') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }
      if (url === "/quan-ly-cham-cong") {
        if (listRole.includes('quan-ly-cham-cong') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }
      if (url === "/bao-cao-cham-cong") {
        if (listRole.includes('bao-cao-cham-cong') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }
      if (url === "/quan-ly-phe-duyet") {
        if (listRole.includes('quan-ly-phe-duyet') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }

      if (url === "/quan-ly-ngay-phep") {
        if (listRole.includes('quan-ly-ngay-phep') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }
      if (url === "/quan-ly-ngay-le") {
        if (listRole.includes('quan-ly-ngay-le') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }
      if (url === "/quan-ly-du-an-sale") {
        if (listRole.includes('quan-ly-du-an-sale') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }
      if (url === "/quan-ly-tien-do") {
        if (listRole.includes('quan-ly-tien-do') === true) {
          window.location = url;
        } else {
          window.location = "/" + listRole[0];
        }
      }

      if (url === "/" || url === "/dashboard" || url === "/login") {
        if (listRole.includes('quan-ly-du-an') || listRole.includes('quan-ly-nguoi-dung')) {
          window.location = "/dashboard";

        } else {
          window.location = "/" + listRole[0];
        }
      }
      return true;
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validate(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));
    if (validForm) {
      setShowLoading(() => true);
      loginWithAccount();
    } else {
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validate(name, value) });
  };


  return (
    <>
      <form onSubmit={handleOnSubmit} >
        <Stack spacing={3} >
          <TextField
            name="email"
            label={
              <span style={{ color: 'grey' }}>Email</span>
            }
            value={inputValues?.email ? inputValues?.email : ''}
            placeholder="Nhập email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={"bxs:user"} sx={{ color: 'black' }} />
                </InputAdornment>
              ),
            }}
            autoComplete="none"
            error={errors.email ? true : false}
            helperText={errors.email}
            onInput={handleOnInput}
            autoFocus
            sx={{
              '& input': {
                color: 'black',
              },
              '& input:valid + fieldset': {
                borderColor: '#62b7fc',
                borderWidth: 1,
              },
              ':hover': {
                '& input:valid + fieldset': {
                  borderColor: '#62b7fc',
                  borderWidth: 1,
                },
              },
            }}
          />

          <TextField
            name="password"
            label={
              <span style={{ color: 'grey' }}>Mật khẩu</span>
            }
            value={inputValues.password ? inputValues.password : ''}
            type={showPassword ? "text" : "password"}
            placeholder="Nhập mật khẩu"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={"bxs:lock"} sx={{ color: 'black' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                      sx={{ color: 'black' }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="none"
            error={errors.password ? true : false}
            helperText={errors.password}
            onInput={handleOnInput}
            sx={{
              '& input': {
                color: 'black',
              },
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: '#62b7fc',
                borderWidth: 1,
              },
              ':hover': {
                '& fieldset.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#62b7fc',
                  borderWidth: 1,
                },
              },
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2, color: 'black' }}
        >
          {account && account.remember === true ? (
            <FormControlLabel
              control={
                <Checkbox
                  name="remember"
                  defaultChecked
                  value={account.remember}
                  onChange={() => setRemember(!account.remember)}
                  sx={{ color: 'black' }}
                />
              }
              label="Ghi nhớ tài khoản"
            />
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  name="remember"
                  value={remember}
                  onChange={() => setRemember(!remember)}
                  sx={{ color: 'black' }}
                />
              }
              label="Ghi nhớ tài khoản"
            />
          )}
          <Link
            to={"/forgot-password"}
            variant="subtitle2"
            style={{ textDecoration: "none", color: "#1b74e4" }}
          >
            Quên mật khẩu?
          </Link>
        </Stack>

        <Button
          className="btn-orange"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ marginBottom: 15, background: '#1776d2', color: 'white' }}
        >
          Đăng Nhập
        </Button>
      </form>
      {isShowActive &&
        <ActiveUserDialog
          open={isShowActive}
          setOpen={setIsShowActive}
          email={inputValues.email}
          password={inputValues.password}
          remember={remember}
        />
      }
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

import Axios from "./../../../../utils/Axios/Axios";

class SaleProject {
  static getAll = async (page, limit, sortOrder, search, sortProperty) =>
    Axios.get("/api/v1/saleProject/getAllSaleProject", {
      page, limit, sortOrder, search, sortProperty
    });

  static create = async (data) => Axios.post("/api/v1/saleProject/createSaleProject", data);

  static delete = async (data) => Axios.delete("/api/v1/saleProject/deleteSaleProject", data);

  static update = async (id, data) => Axios.put(`/api/v1/saleProject/updateSaleProject/${id}`, data);

  static getOne = async (id) => Axios.get(`/api/v1/saleProject/getSaleProject/${id}`);
}

export default SaleProject;
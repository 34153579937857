import Axios from "./../../../../utils/Axios/Axios";

class Position {
    
    static getAll = async (page,limit,sortOrder,search,sortProperty)=>
      Axios.get("/api/v1/position",{
        page,limit,sortOrder,search,sortProperty
      });

      static create = async (data) => Axios.post("/api/v1/position",data);
      static delete = async (data) => Axios.delete("/api/v1/position",data);
      static update = async (id,data) => Axios.put("/api/v1/position/"+id,data);
  }
  
export default Position;
import Axios from "./../../../../utils/Axios/Axios";


class Timekeeping {
  static getAllTimekeeping = async (date,type) => Axios.get(`/api/v1/timekeeping?date=${date}&type=${type}`);

  static attenDance = async (data) => Axios.post("/api/v1/timekeeping",data);

  static deleteTimeKeeping = async (id) => Axios.delete(`/api/v1/timekeeping/${id}`);

  static getAllStatistical = async (month) => Axios.get(`/api/v1/timekeeping/statistical?month=${month}`);

  static getLeaveDays = async (month) => Axios.get(`/api/v1/timekeeping/getLeaveDays`);

  static getRemainingLeaveDays = async (month) => Axios.get(`/api/v1/timekeeping/getRemainingLeaveDays`);

  static getAllTimekeepingDetail = async (month,userId) => Axios.get(`/api/v1/timekeeping/getTimekeepingByUserId?month=${month}&userId=${userId}`);


  
}

export default Timekeeping;

import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment-timezone';
import "react-big-calendar/lib/css/react-big-calendar.css";
import Axios from "../../../utils/Axios";
import { toast } from "react-toastify";

import { Helmet } from "react-helmet";

import { TimekeepingDialog } from "./components/TimekeepingDialog";
import { Autocomplete, Backdrop, Button, CircularProgress, Grid, InputAdornment, TextField } from "@mui/material";
import { TimekeepingUpdateDialog } from "./components/TimekeepingUpdateDialog";
import Iconify from "../../../components/iconify";
import "./index.scss";
import { Box } from "@mui/system";
import { gridSpacing } from "../../../utils/store/constant";
import { styled } from "@mui/material/styles";
import 'moment/locale/vi';
import { DialogLeaveDay } from "./components/DialogLeaveDay";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TimekeepingHoliday } from "./components/TimekeepingHoliday";

moment.tz.setDefault('Etc/GMT+0');

const localizer = momentLocalizer(moment);


// const StyledPaper = styled('div')({
//   backgroundColor: 'white',
//   color: 'black',
//   borderRadius: 2,
//   boxShadow: "0 0 8px #976235",
//   maxHeight: "65vh",
// });


const styleInputFullField = {
  width: "100%",
  mb: 3,
};

let monthData = [];

for (let index = 1; index < 13; index++) {
  let data = {};
  data.title = 'Tháng ' + index
  data.value = index + '';
  monthData.push(data)
}

let monthList = [];


let yearData = [];

let n = 2019;
for (let index = 0; index < 100; index++) {
  let data = {};
  n = n + 1;
  data.title = 'Năm ' + n
  data.value = n + '';
  yearData.push(data)
}

let yearList = [];





const CustomButton = styled
  (Button)(({ theme }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    width: "100%",
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));

yearData.forEach(element => {
  yearList.push(element.value);
});
monthData.forEach(element => {
  if (element.value < 10) {
    element.value = "0" + element.value;
  }
  monthList.push(element.value);
});

let monhYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

const Timekeeping = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isEditUpdate, setIsEditUpdate] = useState(false);
  const [isLeaveDay, setIsLeaveDay] = useState(false);

  const [timekeeping, setTimekeeping] = useState({});
  const [timekeepingUpdate, setTimekeepingUpdate] = useState({});

  const [listEvent, setListEvent] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [month, setMonth] = useState(getMonthFromStringToday(new Date()));
  const [listSatistical, setListSatistical] = useState({});
  const [isDisable, setIsDisable] = useState("isFalse");
  const [remainingLeaveDays, setRemainingLeaveDays] = useState(false);
  const [listLeaveDay, setListLeaveDay] = useState();
  const [validateNote, setvalidateNote] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [type, setType] = useState("month");

  const [defaultMonth, setDefaultMonth] = useState(moment(getMonthFromStringToday(new Date())).toDate());

  const [yearSelect, setYearSelect] = useState(new Date().getFullYear());
  const [indexYear, setIndexYear] = useState(yearList.indexOf(getYear(new Date())));

  const [indexMonth, setIndexMonth] = useState(monthList.indexOf(getMonth(new Date())));
  const [dataHoliday, setDataHoliday] = useState({});
  const [openHoliday, setOpenHoliday] = useState(false);


  function getMonth(dateString) {
    var date = new Date(dateString);
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    return month + "";
  }

  useEffect(() => {
    if (openMenu === false) {
      setType("month");
    } else {
      setType("year");
    }
  }, [openMenu])

  function getYear(dateString) {
    var date = new Date(dateString);
    return date.getFullYear() + "";
  }


  function isWeekend(date) {
    const day = date.getDay();
    return day === 6 || day === 0;
  }

  function dayPropGetter(date) {
    let checkStart = new Date(date).getTime();
    const weekend = isWeekend(date);

    const eventsInSlot = listEvent.filter(
      (event) =>
        new Date(event.start).getTime() === checkStart
    );
    if (eventsInSlot.length > 0) {
      if (weekend === true) {
        return {
          className: 'weekend',
        };
      } else {
        return {
          className: 'background-event',
        };
      }
    } else {
      return {
        className: weekend ? 'weekend' : ''
      };
    }
  }

  const getLeaveDays = async () => {
    setShowLoading(() => true);
    const response = await Axios.Timekeeping.getLeaveDays();
    if (response.status === 200) {

      setListLeaveDay(response.data.listItem);
      setShowLoading(() => false);
    } else {
      setShowLoading(() => false);
    }
  }

  const handleSelected = (slotInfo) => {
    const { start } = slotInfo;

    let checkStart = new Date(start).getTime();
    const eventsInSlot = listEvent.filter(
      (event) =>
        new Date(event.start).getTime() === checkStart
    );
    if (eventsInSlot[0]?.note?.includes('holiday-')) {
      setDataHoliday(eventsInSlot[0]);
      setOpenHoliday(true);
    } else {
      if (eventsInSlot.length > 0) {
        setTimekeepingUpdate(eventsInSlot[0]);
        setIsEditUpdate(true);
      } else {
        const weekend = isWeekend(start);
        if (weekend === true) {
          setvalidateNote("note")
        } else {
          setvalidateNote("")
        }
        const startUtc = moment.tz(start, 'Etc/GMT+0');
        setTimekeeping(startUtc.format());
        let checkMonthYear = new Date(startUtc.format());

        if (checkMonthYear.getMonth() + 1 < new Date().getMonth() + 1 && checkMonthYear.getFullYear() <= new Date().getFullYear()) {

        } else {
          if (checkMonthYear.getMonth() + 1 === new Date(month).getMonth() + 1) {
            setIsEdit(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (openMenu === false) {
      getAllTimekeeping(month, 'month');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  useEffect(() => {
    if (openMenu === true) {
      getAllTimekeeping(yearSelect, 'year');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMenu, yearSelect]);


  const getAllStatistical = async (month) => {
    setShowLoading(() => true);
    const response = await Axios.Timekeeping.getAllStatistical(month);
    if (response.status === 200) {
      setListSatistical(...response.data);
      setShowLoading(() => false);
    } else {
      setShowLoading(() => false);
    }

  }

  const getAllRemainingLeaveDays = async () => {
    setShowLoading(() => true);
    const response = await Axios.Timekeeping.getRemainingLeaveDays();
    if (response.status === 200) {
      setRemainingLeaveDays(response.data.remainingLeaveDays);
      setShowLoading(() => false);
    } else {
      setShowLoading(() => false);
    }

  }


  const getAllTimekeeping = async (data, type) => {
    setShowLoading(() => true);
    let arr = [];
    const response = await Axios.Timekeeping.getAllTimekeeping(data, type);
    if (response.status === 200) {
      if (openMenu === false) {
        getAllStatistical(month);
        getAllRemainingLeaveDays();
        getLeaveDays();
      }

      setShowLoading(() => false);
      response.data.forEach((element) => {
        let listEventObj = {};
        listEventObj._id = element._id;
        if (element.content === "P") {
          if (element.status === false) {
            listEventObj.title = "P - ( acp ) ";
          } else {
            listEventObj.title = "P";
          }
        } else {
          listEventObj.title = element.content;
          if (element.content === "KL") {
            if (element.status === false) {
              listEventObj.title = "KL - ( acp) ";
            } else {
              listEventObj.title = "KL";
            }
          }
        }
        listEventObj.start = element.date;
        listEventObj.end = element.date;
        listEventObj.note = element.note;
        arr.push(listEventObj);
      });
    } else {
      toast.error("Lấy danh sách chấm công thất bại!");
      setShowLoading(() => false);
    }
    setListEvent(arr);
  };

  function getMonthFromStringNext(date) {
    let dateString = date.label;
    const today = new Date();
    const dateFrmat = date.date;

    dateFrmat.setHours(23, 0, 0, 0);
    dateFrmat.setMonth(dateFrmat.getMonth() + 1);

    // ======
    let checkYear = new Date(date.date);
    let monthYear = checkYear.getMonth();
    let year1 = checkYear.getFullYear();

    if (monthYear === 0) { // nếu tháng là tháng 1
      monthYear = 11; // đặt tháng thành tháng 12
      year1--; // giảm năm đi 1
    } else {
      monthYear--; // giảm tháng đi 1
    }

    checkYear.setMonth(monthYear);
    checkYear.setFullYear(year1);

    let checkCurrentYear = new Date(checkYear).getFullYear();
    // console.log(checkCurrentYear, '===',new Date().getFullYear());

    if (dateFrmat.getMonth() >= today.getMonth() && checkCurrentYear >= new Date().getFullYear()) {
      setIsDisable("isFalse")
    }

    let year = dateString.slice(-4);
    let month = parseInt(dateString.substring(6, dateString.length - 5)) + 1 + '';
    if (month > 12) {
      year = parseInt(year) + 1;
      month = 1;
      setIndexYear(indexYear + 1)
    }
    if (month < 10) {
      month = "0" + month;
    }
    var result = year + "-" + month;
    setMonth(result);
    setIndexMonth(monthList.indexOf(month))
    return result;
  }

  function getMonthFromStringPrev(date) {
    let dateString = date.label;
    const today = new Date();
    const dateFrmat = date.date;
    dateFrmat.setHours(0, 0, 0, 0);
    today.setHours(23, 0, 0, 0);
    // ======
    let checkYear = new Date(date.date);
    let monthYear = checkYear.getMonth();
    let year1 = checkYear.getFullYear();

    if (monthYear === 0) { // nếu tháng là tháng 1
      monthYear = 11; // đặt tháng thành tháng 12
      year1--; // giảm năm đi 1
    } else {
      monthYear--; // giảm tháng đi 1
    }

    checkYear.setMonth(monthYear);
    checkYear.setFullYear(year1);

    let checkCurrentYear = new Date(checkYear).getFullYear();

    if (date.date.getTime() < today.getTime() && checkCurrentYear <= new Date().getFullYear()) {
      setIsDisable("isActive")
    }

    let year = dateString.slice(-4);
    let month = (dateString.substring(6, dateString.length - 5)) - 1 + '';
    if (month < 1) {
      year = year - 1;
      month = 12 + '';
      setIndexYear(indexYear - 1)
    }
    if (month < 10) {
      month = "0" + month;
    }

    var result = year + "-" + month;
    setMonth(result);
    setIndexMonth(monthList.indexOf(month))
    setDefaultMonth(moment(result).toDate());
    return result;
  }

  function getMonthFromStringToday(dateString) {

    var date = new Date(dateString);
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let year = date.getFullYear();
    if (month > 12) {
      year = year + 1;
      month = '01';
    }
    var result = year + "-" + month;

    return result;
  }

  const prev = (props) => {

    getMonthFromStringPrev(props);
  };
  const next = (props) => {
    getMonthFromStringNext(props);
  };

  const toDay = (date) => {
    setIsDisable("isFalse");
    getMonthFromStringToday(new Date());
    setMonth(getMonthFromStringToday(new Date()));
    // eslint-disable-next-line no-redeclare
    var date = new Date();

    let monthToday = date.getMonth() + 1;
    if (monthToday < 10) {
      monthToday = "0" + monthToday;
    }
    setDefaultMonth(moment(new Date().getFullYear() + '-' + monthToday).toDate());
    setYearSelect(new Date().getFullYear())
    setIndexMonth(monthList?.indexOf(monthToday))
    setIndexYear(yearList?.indexOf(new Date().getFullYear() + ''))
    if (monthToday > 12) {
      monthToday = 1;
    }
  };

  function getMonthLabel(dateString) {
    let year = dateString.slice(-4);
    let month = (dateString.substring(6, dateString.length - 5));

    let result = "Tháng " + month + " - ( " + year + " )"
    return result;
  }

  const handleChangeYear = (event, newValue) => {

    if (newValue !== null) {
      if (new Date(month).getMonth() + 1 < new Date().getMonth() + 1) {
        if (parseInt(newValue.value) <= new Date().getFullYear()) {
          setIsDisable("isActive")
        } else {
          setIsDisable("isFalse")
        }
      } else {
        if (parseInt(newValue.value) < new Date().getFullYear()) {
          setIsDisable("isActive")
        } else {
          setIsDisable("isFalse")
        }
      }

      let year = newValue.value;
      setIndexYear(yearList.indexOf(newValue?.value))
      setDefaultMonth(moment(year + '-' + month.slice(-2)).toDate());
      setMonth(year + '-' + month.slice(-2));
      setYearSelect(year)
    }
  };

  const handleChangeMonth = (event, newValue) => {
    if (newValue !== null) {
      if (parseInt(newValue.value) < new Date().getMonth() + 1) {
        if (parseInt(yearSelect) <= new Date().getFullYear()) {
          setIsDisable("isActive")
        } else {
          setIsDisable("isFalse")
        }
      } else {

        if (parseInt(yearSelect) < new Date().getFullYear()) {
          setIsDisable("isActive")
        } else {
          setIsDisable("isFalse")
        }
      }
      setIndexMonth(monthList.indexOf(newValue?.value))
      setDefaultMonth(moment(yearSelect + '-' + newValue?.value).toDate());
      setMonth(yearSelect + '-' + newValue?.value);
    }

  };

  const CustomToolbar = (props) => {
    return (
      <>
        <Grid item xs={12} sm={4} md={4} className="lableMonth" sx={{ height: '60px', zIndex: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18, fontWeight: 800 }}>
          {getMonthLabel(props.label)}
        </Grid>

        <Grid rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ background: '', height: '30px', marginBottom: 10, zIndex: 5 }}>
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <Box sx={{ mr: 1 }}> <button
              className="buttonNextPrev"
              style={{ width: 90, borderRadius: '20px 0px 0px 20px', cursor: "pointer", }}
              onClick={() => props.onNavigate("PREV") & prev(props)}
            >
              <Iconify icon={"grommet-icons:chapter-previous"} sx={{ width: '30%', mt: 0.2 }} />
            </button></Box>
            <Box sx={{ mr: 1 }}>
              <button
                className="buttonNextPrev"
                style={{ width: 90, cursor: "pointer", borderRadius: '20px' }}
                onClick={() => props.onNavigate("TODAY") & toDay(props)}
              >
                <Iconify icon={"fluent:calendar-today-16-regular"} sx={{ width: '30%', mt: 0.2 }} />
              </button>
            </Box>
            <button
              className="buttonNextPrev"
              style={{ width: 90, cursor: "pointer", borderRadius: '0px 20px 20px 0px' }}
              onClick={() => props.onNavigate("NEXT") & next(props)}
            >
              <Iconify icon={"grommet-icons:chapter-next"} sx={{ width: '30%', mt: 0.2 }} />
            </button>
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} sx={{ width: '100%', margin: 'auto', mt: -9.5, zIndex: 5 }}>
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={2.9} sm={3} md={1} >
                <Box >X: (<span style={{ fontWeight: "bold" }}>{listSatistical.fullWorkDay}</span>)</Box>
              </Grid>
              <Grid item xs={3.5} sm={3} md={1} >
                <Box >X/2: (<span style={{ fontWeight: "bold" }}>{listSatistical.haftWorkDay}</span>)</Box>
              </Grid>
              <Grid item xs={2.8} sm={3} md={1} >
                <Box >P: (<span style={{ fontWeight: "bold" }}>{listSatistical.vacationDay}</span>)</Box>
              </Grid>
              <Grid item xs={2.8} sm={3} md={1} >
                <Box >H: (<span style={{ fontWeight: "bold" }}>{listSatistical.studyWorkDay}</span>)</Box>
              </Grid>
              {/*  */}
              <Grid item xs={4} sm={3} md={1} >
                <Box >ThS: (<span style={{ fontWeight: "bold" }}>{listSatistical.maternity}</span>)</Box>
              </Grid>
              <Grid item xs={4} sm={3} md={1} >
                <Box >KL: (<span style={{ fontWeight: "bold" }}>{listSatistical.noSalary}</span>)</Box>
              </Grid>
              <Grid item xs={4} sm={3} md={1} >
                <Box >R: (<span style={{ fontWeight: "bold" }}>{listSatistical.remote}</span>)</Box>
              </Grid>

              <Grid item xs={3.1} sm={3} md={1} >
                <Box >TP: (<span style={{ fontWeight: "bold" }}>{remainingLeaveDays}</span>)</Box>
              </Grid>
              <Grid item xs={4.2} sm={3} md={1.5} >
                <Box sx={{ textAlign: 'center', padding: 0.7, borderRadius: 2, boxShadow: 2, background: '#f5fdff', color: 'black', fontWeight: "bold", mt: -0.55 }}>Total: ({listSatistical.totalWork})</Box>
              </Grid>
              <Grid item xs={4.7} sm={3} md={1.5}>
                <CustomButton onClick={handleChange} sx={{ mt: -0.55, boxShadow: 4 }}>Ngày phép</CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


      </>
    );
  };

  function Event(event) {
    return (
      <div >
        {event?.event?.note.includes('holiday-') ? <div
          className="contentTimekeeping"
          style={{
            padding: 3,
            color: "black",
            borderRadius: 15,
            fontWeight: 660,
            backgroundImage: "linear-gradient(to top, #00e676 20%, #00e676 80%)"
          }}
        >
          {event.event.title}
          {event.event.note ? <span style={{ color: 'black' }}> ( Nghỉ lễ )</span> : ""}
        </div> : <Box>
          {event?.event?.title === "P" ? <div
            className="contentTimekeeping"
            style={{
              padding: 3,
              borderRadius: 15,
              fontWeight: 660,
              backgroundImage: "linear-gradient(to top, #f50057 20%, #f50057 80%)",
            }}
          >
            {event.event.title}
            {event.event.note ? <span > ( Note )</span> : ""}
          </div> : <Box> {event?.event?.title === "H" ? <div
            className="contentTimekeeping"
            style={{
              padding: 3,
              color: "white",
              borderRadius: 15,
              fontWeight: 660,
              backgroundImage: "linear-gradient(to top, #ff5722 20%, #ff5722 80%)",

            }}
          >
            {event.event.title}
            {event.event.note ? <span style={{ color: '#ff0080' }}> ( Note )</span> : ""}
          </div> : <div
            className="contentTimekeeping"
            style={{
              padding: 3,
              color: "red",
              borderRadius: 15,
              fontWeight: 660,
              backgroundImage: "linear-gradient(to top, #ccf3fc 20%, #cce3fc 80%)",
            }}
          >
            {event.event.title}
            {event.event.note ? <span style={{ color: '#ff0080' }}> ( Note )</span> : ""}
          </div>}

          </Box>}
        </Box>}


      </div>
    );
  }

  const eventSelected = (data) => {
    const startUtc = moment.tz(data.start, 'Etc/GMT+0');

    let checkMonth = parseInt(startUtc.format().substring(5, startUtc.format().length - 5));

    if (checkMonth < new Date().getMonth() + 1) {

    } else {
      if (data?.note?.includes('holiday-')) {
        setDataHoliday(data);
        setOpenHoliday(true);
      } else {
        setTimekeepingUpdate(data);
        setIsEditUpdate(true);
      }

    }
  };

  const handleChange = (e, value) => {
    setIsLeaveDay(true);
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const CustomToolbarYear = (props) => {
    return (
      <>
        <span style={{ textAlign: "center", fontSize: 18, fontWeight: 800, zIndex: 2, height: '40px' }}>
          {"Tháng " + props.label.substring(6, props.label.length - 5)}
        </span>
      </>
    );

  };

  function EventYear(event) {
    return (
      <div >
        {event?.event?.note.includes('holiday-') ? <div
          style={{
            color: "red",
            borderRadius: 15,
            fontWeight: 660,
            fontSize: '8px',
            backgroundImage: "linear-gradient(to top, #00e676 20%, #00e676 80%)"
          }}
        >
          {event.event.title}
        </div> :
          <Box>
            {event?.event?.title === "P" ? <div
              style={{
                color: "white",
                fontSize: '8px',
                borderRadius: 15,
                fontWeight: 660,
                backgroundImage: "linear-gradient(to top, #f50057 20%, #f50057 80%)",
              }}
            >
              {event.event.title}
            </div> :
              <Box>{event?.event?.title === "H" ? <div
                style={{
                  color: "white",
                  borderRadius: 15,
                  fontWeight: 660,
                  fontSize: '8px',
                  backgroundImage: "linear-gradient(to top, #ff5722 20%, #ff5722 80%)",

                }}
              >
                {event.event.title}
              </div> : <div
                style={{
                  color: "red",
                  borderRadius: 15,
                  fontWeight: 660,
                  fontSize: '8px',
                  backgroundImage: "linear-gradient(to top, #ccf3fc 20%, #cce3fc 80%)",
                }}
              >
                {event.event.title}
              </div>}

              </Box>
            }
          </Box>}
      </div>
    );
  }



  return (
    <>
      {" "}
      <Helmet>
        <title> Chấm công </title>
      </Helmet>
      <p>&nbsp;</p>
      <p style={{ marginTop: '2%' }}>&nbsp;</p>
      <div style={{ width: '100%', margin: 'auto', background: localStorage.getItem("theme") === "dark" ? "#0b1929" : 'white', height: '85vh', color: localStorage.getItem("theme") === "dark" ? "white" : 'black' }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={12} sm={4} md={8} sx={{ height: '50px' }}>
            <FormControl sx={{ width: '100%', mt: -3, ml: 2 }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="month"
              >
                <FormControlLabel value="month" control={<Radio />} label="Tháng" onClick={handleCloseMenu} />
                <FormControlLabel value="year" control={<Radio />} label="Năm" onClick={handleOpenMenu} />
              </RadioGroup>
            </FormControl>
          </Grid>

          {openMenu === false ?
            <Grid item xs={6} sm={6} md={2} sx={{ height: '50px', }}>
              <Box sx={{ width: '90%', margin: 'auto' }}>
                <Autocomplete
                  name="month"
                  onChange={handleChangeMonth}
                  options={monthData}
                  getOptionLabel={(option) => option.title}
                  defaultValue={monthData[indexMonth]}
                  value={monthData[indexMonth]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon={"bx:code-block"} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      variant="standard"
                      label="Chọn tháng"
                      placeholder="Chọn tháng"
                      sx={{
                        styleInputFullField, ...styleInputFullField,
                      }}
                    />
                  )}
                  sx={{ mt: -3 }}
                />
              </Box>
            </Grid>

            : ""}
          <Grid item xs={6} sm={6} md={2} sx={{ height: '20px', marginBottom: 20, zIndex: 5 }}>
            <Box sx={{ width: '90%', margin: 'auto' }}>
              <Autocomplete
                name="year"
                options={yearData}
                getOptionLabel={(option) => option.title}
                defaultValue={yearData[indexYear]}
                value={yearData[indexYear]}
                onChange={handleChangeYear}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"bx:code-block"} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    variant="standard"
                    label="Chọn năm"
                    placeholder="Chọn năm"
                    sx={styleInputFullField}
                  />
                )}
                sx={{ mt: -3 }}
              // PaperComponent={StyledPaper}
              />
            </Box>

          </Grid>
        </Grid>
        {openMenu === false ? "" :
          <Box sx={{ textAlign: 'center', fontSize: 18, fontWeight: 800 }}>
            <div style={{ height: '20px', marginTop: '-145px' }}></div> Năm ( {yearSelect} )</Box>}
        {openMenu === false ? <div style={{ marginTop: 5 }}>
          <div className={isDisable} style={{ height: "50vh", width: '95%', margin: 'auto' }}>
            <Calendar
              selectable
              onSelectSlot={handleSelected}
              components={{ event: Event, toolbar: CustomToolbar }}
              onNavigate={(date, view) => {
              }}
              events={listEvent}
              localizer={localizer}
              eventPropGetter={(event, start, end, isSelected) => {
                let newStyle = {
                  background: 'rgba(0, 0, 0, 0)',
                  textAlign: 'center',
                  marginTop: '-4%'
                };
                return {
                  style: newStyle,
                };
              }}
              date={defaultMonth}
              // style={{ background: '#ffff' }}
              dayPropGetter={dayPropGetter}
              onSelectEvent={eventSelected}
            />
          </div>
          <Box >
            <p style={{ marginLeft: '20px', fontWeight: 600, }}>Data:</p>
            <Grid container spacing={gridSpacing} sx={{ mt: -4.5 }}>
              <Grid item xs={12} sx={{ ml: 6 }}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12} sm={6} md={3}>
                    <span>- Đủ ngày công: <span style={{ fontWeight: 600 }}>( X )</span></span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <span>- Nửa ngày công: <span style={{ fontWeight: 600 }}>( X/2 )</span></span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <span>- Nghỉ phép: <span style={{ fontWeight: 600 }}>( P )</span></span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <span>- Học tập, công tác: <span style={{ fontWeight: 600 }}>( H )</span></span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <span>- Thai sản: <span style={{ fontWeight: 600 }}>( ThS )</span></span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <span>- Không lương: <span style={{ fontWeight: 600 }}>( KL )</span></span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <span>- Remote: <span style={{ fontWeight: 600 }}>( R )</span></span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <span>- Tổng số phép hiện tại chưa dùng / năm: <span style={{ fontWeight: 600 }}> ( TP )</span></span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div> : <Box sx={{ flexGrow: 1, margin: 'auto', width: '98.5%' }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 12 }} >
            {Array.from(Array(12)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div className="isActive" style={{ height: "295pt", width: '100%' }}>
                  <Calendar
                    selectable
                    onSelectSlot={handleSelected}
                    onNavigate={(date, view) => {
                    }}
                    components={{ event: EventYear, toolbar: CustomToolbarYear }}
                    events={listEvent}
                    localizer={localizer}
                    eventPropGetter={(event, start, end, isSelected) => {
                      let newStyle = {
                        background: 'rgba(0, 0, 0, 0)',
                        textAlign: 'center',
                        marginTop: '-3.5%'
                      };
                      return {
                        style: newStyle,
                      };
                    }}
                    date={`Wed ${monhYear[index]} 01 ${yearSelect} 07:00:00 GMT+0700 (Indochina Time)`}
                    // style={{ background: 'white' }}
                    dayPropGetter={dayPropGetter}
                    onSelectEvent={eventSelected}
                  />
                </div>
              </Grid>
              // )

            ))}
          </Grid>
        </Box>
        }</div>


      <TimekeepingDialog
        open={isEdit}
        setOpen={setIsEdit}
        timekeeping={timekeeping}
        onChange={getAllTimekeeping}
        month={month}
        validateNote={validateNote}
        type={type}
      />
      {isLeaveDay &&
        <DialogLeaveDay
          open={isLeaveDay}
          setOpen={setIsLeaveDay}
          listLeaveDay={listLeaveDay}
        />
      }
      <TimekeepingUpdateDialog
        open={isEditUpdate}
        setOpen={setIsEditUpdate}
        timekeeping={timekeepingUpdate}
        onChange={getAllTimekeeping}
        month={month}
        type={type}
      />

      {openHoliday &&
        <TimekeepingHoliday
          open={openHoliday}
          setOpen={setOpenHoliday}
          data={dataHoliday}
        />
      }
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

export default Timekeeping;

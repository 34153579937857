import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import Axios from "../../../../utils/Axios";
import { toast } from "react-toastify";
import useValidator from "../../../../utils/Validator";
import DateTimeOfDay from "../../../../utils/DateTimeOfMessage/DateTimeOfDay";
import { styled } from "@mui/material/styles";


const CustomButton = styled
  (Button)(({ theme }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));

const styleInputFullField = {
  width: "100%",
  mb: 3,
  ml: 2,
};

const content = [
  { content: "Đủ ngày công", value: "X" },
  { content: "Nửa ngày công", value: "X/2" },
  { content: "Nghỉ phép", value: "P" },
  { content: "Học tập, công tác", value: "H" },
  { content: "Thai sản", value: "ThS" },
  { content: "Không lương", value: "KL" },
  { content: "Remote", value: "R" },
];
let arr = [];
content.forEach((element) => {
  arr.push(element.value);
});

const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      {children}
    </Grid>
  );
};

export const TimekeepingUpdateDialog = ({
  open = false,
  setOpen,
  timekeeping,
  onChange,
  month,
  type
}) => {
  const { validateTimeKeeping } = useValidator();

  const [timekeepingReg, setTimekeepingReg] = useState({
    date: "",
    content: timekeeping.content,
    note: "",
  });

  const [showLoading, setShowLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    date: "",
    content: "",
    note: "",
  });

  const [openDialog, setOpenDialog] = React.useState(false);


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const [errors, setErrors] = useState({
    date: "",
    content: "",
    note: "",
  });

  useEffect(() => {
    setErrors({
      content: "",
    });
    setTimekeepingReg({
      date: "",
      content: timekeeping.content,
      note: timekeeping?.note?.includes('holiday-') ? timekeeping?.note?.slice(8) : timekeeping?.note,
    });
  }, [timekeeping]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateTimeKeeping(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      attenDance();
    } else {
      console.log("false");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateTimeKeeping(name, value) });
  };

  const attenDance = async () => {
    setShowLoading(() => true);
    timekeepingReg.date = timekeeping.start;
    const response = await Axios.Timekeeping.attenDance(timekeepingReg);
    if (response.status === 200) {
      onChange(month, type);
      toast.success("Cập nhật thành công");
      setOpen(false);
      setShowLoading(() => false);
    } else {
      setShowLoading(() => false);
      toast.error("Cập nhật thất bại!");
    }
  };

  const deleteTimeKeeping = async () => {
    setShowLoading(() => true);
    timekeepingReg.date = timekeeping.start;
    const response = await Axios.Timekeeping.deleteTimeKeeping(timekeeping._id);
    if (response.status === 200) {
      onChange(month, type);
      setOpenDialog(false);
      toast.success("Xoá điểm danh thành công");
      setOpen(false);
      setShowLoading(() => false);
    } else {
      setShowLoading(() => false);
      toast.error("Xoá điểm danh thất bại!");
    }
  };

  const handleChange = (e, target) => {
    setTimekeepingReg({
      ...timekeepingReg,
      content: target?.value,
    });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="10000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle sx={{ textAlign: 'center', fontSize: 17, fontWeight: 800 }}>
            Cập nhật - ({" "}
            <DateTimeOfDay dateTime={timekeeping.start} /> )
          </DialogTitle>
          <Divider />
          <DialogContent >
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <LayoutFormTwoField>
                <Grid item xs={8}>
                  <Autocomplete
                    name="listcontent"
                    options={content}
                    defaultValue={content[arr.indexOf(timekeeping.title)]}
                    getOptionLabel={(option) => option?.content}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        name="content"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"bx:code-block"} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        variant="standard"
                        label="Nội dung"
                        placeholder="Chọn nội dung"
                        sx={styleInputFullField}

                        error={errors.content ? true : false}
                        helperText={errors.content}
                      />
                    )}

                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomButton
                    sx={{
                      background: "#56d446",
                      float: 'left',
                      mt: 1,
                      ml:'20%'
                    }}
                    onClick={handleClickOpen} variant="contained">Xoá</CustomButton>
                </Grid>
              </LayoutFormTwoField>
              <Box sx={{ml:7}}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</Box>
              <LayoutFormTwoField>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    maxRows={5}
                    label="Ghi chú"
                    placeholder="Nhập Ghi Chú"
                    value={timekeepingReg?.note ? timekeepingReg?.note : ""}
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"material-symbols:edit-note-sharp"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setTimekeepingReg({
                        ...timekeepingReg,
                        note: e.target.value,
                      });
                    }}
                    autoComplete="none"
                    sx={{
                      styleInputFullField,
                      ...styleInputFullField
                    }}
                    onInput={handleOnInput}
                  />
                </Grid>
               
              </LayoutFormTwoField>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton type="submit" variant="contained">Cập nhật</CustomButton>
            <CustomButton
              sx={{ background: "#f72533" }}
              onClick={handleClose} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận xoá?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={deleteTimeKeeping}>Xác nhận</CustomButton>
            <CustomButton onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
              Huỷ
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>

      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

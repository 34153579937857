import React, { useEffect, useState, useRef } from 'react';
import {
    DragDropContext,
    Droppable,
} from 'react-beautiful-dnd';
import styled from "styled-components";
import Column from './Column';
// import '@atlaskit/css-reset'
import { Helmet } from 'react-helmet';
import { Backdrop, Box, Button, CircularProgress, Grid, InputAdornment, TextField } from '@mui/material';
import "./index.scss"
import Iconify from "../../components/iconify/Iconify";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import { DialogMember } from '../../components/views/TaskManagement/components/DialogMember';
import Axios from '../../utils/Axios';
import { toast } from "react-toastify";


const Container = styled.div`
    display:flex;
`
class InnerList extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.column === this.props.column
            && nextProps.taskMap === this.props.taskMap &&
            nextProps.index === this.props.index) {
            return false;
        }
        return true;
    }
    render() {
        const { column, taskMap, index, getListTaskStatus, listUser } = this.props;

        const tasks = column.listTask = taskMap.listTask;
        return <Column column={column} tasks={tasks} index={index} getListTaskStatus={getListTaskStatus} listUser={listUser} />
    }
}

export default function TaskProject() {

    const { _id } = useParams();
    const [listTask, setListTask] = useState([]);
    const [open, setOpen] = useState(false);
    const [isMember, setIsMember] = useState(false);

    const [expanded, setExpanded] = React.useState(false);
    const accordionRef = useRef(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [showLoading, setShowLoading] = useState(false);
    const [taskStatus, setTaskStatus] = useState({});

    const [project, setProject] = useState({});
    const [errors, setErrors] = useState({
    });
    const [listUserInProject, setListUserInProject] = useState([]);
    const [nameProject, setNameProject] = useState();



    useEffect(() => {
        getListTaskStatus();
        let data = {};
        // data.name = nameProject;
        data._id = _id;
        setProject(data);
        getNameProject(_id)
        getListUserInProject(data._id, 0, 10, -1, "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getNameProject = async (_id) => {
        setShowLoading(() => true);
        const response = await Axios.WorkProject.getOne(_id);

        if (response.status === 200) {
            setShowLoading(() => false);
            setNameProject(response.data.name);
        } else {
            setShowLoading(() => false);
        }
    };
    const getListUserInProject = async (id, page, limit, sortOrder, property) => {
        setShowLoading(() => true);
        const response = await Axios.WorkProject.getListUserInProject(
            id,
            page,
            limit,
            sortOrder,
            "",
            property
        );

        if (response.status === 200) {
            setShowLoading(() => false);
            setListUserInProject(response.data.listItem);
        } else {
            setShowLoading(() => false);
            toast.error("Lấy danh sách dự án thất bại!");
        }
    };



    const getListTaskStatus = async () => {
        const response = await Axios.TaskProject.getListTaskStatus(
            _id,
        );
        if (response.status === 200) {
            setPage(page);
            setLimit(limit);
            setListTask(response.data);

        } else {
            toast.error("Lấy danh sách dự án thất bại!");
        }
    };






    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (accordionRef.current && !accordionRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [accordionRef]);

    const changeStatus = async (data) => {
        const response = await Axios.TaskProject.changeStatus(data);
        if (response.status === 200) {
            getListTaskStatus();
        } else {
            toast.error("Kéo task thất bại!");

        }
    };


    const onDragEnd = result => {
        document.body.style.color = 'inherit'
        document.body.style.backgroundColor = 'inherit'
        const { destination, source, draggableId } = result;

        let data = {};


        if (draggableId !== undefined && source?.droppableId !== undefined && destination?.droppableId !== undefined) {

            // console.log('id====>', draggableId)
            // console.log('start', source?.droppableId)
            // console.log('finish', destination?.droppableId);

            if (source?.droppableId !== destination?.droppableId) {

                let indexStart = listTask.findIndex(item => item._id === source?.droppableId);
                let indexEnd = listTask.findIndex(item => item._id === destination?.droppableId);
                let indexArray = listTask[indexStart].listTask.filter(item => item._id === draggableId);

                let objData = indexArray[0];

                let arr = listTask.map(obj => ({
                    ...obj,
                    listTask: obj.listTask.filter(child => child._id !== draggableId)
                }))
                arr[indexEnd].listTask.push(objData)
                setListTask(arr);


                data.fromTaskStatusId = source?.droppableId;
                data.toTaskStatusId = destination?.droppableId;
                data.taskId = draggableId;
                changeStatus(data);
            }

        }

    }



    const createTaskStatus = async () => {
        if (Object.keys(taskStatus).length !== 0) {
            if (checkIfAllWhitespace(taskStatus.name) === false) {
                taskStatus.description = "";
                const response = await Axios.TaskProject.createTaskStatus(_id, taskStatus);
                if (response.status === 200) {
                    getListTaskStatus();
                    toast.success("Tạo cột thành công");
                    setOpen(false);
                } else {
                    if (response.status === 400 && response.code === 22) {
                        setErrors({
                            name: "Tên cột đã tồn tại!",
                        });
                        toast.error("Tên cột đã tồn tại!");
                    } else {
                        toast.error("Tạo cột thất bại");
                    }

                }
            } else {
                setOpen(false);
            }
        } else {
            setOpen(false);
        }

    };

    function checkIfAllWhitespace(str) {
        return str.trim().length === 0;
    }

    return (
        <>
            <Helmet>
                <title> Tiến độ dự án </title>
            </Helmet>
            <Grid container spacing={2} sx={{    background:localStorage.getItem("theme")==="dark"?"#0b1929":'white', }}>
                <Grid item xs={2} sm={4} md={4} sx={{ ml: 2, mt: 12 }} >
                    <Box sx={{ fontWeight: 600, color: localStorage.getItem("theme") === "dark" ? "white" : '#121212' }}>{nameProject}</Box>
                </Grid>
                <Grid item xs={1.5} sx={{ ml: 70, mr: 5, mt: 10, flexGrow: 1, position: 'fixed', zIndex: 1 }}>
                    <div ref={accordionRef}>
                        <Accordion expanded={expanded === 'panel1'} sx={{ width: '250px', boxShadow: 2 }} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '100%', fontWeight: 600, flexShrink: 0, mt: 1 }}>
                                    ( {listUserInProject?.length} ) Thành viên
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ mt: -2 }}>
                                    {listUserInProject.map((item, index) => {
                                        return <Box sx={{ mb: 0.5 }} key={item._id}>
                                            {index + 1}. {item.fullname}
                                        </Box>
                                    })}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
            </Grid>
            <div className='divDragDropContext' style={{     background:localStorage.getItem("theme")==="dark"?"#0b1929":'white', marginTop: '0px' }}>
                <span className='spanDragDropContext'>
                    <DragDropContext
                        onDragEnd={onDragEnd}
                    >
                        <Droppable
                            droppableId="all-columns"
                            direction="horizontal"
                            type="column">
                            {provided => (
                                <Container
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {listTask.map((columnId, index) => {
                                        return <InnerList
                                            key={columnId._id}
                                            column={columnId}
                                            taskMap={columnId}
                                            index={index}
                                            getListTaskStatus={getListTaskStatus}
                                            listUser={listUserInProject}
                                        />;
                                    })}
                                    {provided.placeholder}
                                    <Grid container spacing={2} sx={{ width: '370px', mt: 5.5, }} >
                                        <Grid item xs={12} >
                                            {open === true ?
                                                <Box sx={{ textAlign: 'center' }}>
                                                    <TextField
                                                        component="div"
                                                        name="name"
                                                        label="Tên cột"
                                                        multiline
                                                        maxRows={3}
                                                        variant="standard"
                                                        placeholder="Nhập tiêu đề cho cột..."
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Iconify icon={"material-symbols:edit-note-sharp"} />
                                                                </InputAdornment>
                                                            ),

                                                        }}
                                                        onChange={(e) => {
                                                            setTaskStatus({ ...taskStatus, name: e.target.value });
                                                        }}
                                                        autoComplete="none"
                                                        sx={{
                                                            width: '230px',
                                                            mb: 20
                                                        }}
                                                        error={errors.name ? true : false}
                                                        helperText={
                                                            errors.name
                                                        }
                                                        onBlur={() => createTaskStatus()}
                                                        autoFocus
                                                    />
                                                    <Grid item xs={12} sx={{ mt: -18 }}>
                                                        <Button
                                                            sx={{
                                                                height: '45px',
                                                                color: "white",
                                                                minWidth: '100px',
                                                                paddingLeft: 2.2,
                                                                paddingRight: 3,
                                                                background: '#ff1744',
                                                                fontWeight: 600,
                                                                width: '60%',
                                                                mr: 10,
                                                                "&:hover": {
                                                                    background: '#ff5252',
                                                                },
                                                                margin: 'auto',
                                                                boxShadow: 2,
                                                                borderRadius: 2,
                                                            }}
                                                            onClick={(e => { setOpen(true) })}

                                                        >
                                                            <Iconify icon={"material-symbols:add"} />
                                                            Thêm cột</Button>
                                                    </Grid>
                                                </Box>
                                                : <Grid item xs={12} >
                                                    <Button
                                                        sx={{
                                                            height: '45px',
                                                            color: "white",
                                                            minWidth: '100px',
                                                            paddingLeft: 2.2,
                                                            paddingRight: 3,
                                                            background: '#ff1744',
                                                            fontWeight: 600,
                                                            width: '60%',
                                                            mr: 10,
                                                            "&:hover": {
                                                                background: '#ff5252',
                                                                // color: "black",
                                                            },
                                                            ml: 8,
                                                            boxShadow: 2,
                                                            borderRadius: 2,
                                                        }}
                                                        // eslint-disable-next-line no-unused-expressions
                                                        onClick={(e => { setOpen(true) & setErrors({}) & setTaskStatus({}) })}

                                                    >
                                                        <Iconify icon={"material-symbols:add"} />
                                                        Thêm cột </Button>
                                                </Grid>}

                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} sx={{ ml: 8 }}>
                                        &nbsp;
                                    </Grid>
                                </Container>
                            )}
                        </Droppable>
                    </DragDropContext>
                </span>
            </div>
            {/* </Box> */}
            {isMember &&
                <DialogMember
                    open={isMember}
                    setOpen={setIsMember}
                    project={project}
                />
            }
            <Backdrop
                sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "25%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color="primary" sx={{ zIndex: 9 }} />
            </Backdrop>
        </>
    );
}
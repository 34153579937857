// import { format } from "date-fns";
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import SearchInput from "../../components/views/sections/@dashboard/search/SearchInput";
import ProjectSearchBox from "../../components/views/sections/@dashboard/search/ProjectSearchBox";
import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Axios from "../../utils/Axios";
import DateTimeOfMessage from "../../utils/DateTimeOfMessage/DateTimeOfMessage";
import { DialogEditWork } from "../../components/views/projectManagement/components/DialogEditWork";
import BasicSpeedDial from "./BasicSpeedDial";

const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#f7ebf5",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#f7ebf5",
  },
};
const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%", mb: 2 }}>
      {children}
    </Grid>
  );
};

export default function WorkDetailsPage(props) {
  const { _id } = useParams();
  const [project, setProject] = useState({});

  const [isEdit, setIsEdit] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [listRole, setListRole] = useState(props.listRole);
  const [open, setOpen] = useState(false);
  const [isFocusPopup, setFocusPopup] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    getOneProject(_id);
  }, [_id]);

  const getOneProject = async (_id) => {
    if (_id !== undefined) {
      setShowLoading(() => true);
      const response = await Axios.Project.getOne(_id);
   
      if (response.status === 200) {
        setShowLoading(() => false);
        setProject(response.data);
      } else {
        setShowLoading(() => false);
        setProject(null);
      }
    }
  };

  const handleOpen = (event) => {
    searchAllWork();
    setOpen(event.currentTarget);
  };

  const searchAllWork = async () => {
    const response = await Axios.Project.getAll(0, 30, -1, "", "");
    if (response.status === 200) {
      setSearchList(response.data.listItem);
    } else {
    }
  };

  const handleSearch = async (e) => {
    const response = await Axios.Project.getAll(
      0,
      100,
      -1,
      e.target.value,
      ""
    );
    if (response.status === 200) {
      setSearchList(response.data.listItem);
    } else {
    }
  };

  const handleClose = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
    }
  };
  const handleClose2 = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
      setFocusPopup(false);
    } else {
      setFocusPopup(false);
    }
  };

  const handleUpdateWork = () => {
    setIsEdit(true);
  };

  return (
    <>
      <Helmet>
        <title> Chi tiết công việc </title>
      </Helmet>
    <div style={{width:'100%'}}>

      <Box onFocus={handleOpen} onBlur={handleClose}>
        <SearchInput className="searchInput" onInput={handleSearch} sx={{ mt: 14 }} />
        <Card
          className="searchInput"
          hidden={!open}
          sx={{
            position: "fixed",
            p: 0,
            borderRadius: 2,
            boxShadow: "0 0 8px #976235",
            maxHeight: "45vh",
            zIndex: 99999,
          }}
        >
          <Box
            onBlur={handleClose}
            onMouseEnter={() => setFocusPopup(true)}
            onMouseLeave={handleClose2}
          >
            <CardHeader
              title={
                <Typography fontWeight={600} fontSize={16}>
                  {"Danh sách tìm kiếm"}
                </Typography>
              }
              sx={{ pb: 2, height: 24 }}
            />
            <Divider />

            <CardContent
              sx={{
                p: "0 !important",
                maxHeight: "calc(40vh - (14*2px))",
                overflow: "auto",
                ...scrollbar,
              }}
            >
              {searchList.length !== 0 ? "" : <span style={{pb:2, marginLeft: '5%' }}>( Thông tin tìm kiếm không có! )<p></p></span>}
              {searchList?.map((searchData, index) => (
                <ProjectSearchBox key={index} searchData={searchData} setOpen={setOpen} />
              ))}
            </CardContent>
          </Box>
        </Card>
      </Box>

      <Box sx={{ width: "100%", mt: 3 }}>
        <Box
          sx={{
            width: "98%",
            borderRadius: "12px",
            boxShadow: 5,
            padding: 2,
            margin: 'auto'
          }}
        >
          <LayoutFormTwoField>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>Dự án:</Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>{project.projectName}</Box>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>Chủ đầu tư:</Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>{project.investor}</Box>
            </Grid>
          </LayoutFormTwoField>
          <Divider sx={{ mb: 1.5 }} />

          <LayoutFormTwoField>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>Đầu mối:</Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>{project.intermediary}</Box>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>Hiện trạng:</Box>{" "}
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>{project?.contractStatus?.statusName}</Box>
            </Grid>
          </LayoutFormTwoField>
          <Divider sx={{ mb: 1.5 }} />
          <LayoutFormTwoField>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>
                Tiến độ kỹ thuật:
              </Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>
                {project.technicalProgress}
              </Box>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>
                Tiến độ lắp đặt:
              </Box>{" "}
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>
                {project.installationProgress}
              </Box>
            </Grid>
          </LayoutFormTwoField>
          <Divider sx={{ mb: 1.5 }} />
          <LayoutFormTwoField>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>
                Tồn đọng kỹ thuật / thiết bị:
              </Box>{" "}
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>{project.issue}</Box>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>Hướng xử lý:</Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>{project.solution}</Box>
            </Grid>
          </LayoutFormTwoField>
          <Divider sx={{ mb: 1.5 }} />

          <LayoutFormTwoField>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>
                Thời gian xử lý:
              </Box>{" "}
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>{project.processingTime}</Box>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>
                Giao hàng - Lắp Đặt - Bàn giao - Nghiệm Thu:
              </Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>{project.projectStatus}</Box>
            </Grid>
          </LayoutFormTwoField>
          <Divider sx={{ mb: 1.5 }} />

          <LayoutFormTwoField>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>Ngày tạo: </Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>
                {" "}
                <DateTimeOfMessage dateTime={project.createdAt} />
              </Box>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>Người tạo: </Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>
                {project.createdBy?.fullname} {" - ("}{" "}
                {project.createdBy?.email} {" )"}
              </Box>
            </Grid>
          </LayoutFormTwoField>
          <Divider sx={{ mb: 1.5 }} />

          <LayoutFormTwoField>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>
                Ngày cập nhật:{" "}
              </Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>
                <DateTimeOfMessage dateTime={project.updatedAt} />{" "}
              </Box>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5.5}>
              <Box sx={{ color: "red", fontWeight: 900 }}>
                Người cập nhật:{" "}
              </Box>
              <Box sx={{ ml: 2,color:localStorage.getItem("theme")==="dark"?"white":'black' }}>
                {project.updatedBy?.fullname}{" "}
                {project.updatedBy !== null
                  ? " - ( " + project.updatedBy?.email + " )"
                  : "Chưa có người cập nhật"}
              </Box>
            </Grid>
          </LayoutFormTwoField>
        </Box>
      </Box>
      </div>
      {listRole.includes('quan-ly-du-an-cap-nhat') ? <BasicSpeedDial onchange={handleUpdateWork} /> : ""}

      {isEdit &&  <DialogEditWork
        key={1}
        open={isEdit}
        setOpen={setIsEdit}
        project={project}
        getOneProject={getOneProject}
        listRole={listRole}
      />}
     
      
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
}

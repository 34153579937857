import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";
// component
import Axios from "../../../../../utils/Axios";
import React, { useState } from "react";
import { Box } from "@mui/system";
import SearchInput from "../search/SearchInput"
import TimekeepingSearchBox from "../search/TimekeepingSearchBox";


// ----------------------------------------------------------------------
const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#f7ebf5",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#f7ebf5",
  },
};

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

TimekeepingListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function TimekeepingListToolbar({
  numSelected,
  selected,
  onChange,
  page,
  limit,
  setListTimekeeping,
  month,
  handleTimekeeping
}) {
  const [searchList, setSearchList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isFocusPopup, setFocusPopup] = useState(false);

  const searchAll = async () => {
    const response = await Axios.TimekeepingManagement.getAll(month, 0, 10, -1, "", "");

    if (response.status === 200) {
      setSearchList(response.data.listItem);
      let arr = [];
      response.data.listItem.forEach(element => {
        let obj = {};
        obj._id = element.user._id;
        obj.user = element.user;
        obj.fullname = element.user.fullname;
        obj.positionName = element.user.positionName;
        obj.fullWorkDay = element.fullWorkDay;
        obj.haftWorkDay = element.haftWorkDay;
        obj.vacationDay = element.vacationDay;
        obj.studyWorkDay = element.studyWorkDay;
        obj.maternity = element.maternity;
        obj.noSalary = element.noSalary;
        obj.remote = element.remote;
        obj.totalWork = element.totalWork;
        arr.push(obj)
        element?.listTimekeeping?.forEach(item => {
          let day = new Date(item.date).getDate();
          obj[day] = item.content;
        });
        obj.color = 'black'
      });


      setListTimekeeping(arr);
    } else {

    }
  };

  const handleSearch = async (e) => {
    const response = await Axios.TimekeepingManagement.getAll(month, 0, 10, -1, e.target.value, "");
    if (response.status === 200) {
      setSearchList(response.data.listItem);
      let arr = [];
      response.data.listItem.forEach(element => {
        let obj = {};
        obj._id = element.user._id;
        obj.user = element.user;
        obj.fullname = element.user.fullname;
        obj.positionName = element.user.positionName;
        obj.fullWorkDay = element.fullWorkDay;
        obj.haftWorkDay = element.haftWorkDay;
        obj.vacationDay = element.vacationDay;
        obj.studyWorkDay = element.studyWorkDay;
        obj.maternity = element.maternity;
        obj.noSalary = element.noSalary;
        obj.remote = element.remote;
        obj.totalWork = element.totalWork;
        arr.push(obj)
        element?.listTimekeeping?.forEach(item => {
          let day = new Date(item.date).getDate();
          obj[day] = item.content;
        });
        obj.color = 'black'
      });


      setListTimekeeping(arr);
    } else {
    }
  };

  const handleOpen = (event) => {
    searchAll();
    setOpen(event.currentTarget);
  };

  const handleClose = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
    }
  };
  const handleClose2 = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
      setFocusPopup(false);
    } else {
      setFocusPopup(false);
    }
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          borderRadius: 50,
        }),
      }}
    >
      <Box onFocus={handleOpen} onBlur={handleClose}>
      <SearchInput position="start" onInput={handleSearch} />
        <Card
          hidden={!open}
          className="cardSearch"
          sx={{
            position: "fixed",
            p: 0,
            borderRadius: 2,
            boxShadow: "0 0 8px #976235",
            maxHeight: "45vh",
            zIndex: 99999,
          }}
        >
          <Box
            onBlur={handleClose}
            onMouseEnter={() => setFocusPopup(true)}
            onMouseLeave={handleClose2}
          >
            <CardHeader
              title={
                <Typography fontWeight={600} fontSize={16}>
                  {"Danh sách tìm kiếm"}
                </Typography>
              }
              sx={{ pb: 2, height: 24 }}
            />
            <Divider />

            <CardContent
              sx={{
                p: "0 !important",
                maxHeight: "calc(40vh - (14*2px))",
                overflow: "auto",
                ...scrollbar,
              }}
            >
              {searchList.length !== 0 ? "" : <span style={{ marginLeft: '5%',pb:2 }}>( Thông tin tìm kiếm không có! )<p></p></span>}
              {searchList?.map((searchData, index) => (
                <TimekeepingSearchBox key={index} searchData={searchData} setOpen={setOpen} handleTimekeeping={handleTimekeeping} />
              ))}
            </CardContent>
          </Box>
        </Card>
      </Box>
    </StyledRoot>
  );
}

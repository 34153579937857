import PropTypes from "prop-types";

// material-ui
import { Avatar, Box, ButtonBase, AppBar, Toolbar, Stack, IconButton } from "@mui/material";

// project imports
import LogoSection from "./LogoSection";
import ProfileSection from "./ProfileSection";
import { styled } from "@mui/material/styles";

// assets
import { IconMenu2 } from '@tabler/icons-react';
import { IconMoon, IconBrightnessUp } from '@tabler/icons-react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../utils/store/actions";
const HEADER_MOBILE = 54;

const HEADER_DESKTOP = 72;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // backgroundColor: "var(--web-wash)",
  boxShadow: "1.5px 1.5px 1.5px #9E9E9E",
  background:localStorage.getItem("theme")==="dark"?"#0b1929":'white',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
    
  },
}));
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const customization = useSelector((state) => state.customization);

  const [themeChange, setThemeChange] = useState(customization.mode);


  const dispatch = useDispatch();

  const handleTheme = () => {
    let data = 'light';
    if (themeChange === 'light') {
      data = 'dark';
      setThemeChange('dark')
      localStorage.setItem("theme", 'dark')
    } else {
      setThemeChange('light')
      localStorage.setItem("theme", 'light')
    }

    dispatch(setTheme(data));

  }

  return (
    <>
      <StyledRoot>
        <StyledToolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",

            }}
          >
            <Box sx={{ display: "inline-flex" ,}}>
              <LogoSection />
              <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden", }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    "&:hover": {
                      backgroundImage: "linear-gradient(to left, #ccf3fc 20%, #cce3fc 80%)",
                      color: "red",
                    },
                    marginLeft: 8,
                    backgroundImage: "linear-gradient(to top, #ccf3fc 20%, #cce3fc 80%)",
                    color: "blue",
                  }}
                  onClick={handleLeftDrawerToggle}
                  color="inherit"
                >
                  <IconMenu2 stroke={1.5} size="1.3rem" />
                </Avatar>
              </ButtonBase>
            </Box>


            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{ marginRight: 1 }}
            >
              <Box
                sx={{
                  paddingRight: 3,
                }}
              >
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <IconButton onClick={handleTheme} sx={{ ml: 1 }} color="inherit">
                  {customization.mode !== 'light' ? <IconMoon /> : <IconBrightnessUp style={{ color: 'black' }} />}
                </IconButton>
              </Box>
              <ProfileSection />
            </Stack>
          </Box>
        </StyledToolbar>
      </StyledRoot>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;

import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
// component
import Iconify from "../../../../../components/iconify";
import Axios from "../../../../../utils/Axios";
import { toast } from "react-toastify";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./index.scss"


const CustomButton = styled
  (Button)(({ theme }) => ({
    backgroundColor: 'green', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 50,
    paddingRight:50,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#5351ed', // màu nền khi hover vào button

    },
  }));

const CustomButtonApprove = styled
  (Button)(({ theme }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));

// ----------------------------------------------------------------------
// const scrollbar = {
//   "::-webkit-scrollbar": {
//     width: "5px",
//   },
//   ":hover::-webkit-scrollbar-thumb": {
//     " -webkit-border-radius": "5px",
//     borderRadius: "5px",
//     background: "#dee2e3",
//   },
//   "::-webkit-scrollbar-thumb:window-inactive": {
//     background: "#dee2e3",
//   },
// };

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
  marginTop:'80px'
}));

// ----------------------------------------------------------------------

LaveDayListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function LaveDayListToolbar({
  numSelected,
  selected,
  onChange,
  page,
  limit,
}) {

  const [showLoading, setShowLoading] = useState(false);


  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogDelete = () => {
    approve(false);
    setOpenDialog(false);
  };



  const approve = async (status) => {
    let data = {};
    data.status = status;
    data.listLeaveDay = selected
    setShowLoading(() => true);
    const response = await Axios.LeaveDay.approve(data);
    if (response.status === 200) {
      setShowLoading(() => false);
      onChange(page, limit, -1, "");
      toast.success("Phê duyệt thành công!");
    } else {
      setShowLoading(() => false);
      toast.error("Phê duyệt thất bại!");
    }
  };
  return (
    <>
      <StyledRoot
        sx={{
          ...(numSelected > 0 && {
            borderRadius: 50,
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitl1" sx={{ fontSize: 20, fontWeight: 600, fontFamily: "sans-serif" }}>
            <span style={{  fontWeight: "bold" }}>
              {numSelected}
            </span>{" "}
            người đã chọn
          </Typography>
        ) : (
         ""
        )}

        {numSelected > 0 ? (
          <div>
            <Tooltip title="Phê duyệt">
              <CustomButton
                sx={{ color: "white", border: "none" }}
                onClick={() =>
                  approve(true)
                }
              >
                <Iconify icon="mdi:check-all" />
              </CustomButton>
            </Tooltip>
            &emsp;&emsp;&emsp;&emsp;
            <Tooltip title="Từ chối">
              <CustomButton
                sx={{ color: "white", border: "none", background: 'red' }}
                onClick={handleClickOpen}
              >
                <Iconify icon="bi:sign-no-parking" />
              </CustomButton>
            </Tooltip>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle width={400} id="alert-dialog-title">
                <Typography
                  component="div"
                  sx={{ fontSize: 20 }}
                  variant="subtitl1"
                >
                  {"Xác nhận từ chối?"}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography
                    component="span"
                    sx={{ fontSize: 20, fontFamily: "bold" }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {numSelected}
                    </span>{" "}
                    người đã chọn
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <CustomButtonApprove onClick={handleCloseDialogDelete}>Xác nhận</CustomButtonApprove>
                <CustomButtonApprove onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
                  Huỷ
                </CustomButtonApprove>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          ""
        )}
      </StyledRoot>
      <Backdrop
        sx={{ color: "#fff",background:'rgba(0, 0, 0, 0)',width:"50%",margin:'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
}

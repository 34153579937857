/* eslint-disable no-unused-vars */
// material-ui
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import Iconify from "../../../components/iconify";

import Axios from "../../../utils/Axios";
import { toast } from "react-toastify";

import { Helmet } from "react-helmet";
import { styled } from "@mui/material/styles";

import { useState } from "react";
import { useEffect } from "react";
import { TimekeepingDetail } from "./components/TimekeepingDetail";
import { gridSpacing } from "../../../utils/store/constant";

import moment from 'moment-timezone';
import 'moment/locale/vi';
import TimekeepingManagementListHead from "../sections/@dashboard/TimekeepingManagement/TimekeepingListHead";
import TimekeepingListToolbar from "../sections/@dashboard/TimekeepingManagement/TimekeepingListToolbar";
import ExcelService from "../../../utils/exportExcel/excelService";
import { useSelector } from "react-redux";

// ==============================||  ||============================== //

moment.tz.setDefault('Etc/GMT+0');

const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#dee2e3",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#dee2e3",
  },
};




const styleInputFullField = {
  width: "100%",
  mb: 3,
};



const CustomButton = styled(Button)(({ theme }) => ({
  background: '#00e676',
  borderColor: '#00e676',
  color: '#263238', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 8,
  '&:hover': {
    background: '#00e676',
  },
}));

let monthData = [];

for (let index = 1; index < 13; index++) {
  let data = {};
  data.title = 'Tháng ' + index
  if (index < 10) {
    index = '0' + index;
  }
  data.value = index + '';
  monthData.push(data)
}
let monthList = [];
monthData.forEach(element => {
  monthList.push(element.value);
});

let yearData = [];

let n = 2022;
for (let index = 0; index < 100; index++) {
  let data = {};
  n = n + 1;
  data.title = 'Năm ' + n
  data.value = n + '';
  yearData.push(data)
}

let yearList = [];
yearData.forEach(element => {
  yearList.push(element.value);
});



const TimekeepingManagement = () => {
  const [open, setOpen] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [indexYear, setIndexYear] = useState(yearList.indexOf(getYear(new Date())));
  const [indexMonth, setIndexMonth] = useState(monthList.indexOf(getMonth(new Date())));
  const [listTimekeeping, setListTimekeeping] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("");
  const [countPage, setCountPage] = useState(0);
  const [month, setMonth] = useState(getMonth(new Date()));
  const [year, setYear] = useState(getYear(new Date()));
  const [openTimekeeping, setOpenTimekeeping] = useState(false);
  const [userId, setUserId] = useState();
  const [fullname, setFullname] = useState();
  const [defaultMonth, setDefaultMonth] = useState(moment(getMonthFromStringToday(new Date())).toDate());
  const [daysInMonth, setDaysInMonth] = useState(dayMonth(new Date().getFullYear(), new Date().getMonth() + 1));
  const [TABLE_HEAD, setTABLE_HEAD] = useState([]);
  const [selected, setSelected] = useState([]);



  const { exportToExcelPro } = ExcelService();

  function dayMonth(year, month) {
    var daysInMonth = new Date(year, month, 0).getDate();
    return daysInMonth;
  }


  function getMonthFromStringToday(dateString) {

    var date = new Date(dateString);
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let year = date.getFullYear();
    if (month > 12) {
      year = year + 1;
      month = '01';
    }
    var result = year + "-" + month;

    return result;
  }

  const handleCloseMenu = () => {
    setOpen(null);
  };


  const handleTimekeeping = (e, data) => {
    setUserId(data.user._id);
    setFullname(data.user.fullname)
    setOpenTimekeeping(true);
  };


  const handleChangePage = (event, newPage) => {
    console.log(newPage)
    setPage(newPage);
    getAll(year + "-" + month, newPage, limit, -1, "")
  };

  const handleChangeRowsPerPage = (event) => {
    getAll(year + "-" + month, 0, event.target.value, -1, "")
    setLimit(parseInt(event.target.value));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = ((orderBy === property || orderBy !== property) && order === "asc");
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    daysInMonthChange(daysInMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year])



  const daysInMonthChange = async () => {
    let arr = [];
    arr.push({ id: "fullname", idSort: 'fullname', label: "Họ tên", position: 'sticky', left: 0, zIndex: 3, bg: 'fullname', align: 'center', minWidth: 220, color: 'white', background: '#172b4d', fontSize: '13px' });
    arr.push({ id: "positionName", idSort: 'position.positionName', label: "Chức vụ", bg: 'positionName', align: 'center', minWidth: 140, color: 'white', background: '#172b4d', fontSize: '13px' })
    const weekdays = ["CN", "Th2", "Th3", "Th4", "Th5", "Th6", "Th7"];
    for (let day = 1; day <= new Date(year, month, 0).getDate(); day++) {
      const date = new Date(year, month - 1, day);
      let objDay = {};
      objDay.id = day;
      objDay.label = day + ' ' + weekdays[date.getDay()];
      objDay.bg = weekdays[date.getDay()];
      let width = 0;
      if (weekdays[date.getDay()] === 'CN') {
        objDay.background = '#b9f6ca'
        objDay.color = 'black';
        width = 53;
      }
      else {
        if (weekdays[date.getDay()] === 'Th7') {
          objDay.background = '#fff9c4';
          objDay.color = 'black';
          width = 53.1;
        } else {
          objDay.background = '#172b4d'
          objDay.color = '#ffff';
          width = 5;
        }
      }
      objDay.minWidth = width
      objDay.fontSize = '10px'
      objDay.align = 'center';
      arr.push(objDay)
    }
    arr.push(
      { id: "fullWorkDay", label: "Đủ ngày công", bg: 'fullWorkDay', align: 'center', minWidth: 95, color: '#ffff', background: '#172b4d', fontSize: '13px' },
      { id: "haftWorkDay", label: "Nửa ngày công", bg: 'haftWorkDay', align: 'center', minWidth: 100, color: '#ffff', background: '#172b4d', fontSize: '13px' },
      { id: "vacationDay", label: "Nghỉ phép", bg: 'vacationDay', align: 'center', minWidth: 90, color: '#ffff', background: '#172b4d', fontSize: '13px' },
      { id: "studyWorkDay", label: "Học tập, công tác", bg: 'studyWorkDay', align: 'center', minWidth: 120, color: '#ffff', background: '#172b4d', fontSize: '13px' },
      { id: "maternity", label: "Thai sản", bg: 'maternity', align: 'center', minWidth: 80, color: '#ffff', background: '#172b4d', fontSize: '13px' },
      { id: "remote", label: "Remote", bg: 'remote', align: 'center', minWidth: 60, color: '#ffff', background: '#172b4d', fontSize: '13px' },
      { id: "noSalary", label: "Không lương", bg: 'noSalary', align: 'center', minWidth: 50, color: '#ffff', background: '#172b4d', fontSize: '13px' },
      { id: "totalWork", label: "Tổng công", position: 'sticky', right: 0, zIndex: 3, bg: 'totalWork', align: 'center', minWidth: 105, color: 'black', background: '#fbe9e7', fontSize: '13px' },
    );
    setTABLE_HEAD(arr);
  }

  const getAll = async (month, page, limit, sortOrder, property) => {
    setShowLoading(() => true);
    const response = await Axios.TimekeepingManagement.getAll(month, page, limit, sortOrder, "", property);
    if (response.status === 200) {
      setShowLoading(() => false);
      setPage(page);
      setLimit(limit);

      let arr = [];
      response.data.listItem.forEach(element => {
        let obj = {};
        obj._id = element.user._id;
        obj.user = element.user;
        obj.fullname = element.user.fullname;
        obj.positionName = element.user.positionName;
        obj.fullWorkDay = element.fullWorkDay;
        obj.haftWorkDay = element.haftWorkDay;
        obj.vacationDay = element.vacationDay;
        obj.studyWorkDay = element.studyWorkDay;
        obj.maternity = element.maternity;
        obj.noSalary = element.noSalary;
        obj.remote = element.remote;
        obj.totalWork = element.totalWork;
        arr.push(obj)
        element?.listTimekeeping?.forEach(item => {
          let day = new Date(item.date).getDate();
          obj[day] = item.content;
        });
      });

      setListTimekeeping(arr);
      setCountPage(parseInt(response.data.totalItem));
    } else {
      setShowLoading(() => false);
      toast.error("Lấy danh sách chấm công thất bại!");
    }
  };


  function getYear(dateString) {
    var date = new Date(dateString);
    return date.getFullYear() + "";
  }
  useEffect(() => {
    getMonth(new Date())
  }, [])

  function getMonth(dateString) {
    var date = new Date(dateString);
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    return month + "";
  }


  const handleChangeMonth = (event, newValue) => {

    if (newValue === null) {
      setMonth(month);
      setDefaultMonth(moment(year + '-' + month).toDate());
    } else {
      setDaysInMonth(dayMonth(year, newValue.value));
      let checkMonth = newValue.value;
      setMonth(checkMonth);
      let data = year + '-' + checkMonth;
      setDefaultMonth(moment(data).toDate());
    }
  };

  const handleChangeYear = (event, newValue) => {
    if (newValue === null) {
      setDefaultMonth(moment(year + '-' + month).toDate());
      setYear(year);
    } else {
      setMonth(month);
      let data = newValue?.value + '-' + month;
      setDefaultMonth(moment(data).toDate());
      setYear(newValue?.value);
      setDaysInMonth(dayMonth(newValue.value, month));
    }
  };

  const handleSelectAllClick = (event) => {

    setSelected([]);
  };


  return (
    <>
      <Helmet>
        <title> Báo cáo chấm công</title>
      </Helmet>
      <Grid container sx={{width: '100%',}}>
        <Grid item xs={12}>
          <Grid container sx={{mt:11}}>
            <Grid item xs={0.3} sm={0} md={0.3}>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Autocomplete
                name="major"
                onChange={handleChangeMonth}
                options={monthData}
                getOptionLabel={(option) => option.title}
                defaultValue={monthData[indexMonth]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"bx:code-block"} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    variant="standard"
                    label="Chọn tháng"
                    placeholder="Chọn tháng"
                    sx={styleInputFullField}
                  />
                )}

              />
            </Grid>
            <Grid item xs={0.3} sm={0} md={0.3}>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Autocomplete
                name="year"
                options={yearData}
                onChange={handleChangeYear}
                getOptionLabel={(option) => option.title}
                defaultValue={yearData[indexYear]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"bx:code-block"} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    variant="standard"
                    label="Chọn năm"
                    placeholder="Chọn năm"
                    sx={styleInputFullField}

                  />
                )}


              />
            </Grid>
            <Grid item xs={0.3} sm={0} md={0.3}>

            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CustomButton onClick={(e) => exportToExcelPro(listTimekeeping,
                month,
                year,
                'CÔNG TY TNHH CÔNG NGHỆ SIGINX',
                TABLE_HEAD,
              )} variant="contained">Xuất Excel</CustomButton>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <TimekeepingListToolbar
                numSelected={selected.length}
                selected={selected}
                onChange={getAll}
                page={page}
                limit={limit}
                setListTimekeeping={setListTimekeeping}
                month={year + "-" + month}
                handleTimekeeping={handleTimekeeping}
              />
              <TableContainer className="table" sx={{ maxHeight: 560, cursor: 'pointer', ...scrollbar,width:'98.5%',margin:'auto'}}>
                <Table stickyHeader aria-label="sticky table" >
                  <TimekeepingManagementListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={listTimekeeping?.listItem?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    onChange={getAll}
                    page={page}
                    limit={limit}
                    month={year + "-" + month}
                  />
                  <TableBody >
                    {listTimekeeping?.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id} className="TableRow">
                          {TABLE_HEAD.map((column) => {
                            const value = row[column.id] ?? "";
                            return (
                              <TableCell key={column.id}
                                className={column.bg}
                                onClick={(event) => handleTimekeeping(event, row)}
                                sx={{
                                  textAlign: 'center',
                                  // color: row.color,
                                  color: 'black',
                                  minWidth: column.minWidth
                                }}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {listTimekeeping?.length !== 0 ?
                <TablePagination
                  sx={{ float: "left" }}
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={countPage}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Số hàng mỗi trang"
                />
                : <Box sx={{ marginTop: '40px', marginLeft: '20px', textAlign: 'center', fontSize: '20px', width: "98.5%", height: '100px' }}>Danh sách trống</Box>}


            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>

      <TimekeepingDetail
        open={openTimekeeping}
        setOpen={setOpenTimekeeping}
        userId={userId}
        fullname={fullname}
        month={month}
        year={year}
        defaultMonth={defaultMonth}
      />

    </>
  );
};


export default TimekeepingManagement;

// material-ui
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Iconify from "../../../components/iconify";
import { toast } from "react-toastify";


import { Helmet } from "react-helmet";

import { useState } from "react";
import BasicSpeedDial from "./components/BasicSpeedDial";
import { PositionListHead, PositionListToolbar } from "../sections/@dashboard/Position";
import { PositionDialog } from "./components/PositionDialog";
import Axios from "../../../utils/Axios";
import { PositionUpdateDialog } from "./components/PositionUpdateDialog";
import DateTimeOfMessage from "../../../utils/DateTimeOfMessage/DateTimeOfMessage";
import { styled } from "@mui/material/styles";


// ==============================|| POSITION ||============================== //
const CustomButton = styled
  (Button)(({ theme }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));


const TABLE_HEAD = [
  { id: "positionName", label: "Nhóm quyền", alignRight: false },
  { id: "positionNote", label: "Ghi chú", alignRight: false },
  { id: "createdAt", label: "Ngày tạo", alignRight: false },
  { id: "updatedAt", label: "Ngày cập nhật", alignRight: false },
  { id: "5", label: "", alignRight: false },
];


const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#dee2e3",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#dee2e3",
  },
};

const PositionManagement = (props) => {

  const [order, setOrder] = useState("desc");
  const [listRole, setListRole] = useState([]);
  const [orderBy, setOrderBy] = useState("");

  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState([]);
  const [listPosition, setListPosition] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedOne, setSelectedOne] = useState([]);

  const [isShowPosition, setIsShowPosition] = useState(false);
  const [isShowPositionUpdate, setIsShowPositionUpdate] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [positionUpdate, setPositionUpdate] = useState({});
  const [name, setName] = useState("");
  const [countPage, setCountPage] = useState(0);

  useEffect(() => {
    setListRole(props.listRole)
  }, [props])

  useEffect(() => {
    getAllPosition(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllPosition = async (page, limit, sortOrder, property) => {
    setShowLoading(() => true);
    const response = await Axios.Position.getAll(page,
      limit,
      sortOrder,
      "",
      property);
    if (response.status === 200) {
      setShowLoading(() => false);
      setSelected([]);
      setPage(page);
      setLimit(limit);
      setListPosition(response.data.listItem);
      setCountPage(parseInt(response.data.totalItem));
    } else {
      setShowLoading(() => false);
    }
  }

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event, value) => {
    setName(value.positionName);

    let arr = [];
    arr.push(value._id);
    setSelectedOne(arr);
    setPositionUpdate(value);
    setOpen(event.currentTarget);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllPosition(newPage, 5, -1, "");
  };

  const handleChangeRowsPerPage = (event) => {
    getAllPosition(0, event.target.value, -1, "");
    setLimit(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = ((orderBy === property || orderBy !== property) && order === "asc");
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listPosition?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleCreatePosition = () => {
    setIsShowPosition(true);
  };

  const handlePositionUpdate = (e, data) => {
    setPositionUpdate(data);
    setIsShowPositionUpdate(true);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deletePosition = async () => {
    setShowLoading(() => true);
    const response = await Axios.Position.delete({ listId: selectedOne });
    if (response.status === 200) {
      setShowLoading(() => false);
      getAllPosition(page, limit, order, orderBy);
      setOpenDialog(false);
      setOpen(null);
      toast.success("Xoá chức vụ thành công!");
    } else {
      if (response.status === 400 && response.code === 29) {
        setShowLoading(() => false);
        toast.error("Chức vụ đang có người dùng sử dụng!");
      } else {
        setShowLoading(() => false);
        toast.error("Xoá chức vụ thất bại!");
      }

    }
  };

  return (
    <>
      <Helmet>
        <title> Position Management </title>
      </Helmet>
      <Grid container sx={{ width: '100%', }}>
        <Grid item xs={12}>
          <Grid container >
            <Grid item xs={12} sm={6} md={12}>
              <PositionListToolbar
                numSelected={selected.length}
                selected={selected}
                onChange={getAllPosition}
                page={page}
                limit={limit}
                handlePositionUpdate={handlePositionUpdate}
                listRole={listRole}
                setListPosition={setListPosition}
              />
              <TableContainer
                sx={{
                  minWidth: 100,
                  maxHeight: 560,
                  ...scrollbar,
                  borderRadius: 2,
                  borderRight: 1,
                  borderLeft: 1,
                  borderBottom: 1,
                  borderColor: "#dee2e3",
                  margin: 'auto',
                  width: "98.5%"
                }}
              >
                <Table>
                  <PositionListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={listPosition?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    onChange={getAllPosition}
                    page={page}
                    limit={limit}
                    listRole={listRole}
                  />
                  <TableBody>
                    {listPosition?.map((row) => {
                      const { _id, positionName, positionNote, createdAt, updatedAt } =
                        row;
                      const selectedPosition = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedPosition}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            {listRole.includes('quan-ly-chuc-vu-xoa') ? <Checkbox
                              checked={selectedPosition}
                              onChange={(event) => handleClick(event, _id)}
                            /> : ""}

                          </TableCell>
                          <TableCell
                            onClick={(event) => handlePositionUpdate(event, row)}
                            style={{ width: 250, whiteSpace: "pre-line", fontWeight: 700, }}
                            align="center"
                          >
                            {positionName}
                          </TableCell>

                          <TableCell
                            onClick={(event) => handlePositionUpdate(event, row)}
                            align="center"
                            style={{ width: 250, whiteSpace: "pre-line" }}
                          >
                            {positionNote}
                          </TableCell>

                          <TableCell
                            onClick={(event) => handlePositionUpdate(event, row)}
                            style={{ width: 300, whiteSpace: "pre-line" }}
                            align="center"
                          >
                            <DateTimeOfMessage dateTime={createdAt} />
                          </TableCell>

                          <TableCell
                            onClick={(event) => handlePositionUpdate(event, row)}
                            style={{ width: 300, whiteSpace: "pre-line" }}
                            align="center"
                          >
                            <DateTimeOfMessage dateTime={updatedAt} />
                          </TableCell>

                          <TableCell align="right" sx={{ width: "5%" }}>
                            {!listRole.includes('quan-ly-chuc-vu-cap-nhat') && !listRole.includes('quan-ly-chuc-vu-xoa') ? "" :
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) => handleOpenMenu(e, row)}
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            }

                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {listPosition?.listItem?.length !== 0 ?
                <TablePagination
                  sx={{ float: "left" }}
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={countPage}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Số hàng mỗi trang"
                />
                : <Box sx={{ marginTop: '40px', marginLeft: '20px', textAlign: 'center', fontSize: '20px', width: "98.5%", height: '100px', color: 'black' }}>Danh sách trống</Box>}
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 140,
                    "& .MuiMenuItem-root": {
                      px: 1,
                      typography: "body2",
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                {listRole.includes('quan-ly-chuc-vu-cap-nhat') ?
                  <MenuItem
                    onClick={(event) => handlePositionUpdate(event, positionUpdate) & handleCloseMenu()}
                  >
                    <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                    Chỉnh sửa
                  </MenuItem> : ""}

                {listRole.includes('quan-ly-chuc-vu-xoa') ?
                  <MenuItem onClick={handleClickOpen} >
                    <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                    Xoá
                  </MenuItem> : ""}


              </Popover>
              {listRole.includes('quan-ly-chuc-vu-them-moi') ? <BasicSpeedDial handleCreatePosition={handleCreatePosition} /> : ""}

            </Grid>

          </Grid>
        </Grid>
      </Grid>
      {isShowPosition &&
        <PositionDialog
          open={isShowPosition}
          setOpen={setIsShowPosition}
          onChange={getAllPosition}
        />
      }

        <PositionUpdateDialog
          open={isShowPositionUpdate}
          setOpen={setIsShowPositionUpdate}
          positionUpdate={positionUpdate}
          onChange={getAllPosition}
          listRole={listRole}
        />

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận xoá?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                sx={{ fontSize: 20, fontFamily: "bold" }}
              >
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {/* {numSelected} */}
                </span>{" "}
                ( {name} )
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={deletePosition}>Xác nhận</CustomButton>
            <CustomButton onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
              Huỷ
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

export default PositionManagement;

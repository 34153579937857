import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  DialogContentText,
  Divider,
  Grid,
} from "@mui/material";

export const DialogLeaveDay = ({ open, setOpen, listLeaveDay }) => {

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="800">
        <DialogTitle sx={{ textAlign: 'center', fontSize: 23, }}>Chi tiết ngày nghỉ các năm</DialogTitle>
        <Divider />
        <DialogContent sx={{ maxWidth: "800px", }}>
          <DialogContentText />
          <Box
            sx={{
              width: "100%",
            }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            <div
              style={{
                marginTop: '-25px',
                maxHeight: "444px",
                overflowY: "auto",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container >
                    {listLeaveDay?.map((item, index) => (
                      <Grid item xs={12} sm={6} md={listLeaveDay?.length > 1 ? 6:12} key={index} sx={{ textAlign: 'center', mb: 3, fontSize: '20px', paddingLeft: 5, paddingRight: 5 }}>
                        <Box sx={{ fontWeight: 900, mt: 0.5, fontFamily: "-moz-initial", }}>Năm:  <span >{item.year}</span></Box>
                        <Box sx={{ fontSize: 17, mb: 0.5, fontWeight: 900, mt: 0.5, fontFamily: "-moz-initial", }} > Nghỉ phép hàng năm: ( <span >{item.annualLeaveDay}</span> )</Box>
                        <Box sx={{ fontSize: 18, fontWeight: 900, mt: 0.5, fontFamily: "-moz-initial", }} > Số ngày đã nghỉ phép: ( <span >{item.numberLeaveDay}</span> )</Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>

          </Box>
        </DialogContent>

      </Dialog>

    </>
  );
};

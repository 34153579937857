// @mui
import {
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Popover,
  MenuItem,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Backdrop,
  CircularProgress,
  Grid,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../../components/iconify";
import BasicSpeedDial from "./components/BasicSpeedDial";
import { DialogCreateWorkProject } from "./components/DialogCreateWorkProject";
import { Helmet } from "react-helmet";
import { makeStyles } from "@mui/styles";
import Axios from "../../../utils/Axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { DialogMember } from "./components/DialogMember";
import { TaskWorkListHead, TaskWorkListToolbar } from "../sections/@dashboard/taskWork";
import DateTimeOfMessage from "../../../utils/DateTimeOfMessage/DateTimeOfMessage";
import { DialogEditWorkProject } from "./components/DialogEditWorkProject";
import { useSelector } from "react-redux";


const CustomButton = styled
  // eslint-disable-next-line no-empty-pattern
  (Button)(({ }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));

const TABLE_HEAD = [
  { id: "name", label: "Dự án", alignRight: false },
  { id: "description", label: "Nội dung", alignRight: false },
  { id: "createdAt", label: "Ngày tạo", alignRight: false },
  { id: "updatedAt", label: "Ngày cập nhật", alignRight: false },
  { id: "199", label: "", alignRight: true },
];

const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#dee2e3",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#dee2e3",
  },
};

// ----------------------------------------------------------------------
const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});


const TaskManagement = (props) => {

  // eslint-disable-next-line no-unused-vars
  const [listRole, setListRole] = useState(props.listRole);
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [selected, setSelected] = useState([]);

  const [selectedOne, setSelectedOne] = useState([]);

  const [isCreate, setIsCreate] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isMember, setIsMember] = useState(false);

  const [listWork, setListWork] = useState([]);

  const [countPage, setCountPage] = useState(0);

  const [project, setProject] = useState({});

  const [order, setOrder] = useState("desc");

  const [orderBy, setOrderBy] = useState("");

  const [openDialog, setOpenDialog] = React.useState(false);

  const [nameWork, setNameWork] = useState("");
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleOpenMenu = (event, value) => {
    setNameWork(value.projectName);
    let arr = [];
    setOpen(event.currentTarget);
    arr.push(value._id);
    setSelectedOne(arr);
    setProject(value);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listWork?.listItem?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    if (listRole.includes('quan-ly-tien-do-xoa') === true) {
      const selectedIndex = selected.indexOf(_id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, _id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllWorkProject(newPage, 5, -1, "");
  };

  const handleChangeRowsPerPage = (event) => {
    getAllWorkProject(0, event.target.value, -1, "");
    setLimit(parseInt(event.target.value, 10));
  };

  const handleCreateWork = () => {
    setIsCreate(true);
  };

  const getAllWorkProject = async (page, limit, sortOrder, property) => {
    setShowLoading(() => true);
    const response = await Axios.WorkProject.getAllWorkProject(
      page,
      limit,
      sortOrder,
      "",
      property
    );
    if (response.status === 200) {
      setShowLoading(() => false);
      setSelected([]);
      setPage(page);
      setLimit(limit);
      setListWork(response.data);
      setCountPage(parseInt(response.data.totalItem));
    } else {
      setShowLoading(() => false);
      toast.error("Lấy danh sách dự án thất bại!");
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = ((orderBy === property || orderBy !== property) && order === "asc");
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const deleteWorkProject = async () => {
    setShowLoading(() => true);
    const response = await Axios.WorkProject.deleteWorkProject({ listId: selectedOne });
    if (response.status === 200) {
      setShowLoading(() => false);
      getAllWorkProject(page, limit, order, orderBy);
      setOpenDialog(false);
      setOpen(null);
      toast.success("Xoá dự án thành công!");
    } else {
      setShowLoading(() => false);
      toast.error("Xoá dự án thất bại!");
    }
  };

  const handleDetail = (e,_id) => {
    navigate(`/getListUserInProject/${_id}`);
  };

  return (
    <>
      <Helmet>
        <title> Quản lý tiến độ </title>
      </Helmet>
      <Grid container sx={{ width: '100%', }}>
        <Grid item xs={12}>
          <Grid container >
            <Grid item xs={12} sm={6} md={12}>
              <TaskWorkListToolbar
                numSelected={selected.length}
                selected={selected}
                onChange={getAllWorkProject}
                page={page}
                limit={limit}
                setListWork={setListWork}

              />
              <TableContainer
                sx={{
                  minWidth: 10,
                  maxHeight: 560,
                  ...scrollbar,
                  borderRadius: 2,
                  borderRight: 1,
                  borderLeft: 1,
                  borderBottom: 1,
                  borderColor: "#dee2e3",
                  margin: 'auto',
                  width: "98.5%"
                }}
              >
                <Table className={classes.table}>
                  <TaskWorkListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={listWork?.listItem?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    onChange={getAllWorkProject}
                    page={page}
                    limit={limit}
                    listRole={listRole}
                  />
                  <TableBody>
                    {listWork?.listItem?.map((row) => {
                      const {
                        _id,
                        name,
                        description,
                        createdAt,
                        updatedAt,
                      } = row;
                      const selectedWork = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedWork}
                          sx={{ cursor: "pointer", }}
                        >
                          <TableCell padding="checkbox">
                            {listRole.includes('quan-ly-tien-do-xoa') ? <Checkbox
                              checked={selectedWork}
                              onChange={(event) => handleClick(event, _id)}
                            /> : ""}

                          </TableCell>

                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            padding="none"
                            style={{ width: 230 }}
                            onClick={(event) => handleDetail(event, _id)}
                          >
                            <Typography
                              sx={{ marginLeft: 3, whiteSpace: "pre-line" }}

                            >
                              {name}
                            </Typography>
                          </TableCell>

                          <TableCell
                            onClick={(event) => handleDetail(event, _id)}
                            style={{ width: 270, whiteSpace: "pre-line" }}
                            align="center"
                          >
                            {description}
                          </TableCell>

                          <TableCell
                            onClick={(event) => handleDetail(event, _id)}
                            align="center"
                            style={{ width: 230, whiteSpace: "pre-line" }}
                          >
                            <DateTimeOfMessage dateTime={createdAt} />
                          </TableCell>

                          <TableCell
                            onClick={(event) => handleDetail(event, _id)}
                            style={{ width: 230, whiteSpace: "pre-line" }}
                            align="center"
                          >
                            <DateTimeOfMessage dateTime={updatedAt} />

                          </TableCell>
                          <TableCell align="right" sx={{ width: "5%" }}>
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => handleOpenMenu(e, row)}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              {listWork?.listItem?.length !== 0 ?
                <TablePagination
                  sx={{ float: "left" }}
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={countPage}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Số hàng mỗi trang"
                />
                : <Box sx={{ marginTop: '40px', marginLeft: '20px', textAlign: 'center', fontSize: '20px', width: "98.5%", height: '100px' }}>Danh sách trống</Box>}

              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 180,
                    "& .MuiMenuItem-root": {
                      px: 1,
                      typography: "body2",
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <Link
                  to={`/getListUserInProject/${project._id}`}
                  style={{ textDecoration: "none" }}
                  className={customization.mode}
                >
                  <MenuItem >
                    <Iconify icon={"clarity:details-line"} sx={{ mr: 2 }} />
                    Chi tiết
                  </MenuItem>
                </Link>

                {listRole.includes('quan-ly-tien-do-cap-nhat') ?
                  <MenuItem
                    onClick={() => setIsEdit(true) & handleCloseMenu()}
                  >
                    <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                    Chỉnh sửa
                  </MenuItem> : ""}

                <MenuItem
                  onClick={() => setIsMember(true) & handleCloseMenu()}
                >
                  <Iconify icon={"ci:user-add"} sx={{ mr: 2 }} />
                  Thêm thành viên
                </MenuItem>

                {listRole.includes('quan-ly-tien-do-xoa') ?
                  <MenuItem onClick={handleClickOpen} >
                    <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                    Xoá
                  </MenuItem> : ""}

              </Popover>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      {isCreate &&
        <DialogCreateWorkProject
          onChange={getAllWorkProject}
          open={isCreate}
          setOpen={setIsCreate}
          page={page}
          limit={limit}
        />
      }
      {isMember &&
        <DialogMember
          open={isMember}
          setOpen={setIsMember}
          project={project}

        />
      }
      {isEdit &&
        <DialogEditWorkProject
          key={1}
          open={isEdit}
          setOpen={setIsEdit}
          project={project}
          getAllWorkProject={getAllWorkProject}
        />
      }
      {listRole.includes('quan-ly-tien-do-them-moi') ? <BasicSpeedDial handleCreateWork={handleCreateWork} /> : ""}

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận xoá?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                sx={{ fontSize: 20, fontFamily: "bold", }}
              >
                <span style={{ fontWeight: "bold" }}>
                </span>{" "}
                {nameWork}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={deleteWorkProject}>Xác nhận</CustomButton>
            <CustomButton onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
              Huỷ
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};
export default TaskManagement;

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import React, { useEffect, useState } from "react";
import useValidator from "../../../../utils/Validator";
import { toast } from "react-toastify";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const styleInputFullField = {
  width: "100%",
  mt: 2,
};

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));


const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      {children}
    </Grid>
  );
};

export const DialogCreateHoliday = ({ open, setOpen, onChange, page, limit, listRole }) => {
  const { validateHoliday } = useValidator();
  console.log('create')
  const [holidayCreate, setHolidayCreate] = useState({
    name: "",
    date: "",
    note: "",
  });
  const [showLoading, setShowLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [openMenu, setOpenMenu] = useState(false);

  const [inputValues, setInputValues] = useState({
    name: "",
    date: "",
    note: ""
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setErrors({});
    setOpenMenu(false);
  }, [open])

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateHoliday(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      create();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const create = async () => {
    setShowLoading(() => true);
    let data = {};
    if (holidayCreate.note.includes('holiday-')) {
      // eslint-disable-next-line no-self-assign
      holidayCreate.note = holidayCreate.note;
    } else {
      holidayCreate.note = "holiday-" + holidayCreate.note;
    }
    if (openMenu === true) {
      data.fromDate = holidayCreate.date;
      data.toDate = holidayCreate.toDate;
    } else {
      data.date = holidayCreate.date;
    }
    data.name = holidayCreate.name;
    data.note = holidayCreate.note;

    // console.log(data)

    const response = await Axios.Holiday.create(data);
    if (response.status === 200) {
      setShowLoading(() => false);
      toast.success("Thêm ngày lễ thành công");
      setOpen(false);
      onChange(page, limit, -1, "");
    } else {
      if (response.data.code === 14 && response.data.status === 400) {
        setErrors({
          date: "Ngày lễ đã được tạo!",
        })
        toast.error("Ngày lễ đã được tạo!");
      } else {
        toast.error("Thêm ngày lễ thất bại!");
        setShowLoading(() => false);
      }
      setShowLoading(() => false);

    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateHoliday(name, value) });
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };



  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle
            sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800 }}
          >
            Tạo Ngày Lễ
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <LayoutFormTwoField>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Ngày lễ"
                    variant="standard"
                    placeholder="Ngày lễ?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"carbon:transform-instructions"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setHolidayCreate({ ...holidayCreate, name: e.target.value });
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "100%" })
                    }
                    error={errors.name ? true : false}
                    helperText={
                      errors.name ? (
                        errors.name
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
&nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>

              </LayoutFormTwoField>
              <Grid item xs={6}>
              <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Chọn ngày</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="one"

                  >
                    <FormControlLabel value="one" control={<Radio />} label="Một ngày" onClick={handleCloseMenu} />
                    <FormControlLabel value="two" control={<Radio />} label="Nhiều ngày" onClick={handleOpenMenu} />
                  </RadioGroup>
                </FormControl>

              </Grid>
              <LayoutFormTwoField>
                <Grid item xs={6}>
                  <TextField
                    name="date"
                    label="Ngày bắt đầu"
                    variant="standard"
                    type="date"
                    placeholder="Nhập ngày bắt đầu"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"uiw:date"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setHolidayCreate({ ...holidayCreate, date: e.target.value });
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    error={errors.date ? true : false}
                    helperText={
                      errors.date ? (
                        errors.date
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
&nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  {openMenu === true ? <TextField
                    name="dateEnd"
                    label="Ngày kết thúc"
                    variant="standard"
                    type="date"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"uiw:date"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setHolidayCreate({ ...holidayCreate, toDate: e.target.value });
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    error={errors.dateEnd ? true : false}
                    helperText={
                      errors.dateEnd ? (
                        errors.dateEnd
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
&nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  /> : ""}

                </Grid>
              </LayoutFormTwoField>

              <TextField
                name="note"
                component="div"
                label="Ghi chú"
                multiline
                maxRows={5}
                onChange={(e) => {
                  setHolidayCreate({ ...holidayCreate, note: e.target.value });
                }}
                variant="standard"
                placeholder="Ghi chú?"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon={"material-symbols:edit-note-sharp"}
                      />
                    </InputAdornment>
                  ),
                }}
                autoComplete="none"
                error={errors.note ? true : false}
                helperText={
                  errors.note ? (
                    errors.note
                  ) : (
                    <Box component="span" sx={{ color: "white" }}>
                      &emsp;
                    </Box>
                  )
                }
                onInput={handleOnInput}
                sx={
                  (styleInputFullField,
                    { ...styleInputFullField, ml: 2, width: "100%" })
                }
              />

            </Grid>
          </DialogContent>

          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton type="submit" variant="contained">Thêm mới</CustomButton>
            <CustomButton onClick={handleClose} sx={{ background: "#f72533" }} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

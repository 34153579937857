import {  Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

export default function PositionSearchBox({ searchData,handlePositionUpdate,setOpen }) {

  const { positionName, positionNote } = searchData;
  const customization = useSelector((state) => state.customization);

  return (
    <>
    <Box className={customization.mode}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
          onClick={(event) => handlePositionUpdate(event, searchData) & setOpen(false)}
          className="searchBox"
        >
          <Box display={"flex"} maxWidth="70%">
            <Box sx={{ maxWidth: "99%", ml: 2 }}>
              <Typography
               sx={{ fontWeight:600 }}
                variant="subtitle2"
                noWrap
                fontSize={16}
              >
                {positionName}
              </Typography>

              <Typography variant="body3" noWrap>
              <Box >
                  {positionNote}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
        </Box>
    </>
  );
}

/* eslint-disable no-unused-vars */
import React from "react";
import Axios from "../../../utils/Axios";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Backdrop, Box, Checkbox, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from "@mui/material";
import LaveDayManagementListToolbar from "../sections/@dashboard/LeaveDayManagement/LaveDayManagementListToolbar";

import { makeStyles } from "@mui/styles";
import LeaveDayManagementListHead from "../sections/@dashboard/LeaveDayManagement/LeaveDayManagementListHead";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// ==============================||  ||============================== //

const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#dee2e3",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#dee2e3",
  },
};
const TABLE_HEAD = [
  { id: "fullname", label: "Họ tên", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "position.positionName", label: "Chức vụ", alignRight: false },
];

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});
const LeaveManagement = (props) => {

  const [showLoading, setShowLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [listRole, setListRole] = useState(props.listRole);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const classes = useStyles();

  const [orderBy, setOrderBy] = useState("");
  const [listUser, setListUser] = useState([]);

  const [countPage, setCountPage] = useState(0);
  const navigate = useNavigate();

  const getAll = async (page, limit, sortOrder, property) => {
    setShowLoading(() => true);
    const response = await Axios.LeaveDay.getAllUserWithTimekeepingPermission(
      page,
      limit,
      sortOrder,
      "",
      property
    );
    if (response.status === 200) {
      setShowLoading(() => false);
      setSelected([]);
      setPage(page);
      setLimit(limit);
      setListUser(response.data);
      setCountPage(parseInt(response.data.totalItem));
    } else {
      setShowLoading(() => false);
      toast.error("Lấy danh sách ngày phép thất bại!");
    }
  };

  const handleClick = (event, _id) => {
    navigate(`/LeaveDetailPage/${_id}`);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = ((orderBy === property || orderBy !== property) && order === "asc");
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAll(newPage, 5, -1, "");
  };

  const handleChangeRowsPerPage = (event) => {
    getAll(0, event.target.value, -1, "");
    setLimit(parseInt(event.target.value, 10));
  };

  return (
    <>
      <Helmet>
        <title> Quản lý ngày phép </title>
      </Helmet>
      <Grid container sx={{width: '100%',}}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={6} md={12}>
              <LaveDayManagementListToolbar
                numSelected={selected.length}
                selected={selected}
                onChange={getAll}
                page={page}
                limit={limit}
                setListUser={setListUser}
              />

                <TableContainer
                  sx={{
                    minWidth: 10,
                    maxHeight: 560,
                    ...scrollbar,
                    borderRadius: 2,
                    borderRight: 1,
                    borderLeft: 1,
                    borderBottom: 1,
                    borderColor: "#dee2e3",
                    margin: 'auto',
                    width: "98.5%"
                  }}
                >
                  <Table className={classes.table}>
                    <LeaveDayManagementListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={listUser?.listItem?.length}
                      // numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      // onSelectAllClick={handleSelectAllClick}
                      onChange={getAll}
                      page={page}
                      limit={limit}
                      listRole={listRole}
                    />
                    <TableBody>
                      {listUser?.listItem?.map((row) => {
                        const {
                          _id,
                          fullname,
                          email,
                          position
                        } = row;
                        const selectedWork = selected.indexOf(_id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedWork}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox sx={{ visibility: "hidden" }} />
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                              style={{ width: 170, }}
                              onClick={(event) => handleClick(event, _id)}
                            >
                              <Typography
                                sx={{ marginLeft: 3, whiteSpace: "pre-line", fontWeight: 700 }}
                              >
                                {fullname}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                              padding="none"
                              style={{ width: 170 }}
                              onClick={(event) => handleClick(event, _id)}
                            >
                              <Typography
                                sx={{ marginLeft: 3, whiteSpace: "pre-line" }}
                              >
                                {email}
                              </Typography>
                            </TableCell>

                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                              padding="none"
                              style={{ width: 170 }}
                              onClick={(event) => handleClick(event, _id)}
                            >
                              <Typography
                                sx={{ marginLeft: 3, whiteSpace: "pre-line" }}
                              >
                                {position.positionName}
                              </Typography>
                            </TableCell>

                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {listUser?.listItem?.length !== 0 ?
                  <TablePagination
                    sx={{ float: "left" }}
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={countPage}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Số hàng mỗi trang"
                  />
                  : <Box sx={{ marginTop: '40px', marginLeft: '20px', textAlign: 'center', fontSize: '20px', width: "98.5%", height: '100px', color: 'black' }}>Danh sách trống</Box>}

            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>

    </>
  );
};

export default LeaveManagement;

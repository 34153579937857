// project imports
import config from './../../config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    // opened: false,
    opened: localStorage.getItem("opened") === "false" ? false : true,
    mode: localStorage.getItem("theme") ? localStorage.getItem("theme") : "light",
};

export const userLoginDetail = {
};
export const approve = 0;


// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case 'setTheme':
            return {
                ...state,
                mode: action.payload
            }
        default:
            return state;
    }
};

const customizationReducerListRole = (state = userLoginDetail, action) => {
    let data = action.payload;
    switch (action.type) {
        case 'getListRole':
            return {
                ...state,
                data
            }
        default:
            return state;
    }
};

const customizationReducerApprove = (state = approve, action) => {
    let data = action.payload
    switch (action.type) {
        case 'approve':
            return {
                ...state,
                data
            }
        default:
            return state;
    }
};



export { customizationReducer, customizationReducerListRole, customizationReducerApprove };

// material-ui
import { Grid, Backdrop, CircularProgress } from "@mui/material";
// sections
import AppWidgetSummary from "../sections/@dashboard/app/AppWidgetSummary";
import AppNewsUpdate from "../sections/@dashboard/app/AppNewsUpdate";

import Axios from "./../../../utils/Axios";
import { toast } from "react-toastify";

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
// ==============================|| DEFAULT DASHBOARD ||==yarn============================ //


const Dashboard = ({listRole}) => {

  const [totalProject, setTotalProject] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalProcessingProject, setTotalProcessingProject] = useState(0);
  const [totalSignedContractProject, setTotalSignedContractProject] =
    useState(0);

  const [listProject, setListProject] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    getAllStatistical();
  }, []);

  useEffect(() => {
    if (listRole.includes('quan-ly-du-an-xem') === true) {
      getAllWork();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRole.length !== 0]);

  const getAllWork = async () => {
    setShowLoading(() => true);
    const response = await Axios.Project.getAll(0, 3, -1, "", "");
    if (response.status === 200) {
      setShowLoading(() => false);
      setListProject(response.data.listItem);
    } else {
      setShowLoading(() => false);
      toast.error("Lấy danh sách dự án thất bại!");
    }
  };

  const getAllStatistical = async () => {
    setShowLoading(() => true);
    const response = await Axios.Statistical.getAll();
    if (response.status === 200) {
      setShowLoading(() => false);
      setTotalUser(response.data.totalUser);
      setTotalProject(response.data.totalProject);
      setTotalProcessingProject(response.data.processingProject);
      setTotalSignedContractProject(response.data.signedContractProject);
    } else {
      setShowLoading(() => false);
      toast.error("Lấy danh sách thống kê thất bại!");
    }
  };

  return (
    <>
      <Helmet>
        <title> Trang chủ | Dashboard </title>
      </Helmet>
      <Grid container sx={{width:'100%'}}>
        <Grid item xs={12} sx={{mt:12}}>
          <Grid container>
            {listRole.includes('quan-ly-nguoi-dung-xem') ? <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Người dùng"
                total={totalUser}
                icon={"mdi:user-group-outline"}
                sx={{ backgroundImage: "linear-gradient(to top, #c3faf8 20%, #e0f8f7 80%)" }}
              />
            </Grid> : ""}

            {listRole.includes('quan-ly-du-an-xem') ? <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Dự án"
                total={totalProject}
                color="success"
                icon={"la:project-diagram"}
                sx={{ backgroundImage: "linear-gradient(to top, #b5f79e 20%,#ddf7d5 80%)" }}
              />
            </Grid> : ""}
            {listRole.includes('quan-ly-du-an-xem') ? <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                color="error"
                title="Đang xử lý"
                total={totalProcessingProject}
                icon={"fluent-mdl2:processing-run"}
                sx={{ backgroundImage: "linear-gradient(to top, #f7d2d2 20%, #fbefef 80%)" }}
              />
            </Grid> : ""}
            {listRole.includes('quan-ly-du-an-xem') ? <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Đã ký hợp đồng"
                total={totalSignedContractProject}
                color="warning"
                icon={"material-symbols:error-med-outline"}
                sx={{ backgroundImage: "linear-gradient(to top, #f5f7a6 20%, #f7f8e0 80%)" }}

              />
            </Grid> : ""}


            {listProject.length !== 0 ? <Grid item sx={{ minWidth: 380, width: "95%",margin:'auto',mt:3 }}>
              {listRole.includes('quan-ly-du-an-xem') ? <AppNewsUpdate
                title="Công việc mới cập nhật"
                list={listProject}
              /> : ""}

            </Grid> : ""}

          </Grid>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>

    </>
  );
};

export default Dashboard;

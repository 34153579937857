
// @mui
import { styled } from "@mui/material/styles";
import { Container, Box } from "@mui/material";
import logo from "../../home.webp";
// ----------------------------------------------------------------------
import { Helmet } from "react-helmet";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Home() {
  return (
    <>
      <Helmet>
        <title> Trang chủ </title>
      </Helmet>
      <Container>
        <StyledContent>
          <Box
            component="img"
            src={logo}
            sx={{ width: "100%", height: '100%', mx: "auto", my: { xs: 5, sm: 10 } }}
          />
        </StyledContent>
      </Container>
    </>
  );
}

/* eslint-disable no-self-assign */
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Iconify from "../../../../components/iconify";

import useValidator from "../../../../utils/Validator";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";

// import useValidator from "../../../utils/Validator";
const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));

const styleInputFullField = {
  width: "100%",
  mb: 3,
};

export const DialogEditSaleProject = ({
  open,
  setOpen,
  project,
  getAll,
  getOneProject,
}) => {

  const { validateSaleProject } = useValidator();
  let data;
  if (project?.lastContactDate === null) {
    project.lastContactDate = null;
  } else {
    let date = new Date(project.lastContactDate);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    } else {
      day = day;
    }
    if (month < 10) {
      month = "0" + month;
    } else {
      month = month;
    }
    data = year + '-' + month + '-' + day;

    project.lastContactDate = data;
  }
  const [projectEdit, setProjectEdit] = useState(project);

  const [isMultilineCompanyName, setIsMultilineCompanyName] = useState(false);
  const [isMultilinAaddress, setIsMultilineAddress] = useState(false);
  const [isMultilineContactPerson, setIsMultilineContactPerson] =
    useState(false);
  const [isMultilinePosition, setIsMultilinePosition] = useState(false);
  const [isMultilineNote, setIsMultilineNote] =
    useState(false);
  const [isMultilineSource, setIsMultilineSource] = useState(false);
  const [isMultilineLastMarketInformation, setIsMultilineLastMarketInformation] =
    useState(false);

  const [isMultilineMarketInformation, setIsMultilineMarketInformation] = useState(false);


  const [showLoading, setShowLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };


  const update = async () => {
    setShowLoading(() => true);
    const response = await Axios.SaleProject.update(project._id, projectEdit);
    if (response.status === 200) {
      setShowLoading(() => false);
      handleCloseDialog();
      toast.success("Cập nhật dự án thành công");
      setOpen(false);
      if (getOneProject === undefined) {
        getAll(0, 10, -1, "");
      } else {
        getOneProject(project._id);
      }
    } else {
      handleCloseDialog();
      setErrors({
        NameOfProject: "Tên dự án đã tồn tại!",
      });
      toast.error("Cập nhật dự án thất bại!");
      setShowLoading(() => false);
    }
  };

  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateSaleProject(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      handleClickOpen();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateSaleProject(name, value) });
  };
  const handleReset = () => {
    setProjectEdit({
      companyName: "",
      address: "",
      contactPerson: "",
      phoneNumber: "",
      email: "",
      position: "",
      marketInformation: "",
      note: "",
      source: "",
      lastMarketInformation: "",
      lastContactDate: null,
    });
    setErrors({});
  };


  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle
            sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800, }}
          >
            Cập Nhật Báo Cáo Thị Trường
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <Box sx={{ mt: 1, display: "flex", width: "100%" }}>
                <Box sx={{ width: "49%", ml: 1 }}>
                  {/* isMultilineCompanyName */}
                  {isMultilineCompanyName === false ? (
                    <TextField
                      component="div"
                      name="companyName"
                      label="Tên Công Ty"
                      onFocus={() => setIsMultilineCompanyName(true)}
                      value={projectEdit?.companyName}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          companyName: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tên Công Ty?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.companyName ? true : false}
                      helperText={
                        errors.companyName ? (
                          errors.companyName
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  ) : (
                    <TextField
                      component="div"
                      name="companyName"
                      label="Tên Công Ty?"
                      onBlur={() => setIsMultilineCompanyName(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.companyName}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          companyName: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tên Công Ty?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.companyName ? true : false}
                      helperText={
                        errors.companyName ? (
                          errors.companyName
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                      autoFocus
                    />
                  )}
                  {/* isMultilinAaddress */}
                  {isMultilinAaddress === false ? (
                    <TextField
                      component="div"
                      // name="intermediary"
                      label="Địa chỉ"
                      onFocus={() => setIsMultilineAddress(true)}
                      value={projectEdit?.address}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          address: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Địa chỉ?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  ) : (
                    <TextField
                      component="div"
                      label="Địa chỉ"
                      onBlur={() => setIsMultilineAddress(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.address}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          address: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Địa chỉ?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  )}

                  {/* isMultilineContactPerson */}
                  {isMultilineContactPerson === false ? (
                    <TextField
                      component="div"
                      label="Người liên hệ"
                      onFocus={() => setIsMultilineContactPerson(true)}
                      value={projectEdit?.contactPerson}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          contactPerson: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Người liên hệ?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      label="Người liên hệ"
                      onBlur={() => setIsMultilineContactPerson(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.contactPerson}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          contactPerson: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Người liên hệ?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}

                  <TextField
                    component="div"
                    label="Số điện thoại"
                    value={projectEdit?.phoneNumber}
                    onChange={(e) =>
                      setProjectEdit({
                        ...projectEdit,
                        phoneNumber: e.target.value,
                      })
                    }
                    type="number"
                    variant="standard"
                    placeholder="Số điện thoại?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                  />

                  <TextField
                    component="div"
                    // name="issue"
                    label="Email"
                    value={projectEdit?.email}
                    onChange={(e) =>
                      setProjectEdit({
                        ...projectEdit,
                        email: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Email?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                  />
                </Box>

                <Box sx={{ width: "49%" }}>
                  {/* isMultilinePosition */}
                  {isMultilinePosition === false ? (
                    <TextField
                      onFocus={() => setIsMultilinePosition(true)}
                      component="div"
                      // name="investor"
                      label="Chức vụ"
                      value={projectEdit?.position}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          position: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Chức vụ?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="investor"
                      label="Chức vụ"
                      onBlur={() => setIsMultilinePosition(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.position}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          position: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Chức vụ?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  )}
                  {/* isMultilineNote */}
                  {isMultilineNote === false ? (
                    <TextField
                      component="div"
                      label="Ghi chú"
                      onFocus={() => setIsMultilineNote(true)}
                      value={projectEdit?.note}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          note: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Ghi chú?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      label="Ghi chú"
                      onBlur={() => setIsMultilineNote(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.note}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          note: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Ghi chú?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}

                  {/* isMultilineSource */}
                  {isMultilineSource === false ? (
                    <TextField
                      component="div"
                      // name="solution"
                      label="Nguồn khách"
                      onFocus={() => setIsMultilineSource(true)}
                      value={projectEdit?.source}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          source: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Nguồn khách?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="solution"
                      label="Nguồn khách"
                      onBlur={() => setIsMultilineSource(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.source}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          source: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Nguồn khách?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}

                  {/* isMultilineLastMarketInformation */}
                  {isMultilineLastMarketInformation === false ? (
                    <TextField
                      component="div"
                      // name="projectStatus"
                      label="Thông tin thị trường gần nhất"
                      onFocus={() => setIsMultilineLastMarketInformation(true)}
                      value={projectEdit?.lastMarketInformation}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          lastMarketInformation: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Thông tin thị trường gần nhất?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      label="Thông tin thị trường gần nhất"
                      onBlur={() => setIsMultilineLastMarketInformation(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      // autoFocus
                      value={projectEdit?.lastMarketInformation}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          lastMarketInformation: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Thông tin thị trường gần nhất?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.no ? true : false}
                      helperText={
                        errors.no ? (
                          errors.no
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}

                  <TextField
                    label="Ngày liên hệ gần nhất"
                    variant="standard"
                    type="date"
                    value={projectEdit?.lastContactDate ? projectEdit?.lastContactDate : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"uiw:date"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setProjectEdit({ ...projectEdit, lastContactDate: e.target.value });
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    onInput={handleOnInput}
                    error={errors.no ? true : false}
                    helperText={
                      errors.no ? (
                        errors.no
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                  />

                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                {isMultilineMarketInformation === false ? <TextField
                  component="div"
                  label="Thông tin thị trường"
                  value={projectEdit?.marketInformation}
                  onChange={(e) =>
                    setProjectEdit({
                      ...projectEdit,
                      marketInformation: e.target.value,
                    })
                  }
                  variant="standard"
                  placeholder="Thông tin thị trường?"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon={"material-symbols:edit-note-sharp"}
                        />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="none"
                  onFocus={() => setIsMultilineMarketInformation(true)}

                  error={errors.no ? true : false}
                  helperText={
                    errors.no ? (
                      errors.no
                    ) : (
                      <Box component="span" sx={{ color: "white" }}>
                        &emsp;
                      </Box>
                    )
                  }
                  onInput={handleOnInput}
                  sx={
                    (styleInputFullField,
                      { ...styleInputFullField, ml: 3, width: "94%" })
                  }
                /> : <TextField
                  component="div"
                  multiline
                  rows={5}
                  label="Thông tin thị trường"
                  value={projectEdit?.marketInformation}
                  onBlur={() => setIsMultilineMarketInformation(false)}

                  onChange={(e) =>
                    setProjectEdit({
                      ...projectEdit,
                      marketInformation: e.target.value,
                    })
                  }
                  onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )
                  }
                  inputRef={(input) => input && input.focus()}
                  variant="standard"
                  placeholder="Thông tin thị trường?"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon={"material-symbols:edit-note-sharp"}
                        />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="none"
                  error={errors.no ? true : false}
                  helperText={
                    errors.no ? (
                      errors.no
                    ) : (
                      <Box component="span" sx={{ color: "white" }}>
                        &emsp;
                      </Box>
                    )
                  }
                  onInput={handleOnInput}
                  sx={
                    (styleInputFullField,
                      { ...styleInputFullField, ml: 3, width: "94%" })
                  }
                />}

              </Box>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            {projectEdit.projectName !== "" ||
              projectEdit.intermediary !== "" ||
              projectEdit.technicalProgress !== "" ||
              projectEdit.processingTime !== "" ||
              projectEdit.issue !== "" ||
              projectEdit.investor !== "" ||
              projectEdit.contractStatus !== "" ||
              projectEdit.installationProgress ||
              projectEdit.solution ||
              projectEdit.projectStatus !== "" ||
              Object.keys(errors).length !== 0 ? (

              <CustomButton
                sx={{ background: "#f78b25" }}
                onClick={handleReset} variant="contained"> Làm mới</CustomButton>

            ) : (
              ""
            )}
            <CustomButton
              type="submit"
              variant="contained"> Cập Nhật</CustomButton>
            <CustomButton
              sx={{ background: "#f72533" }}
              onClick={handleClose} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận cập nhật?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                sx={{ fontSize: 20, fontFamily: "bold" }}
              >
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {/* {numSelected} */}
                </span>{" "}
                {/* {nameWork} */}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={update}>Xác nhận</CustomButton>
            <CustomButton onClick={handleCloseDialog} sx={{ background: 'red' }} autoFocus>
              Huỷ
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>

      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

import PropTypes from "prop-types";
// @mui
import {
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { useEffect } from "react";

// ----------------------------------------------------------------------


TaskWorkListHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default function TaskWorkListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  onChange,
  page,
  limit,
  listRole
}) {

  useEffect(() => {
    onChange(page, limit, order, orderBy);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order || orderBy]);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ position: 'sticky', top: 0,zIndex:999 }} className="tableHead" >
      <TableRow>
        <TableCell padding="checkbox" >
        {listRole.includes('quan-ly-tien-do-xoa') && rowCount > 0 ?  <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            className="tableHeadLabel"

          />:""}
         
        </TableCell>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "left" : "center"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ fontWeight: "bold", fontSize: 15,color:"white"}}
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            className="tableHeadLabel"
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// @mui
import { styled } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import ReactLoading from "react-loading";

// ----------------------------------------------------------------------
import { Helmet } from "react-helmet";
import { Section, Article, list } from "./generic";
import { useSelector } from "react-redux";


const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoadingLogin() {
  const customization = useSelector((state) => state.customization);

  return <>
    <Helmet>
      <title> Loading... </title>
    </Helmet>
    <Container>
      <StyledContent sx={{ textAlign: "center", alignItems: "center", }}>
        <Section >
          {list.map(l => (
            <Article key={l.prop}>
              <Box className={customization.mode} >
                <ReactLoading type={l.prop} color={customization.mode==='light'?'black':'white'} />
              </Box>
            </Article>
          ))}
        </Section>
      </StyledContent>
    </Container>

  </>
}

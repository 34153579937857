import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import React, { useEffect, useState } from "react";
import useValidator from "../../../../utils/Validator";
import { toast } from "react-toastify";
import Axios from "../../../../utils/Axios";

import { styled } from "@mui/material/styles";
import useLogin from "../../../../utils/Login/useLogin";

const styleInputFullField = {
  width: "100%",
  mt: 2,
};

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));


export const DialogCreateWork = ({ open, setOpen, onChange, page, limit, listRole }) => {
  const { validateCreateProject } = useValidator();
  const { account } = useLogin();

  const [selectedOption, setSelectedOption] = useState(null);



  const [workCreate, setWorkCreate] = useState({
    projectName: "",
    intermediary: "",
    technicalProgress: "",
    processingTime: "",
    issue: "",
    investor: "",
    installationProgress: "",
    solution: "",
    projectStatus: "",
  });

  const [showLoading, setShowLoading] = useState(false);
  const [listContractStatus, setListContractStatus] = useState([]);

  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({
    NameOfProject: "",
    Investor: "",
    Clue: "",
    TechnicalProgress: "",
    CurrentStatus: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setErrors({});
  }, [open])

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateCreateProject(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      createWork();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  useEffect(() => {
    if (listRole.includes('quan-ly-du-an-them-moi') === true) {
      getAllContactStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRole.length !== 0])

  const getAllContactStatus = async () => {
    setShowLoading(() => true);
    const response = await Axios.Project.getAllContactStatus();
    if (response.status === 200) {
      setListContractStatus(response.data)
      setShowLoading(() => false);
    } else {
      toast.error("Lấy danh sách hiện trạng thất bại!");
      setShowLoading(() => false);
    }
  };



  const createWork = async () => {
    setShowLoading(() => true);
    let data = workCreate;
    data.createdBy = account._id;

    const response = await Axios.Project.create(data);
    if (response.status === 201) {
      setShowLoading(() => false);
      handleReset();
      toast.success("Tạo dự án thành công");
      setOpen(false);
      onChange(page, limit, -1, "");
    } else {
      setErrors({
        projectName: "Tên dự án đã tồn tại!",
      });
      toast.error("Tạo dự án thất bại!");
      setShowLoading(() => false);
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateCreateProject(name, value) });
  };


  const handleReset = () => {
    setSelectedOption(null);
    const autocompleteInput = document.getElementById("autocomplete");

    if (autocompleteInput) {
      autocompleteInput.value = "";
    }

    setWorkCreate({
      projectName: "",
      intermediary: "",
      technicalProgress: "",
      processingTime: "",
      issue: "",
      investor: "",
      installationProgress: "",
      solution: "",
      projectStatus: "",
    });
    setErrors({});
  };

  const handleChange = (e, target) => {
    setSelectedOption(target);
    setWorkCreate({
      ...workCreate,
      contractStatus: target?._id,
    });
  };

  const renderOption = (props, option) => (
    <li {...props}
      className={
        option.statusName === 'Cancel' ? 'red-background' :
          option.statusName === 'Pending' ? 'blue-background' :
                  'no-background'
      }
      >
      {option.statusName}
    </li>
  );



  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle
            sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800 }}
          >
            Tạo Dự Án
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <Box tag={"p"} sx={{ mt: 2, display: "flex", width: "100%" }}>
                <Box sx={{ width: "60%", ml: 1 }}>
                  <TextField
                    component="div"
                    name="projectName"
                    label="Tên Dự Án"
                    multiline
                    value={workCreate?.projectName}
                    maxRows={5}
                    onChange={(e) =>
                      setWorkCreate({
                        ...workCreate,
                        projectName: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Tên Dự Án?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.projectName ? true : false}
                    helperText={
                      errors.projectName ? (
                        errors.projectName
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    sx={
                      (styleInputFullField,
                      {
                        ...styleInputFullField,
                        ml: 2,
                        width: "90%",
                        fontFamily: "inherit",
                      })
                    }
                    onInput={handleOnInput}

                  />
                  <TextField
                    component="div"
                    value={workCreate?.intermediary}
                    label="Đầu Mối"
                    multiline
                    maxRows={5}
                    onChange={(e) =>
                      setWorkCreate({
                        ...workCreate,
                        intermediary: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Tên Đầu Mối?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.intermediary ? true : false}
                    helperText={
                      errors.intermediary ? (
                        errors.intermediary
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    sx={
                      (styleInputFullField,
                      {
                        ...styleInputFullField,
                        ml: 2,
                        width: "90%",
                        fontFamily: "inherit",
                      })
                    }
                    onInput={handleOnInput}
                  />

                  <TextField
                    component="div"
                    value={workCreate?.technicalProgress}
                    label="Tiến Độ Kỹ Thuật"
                    multiline
                    maxRows={5}
                    onChange={(e) =>
                      setWorkCreate({
                        ...workCreate,
                        technicalProgress: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Tiến Độ Kỹ Thuật?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.technicalProgress ? true : false}
                    helperText={
                      errors.technicalProgress ? (
                        errors.technicalProgress
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    sx={
                      (styleInputFullField,
                      {
                        ...styleInputFullField,
                        ml: 2,
                        width: "90%",
                        fontFamily: "inherit",
                      })
                    }
                    onInput={handleOnInput}
                  />
                  <TextField
                    component="div"
                    value={workCreate?.processingTime}
                    label="Thời Gian Xử Lý"
                    multiline
                    maxRows={5}
                    onChange={(e) =>
                      setWorkCreate({
                        ...workCreate,
                        processingTime: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Thời Gian Xử Lý?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.processingTime ? true : false}
                    helperText={
                      errors.processingTime ? (
                        errors.processingTime
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                  />
                  <TextField
                    component="div"
                    value={workCreate?.issue}
                    label="Tồn Đọng Kỹ Thuật / Thiết Bị"
                    multiline
                    maxRows={5}
                    onChange={(e) =>
                      setWorkCreate({ ...workCreate, issue: e.target.value })
                    }
                    variant="standard"
                    placeholder="Tồn Đọng?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.issue ? true : false}
                    helperText={
                      errors.issue ? (
                        errors.issue
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                  />
                </Box>

                <Box sx={{ width: "60%" }}>
                  <TextField
                    component="div"
                    value={workCreate?.investor}
                    label="Chủ Đầu Tư"
                    multiline
                    maxRows={5}
                    onChange={(e) =>
                      setWorkCreate({
                        ...workCreate,
                        investor: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Chủ Đầu Tư?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.investor ? true : false}
                    helperText={
                      errors.investor ? (
                        errors.investor
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    onInput={handleOnInput}
                  />

                  <Autocomplete
                    name="listContractStatus"
                    options={listContractStatus}
                    getOptionLabel={(option) => option.statusName}
                    value={selectedOption}
                    onChange={handleChange}
                    renderOption={renderOption}
                    renderInput={(params) => (
                      <TextField
                        name="contractStatus"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"bx:code-block"} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        variant="standard"
                        label="Hiện trạng"
                        placeholder="Chọn hiện trạng"
                        sx={
                          (styleInputFullField,
                          {
                            ...styleInputFullField,
                            ml: 2,
                            width: "90%",
                          })
                        }

                        error={errors.contractStatus ? true : false}
                        helperText={
                          errors.contractStatus ? (
                            errors.contractStatus
                          ) : (
                            <Box component="span" sx={{ color: "white" }}>
                              &emsp;

                            </Box>
                          )
                        }

                      />
                    )}
                  />

                  <TextField
                    component="div"
                    value={workCreate?.installationProgress}
                    multiline
                    maxRows={5}
                    label="Tiến Độ Lắp Đặt"
                    onChange={(e) =>
                      setWorkCreate({
                        ...workCreate,
                        installationProgress: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Tiến Độ?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.installationProgress ? true : false}
                    helperText={
                      errors.installationProgress ? (
                        errors.installationProgress
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                  />
                  <TextField
                    component="div"
                    value={workCreate?.solution}
                    label="Hướng Xử Lý"
                    multiline
                    maxRows={5}
                    onChange={(e) =>
                      setWorkCreate({
                        ...workCreate,
                        solution: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Hướng Xử Lý?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.solution ? true : false}
                    helperText={
                      errors.solution ? (
                        errors.solution
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                  />
                  <TextField
                    component="div"
                    value={workCreate?.projectStatus}
                    label="Giao Hàng - Lắp Đặt"
                    multiline
                    maxRows={5}
                    onChange={(e) =>
                      setWorkCreate({
                        ...workCreate,
                        projectStatus: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Giao Hàng?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.projectStatus ? true : false}
                    helperText={
                      errors.projectStatus ? (
                        errors.projectStatus
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                  />
                </Box>
              </Box>

            </Grid>

          </DialogContent>

          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            {workCreate.projectName !== "" ||
              workCreate.intermediary !== "" ||
              workCreate.technicalProgress !== "" ||
              workCreate.processingTime !== "" ||
              workCreate.issue !== "" ||
              workCreate.investor !== "" ||
              workCreate.installationProgress !== "" ||
              workCreate.solution !== "" ||
              workCreate.projectStatus !== "" ||
              Object.keys(errors).length !== 0 ? (
              <CustomButton
                onClick={handleReset}
                sx={{ background: "#f78b25" }}
                variant="contained">Làm mới</CustomButton>
            ) : (
              ""
            )}
            <CustomButton type="submit" variant="contained">Thêm mới</CustomButton>
            <CustomButton onClick={handleClose} sx={{ background: "#f72533" }} variant="contained">Hủy</CustomButton>

          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

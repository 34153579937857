/* eslint-disable no-unused-expressions */
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import React, { useEffect, useState } from "react";
import useValidator from "../../../../utils/Validator";
import { toast } from "react-toastify";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";

const styleInputFullField = {
  width: "100%",
  mt: 2,
};

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));


const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      {children}
    </Grid>
  );
};

export const DialogEditHoliday = ({ open, setOpen, holiday, onChange }) => {
  let today = new Date(holiday?.date);
  let month = today.getMonth() + 1
  if (month < 10) {
    month = "0" + month;
  } else {
    // eslint-disable-next-line no-self-assign
    month = month;
  }
  let day = today.getDate();
  if (day < 10) {
    day = "0" + day;
  } else {
    // eslint-disable-next-line no-self-assign
    day = day;
  }

  let date =
    today.getFullYear() + "-" + month + "-" + day


  holiday.date = date;
  holiday.note = holiday?.note?.includes('holiday-') ? holiday?.note?.slice(8) : holiday?.note;
  const [holidayEdit, setHolidayEdit] = useState(holiday);

  const { validateHoliday } = useValidator();
  const [openDialog, setOpenDialog] = React.useState(false);
  console.log('update')

  // useEffect(() => {

  //   let today = new Date(holiday?.date);
  //   let month = today.getMonth() + 1
  //   if (month < 10) {
  //     month = "0" + month;
  //   } else {
  //     // eslint-disable-next-line no-self-assign
  //     month = month;
  //   }
  //   let day = today.getDate();
  //   if (day < 10) {
  //     day = "0" + day;
  //   } else {
  //     // eslint-disable-next-line no-self-assign
  //     day = day;
  //   }

  //   let date =
  //     today.getFullYear() + "-" + month + "-" + day


  //   holiday.date = date;
  //   holiday.note = holiday?.note?.includes('holiday-') ? holiday?.note?.slice(8) : holiday?.note;

  //   setHolidayEdit(holiday);
  //   setErrors({})
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open])

  const [showLoading, setShowLoading] = useState(false);


  const [errors, setErrors] = useState({});

  const [inputValues, setInputValues] = useState({
    name: "",
    date: "",
    note: ""
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setErrors({});
  }, [open])

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateHoliday(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      handleClickOpen()
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const update = async () => {
    setShowLoading(() => true);
    holidayEdit.note = 'holiday-' + holidayEdit.note
    const response = await Axios.Holiday.update(holidayEdit._id, holidayEdit);
    if (response.status === 200) {
      setShowLoading(() => false);
      setOpenDialog(false);
      toast.success("Cập nhật ngày lễ thành công");
      setOpen(false);
      onChange(0, 10, -1, "");

    } else {
      if (response.code === 17 && response.status === 400) {
        setErrors({
          date: "Không thể cập nhật ngày lễ đã qua!",
        })
        toast.error("Không thể cập nhật ngày lễ đã qua!");
        setShowLoading(() => false);

      } else {
        toast.error("Cập nhật ngày lễ thất bại!");
        setShowLoading(() => false);
      }
      setOpenDialog(false);
    }

  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateHoliday(name, value) });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle
            sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800, }}
          >
            Chỉnh sửa ngày lễ
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <LayoutFormTwoField>
                <Grid item xs={6}>
                  <TextField
                    name="name"
                    value={holidayEdit?.name ? holidayEdit?.name : ""}
                    label="Ngày lễ"
                    variant="standard"
                    placeholder="Ngày lễ?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"carbon:transform-instructions"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setHolidayEdit({ ...holidayEdit, name: e.target.value });
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    error={errors.name ? true : false}
                    helperText={
                      errors.name ? (
                        errors.name
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="date"
                    label="Ngày"
                    variant="standard"
                    type="date"
                    placeholder="Nhập ngày lễ"
                    value={holidayEdit?.date}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"uiw:date"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setHolidayEdit({ ...holidayEdit, date: e.target.value });
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    error={errors.date ? true : false}
                    helperText={
                      errors.date ? (
                        errors.date
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>
              </LayoutFormTwoField>
              <TextField
                name="note"
                component="div"
                label="Ghi chú"
                value={holidayEdit?.note ? holidayEdit?.note : ""}
                multiline
                maxRows={5}
                onChange={(e) => {
                  setHolidayEdit({ ...holidayEdit, note: e.target.value });
                }}
                variant="standard"
                placeholder="Ghi chú?"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon={"material-symbols:edit-note-sharp"}
                      />
                    </InputAdornment>
                  ),
                }}

                autoComplete="none"
                error={errors.note ? true : false}
                helperText={
                  errors.note ? (
                    errors.note
                  ) : (
                    <Box component="span" sx={{ color: "white" }}>
                      &emsp;
                    </Box>
                  )
                }
                onInput={handleOnInput}
                sx={
                  (styleInputFullField,
                    { ...styleInputFullField, ml: 2, width: "100%" })
                }
              />

            </Grid>
          </DialogContent>

          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton type="submit" variant="contained">Cập nhật</CustomButton>
            <CustomButton onClick={handleClose} sx={{ background: "#f72533" }} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ zIndex: 99999 }}
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận cập nhật?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                sx={{ fontSize: 20, fontFamily: "bold" }}
              >
                <span style={{ color: "red", fontWeight: "bold" }}></span>{" "}
                {holiday.name}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={update}>Xác nhận</CustomButton>
            <CustomButton onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
              Huỷ
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>

    </>
  );
};

import React, { useRef, useState } from "react";
import styled from "styled-components";
import Task from "./Task";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
import Axios from "../../utils/Axios";
import { toast } from "react-toastify";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  background-color: #f7f9fa;
  border-radius: 20px;
  width: 260px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
`;
const Title = styled.h3`
  padding: 8px;
`;

const TaskList = styled.div`
  padding: 8px;
  margin-top: -5px;
  transition: background-color 0.2s ease;

  flex-grow: 1;
  min-height: 65px;
`;
// background-color: ${(props) =>
//   props?.isDraggingOver ? "#fcf8eb" : "inherit"};

class InnerList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps?.tasks === this.props?.tasks) {
      return false;
    }
    return true;
  }
  render() {
    return this.props.tasks.map((task, index) => (
      <Task
        key={task._id}
        task={task}
        index={index}
        getListTaskStatus={this.props.getListTaskStatus}
        listUser={this.props.listUser}
      />
    ));
  }
}
export default function Column(props) {
  const [open, setOpen] = useState(false);
  const myRef = useRef(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [taskStatus, setTaskStatus] = useState(props.column);
  const [task, setTask] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [idTaskStatus, setIdTaskStatus] = useState();
  const [name, setName] = useState();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = (id, name) => {
    setIdTaskStatus(id);
    setName(name);
    setOpenDialog(true);
  };

  const createTask = async () => {
    setOpen(false);
    if (Object.keys(task).length !== 0) {
      if (checkIfAllWhitespace(task.name) === false) {
        const response = await Axios.TaskProject.createTask(
          props.column._id,
          task
        );
        if (response.status === 200) {
          props.getListTaskStatus();
          toast.success("Tạo thẻ thành công");
          setOpen(false);
          setTask({})
        } else {
          toast.error("Tạo thẻ thất bại");
        }
      }
    }
  };

  const updateTaskStatus = async (id) => {
    setOpenUpdate(false);
    if (props.column.name !== taskStatus.name) {
      if (checkIfAllWhitespace(taskStatus.name) === false) {
        const response = await Axios.TaskProject.updateTaskStatus(
          id,
          taskStatus
        );
        if (response.status === 200) {
          props.getListTaskStatus();
        } else {
          toast.error("Cập nhật thất bại");
        }
      } else {
        setTaskStatus(props.column);
      }
    }
  };

  function checkIfAllWhitespace(str) {
    return str.trim().length === 0;
  }

  const deleteTaskStatus = async () => {
    setOpen(false);
    const response = await Axios.TaskProject.deleteTaskStatus(idTaskStatus);
    if (response.status === 200) {
      props.getListTaskStatus();
      toast.success("Xoá cột thành công");
      setOpen(false);
    } else {
      toast.error("Xoá cột thất bại");
    }
  };

  return (
    <>
      <div style={{ marginTop: 55 }}>
        <Draggable draggableId={props.column._id} index={props.index}>
          {(provided) => (
            <Container {...provided.draggableProps} ref={provided.innerRef}>
              <Title {...provided.dragHandleProps} ref={myRef}>
                {openUpdate === false ? (
                  <div
                    style={{
                      width: "86%",
                      marginTop: -20,
                      marginLeft: "16px",
                      color: "black",
                      position: "sticky",
                      paddingTop: 5,
                      paddingBottom: 7
                    }}
                    ref={myRef}
                  >
                    <Iconify
                      icon={"material-symbols:delete-outline"}
                      sx={{
                        color: "red",
                        cursor: "pointer",
                        marginTop: -0.3,
                        ml:-2.5,
                        position: "absolute"
                      }}
                      onClick={(e) => {
                        handleClickOpen(props.column._id, props.column.name);
                      }}
                    />
                    <Iconify
                      icon={"material-symbols:edit-outline"}
                      sx={{
                        marginLeft:25.5,
                        marginTop: -0.3,
                        position: "absolute",
                        color: "blue",
                        cursor: "pointer"
                      }}
                      onClick={(e) => {
                        setOpenUpdate(true);
                      }}
                    />
                    <p style={{ width:'205px',margin:'auto',textAlign:'center',}}>{props.column.name}</p>
                  </div>
                  
                ) : (
                  <div
                    style={{
                      width: "84%",
                      marginTop: -20,
                      marginLeft: "18px",
                      color: "black",
                      textAlign: "center",
                      position: "sticky",
                      paddingTop: 5,
                      paddingBottom: 7,

                    }}
                    ref={myRef}
                  >
                    <TextField
                      variant="standard"
                      value={taskStatus?.name}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      onChange={(e) => {
                        setTaskStatus({ ...taskStatus, name: e.target.value });
                      }}
                      sx={{
                        width: "205px",
                        ml: 0,
                        mt: 0
                      }}
                      inputRef={(input) => input && input.focus()}
                      onBlur={() => updateTaskStatus(props.column._id)}
                    />
                  </div>
                )}
              </Title>

              <Droppable droppableId={props.column._id} type="task">
                {(provided, snapshot) => (
                  <div
                    style={{
                      maxHeight: "60vh",
                      overflowY: "auto",
                      marginTop: "-30px"
                    }}
                  >
                    <TaskList
                      ref={provided.innerRef}
                      {...provided?.droppableProps}
                    >
                      <InnerList
                        tasks={props?.tasks}
                        getListTaskStatus={props?.getListTaskStatus}
                        listUser={props?.listUser}
                      />
                      {provided.placeholder}
                    </TaskList>
                  </div>
                )}
              </Droppable>
              {open === true ? (
                <TextField
                  component="div"
                  name="intermediary"
                  label="Tên thẻ"
                  multiline
                  maxRows={5}
                  variant="standard"
                  placeholder="Nhập tiêu đề cho thẻ này..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={"material-symbols:edit-note-sharp"} sx={{color:'black'}}/>
                      </InputAdornment>
                    ),style: { color: 'black' },
                  }}
                  onChange={(e) => {
                    setTask({ ...task, name: e.target.value });
                  }}
                  autoComplete="none"
                  sx={{
                    width: "92%",
                    ml: 1.3,
                    // background: "white"
                  }}
                  onBlur={() => createTask()}
                  autoFocus
                />
              ) : (
                ""
              )}

              <Button
                sx={{
                  height: "45px",
                  color: "black",
                  fontWeight: 600,
                  margin: "auto",
                  width: "60%",
                  marginBottom: 2.5,
                  background: "#ffd600",
                  "&:hover": {
                    background: "#ffea00"
                  },
                  mt: 1,
                  boxShadow: 3,
                  borderRadius: 2
                }}
                onClick={(e) => {
                  setOpen(true);
                }}
              >
                <Iconify icon={"material-symbols:add"} />
                Thêm thẻ
              </Button>
            </Container>
          )}
        </Draggable>
      </div>

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận xoá?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{  width: "95%" }}>
              {name}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ mt: -3 }}>
            <Button
              sx={{
                backgroundColor: "#5351ed", // màu nền button
                color: "white", // màu chữ button
                fontWeight: "bold",
                borderRadius: 15,
                marginBottom: 1,
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 5,
                "&:hover": {
                  backgroundColor: "#f7f9fa", // màu nền khi hover vào button
                  color: "red" // màu chữ khi hover vào button
                }
              }}
              onClick={deleteTaskStatus}
            >
              Xác nhận
            </Button>
            <Button
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: "red", // màu nền button
                color: "white", // màu chữ button
                fontWeight: "bold",
                borderRadius: 15,
                marginBottom: 1,
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 5,
                "&:hover": {
                  backgroundColor: "#f7f9fa", // màu nền khi hover vào button
                  color: "red" // màu chữ khi hover vào button
                }
              }}
            >
              Huỷ
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    </>
  );
}

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Badge, Collapse, ListItemButton, ListItemIcon, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {

    const customization = useSelector((state) => state.customization);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const approve = useSelector((state) => state.approve.data);

    let listCheckRole = [];

    const userLoginDetail = useSelector((state) => state.userLoginDetail.data);
    userLoginDetail?.positionId?.listPermission?.forEach(element => {
        listCheckRole.push(element.permissionCode)
    });



    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };
    const { pathname } = useLocation();
    const checkOpenForParent = (child, id) => {
        child.forEach((item) => {
            if (item.url === pathname) {
                setOpen(true);
                setSelected(id);
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
        setOpen(false);
        setSelected(null);
        if (menu.children) {
            menu.children.forEach((item) => {
                if (item.children?.length) {
                    checkOpenForParent(item.children, menu.id);
                }
                if (item.url === pathname) {
                    setSelected(menu.id);
                    setOpen(true);
                }
                if (pathname.includes('/saleProjectDetailsPage/')) {
                    if (item.url === '/quan-ly-du-an-sale') {
                        setSelected(menu.id);
                        setOpen(true);
                    }
                }
                if (pathname.includes('/projectDetailsPage/')) {
                    if (item.url === '/quan-ly-du-an') {
                        setSelected(menu.id);
                        setOpen(true);
                    }
                }
                if (pathname.includes('/profile/')) {
                    if (item.url === '/quan-ly-nguoi-dung') {
                        setSelected(menu.id);
                        setOpen(true);
                    }
                }
                if (pathname.includes('/LeaveDetailPage/')) {
                    if (item.url === '/quan-ly-ngay-phep') {
                        setSelected(menu.id);
                        setOpen(true);
                    }
                }
                if (pathname.includes('/getListUserInProject/')) {
                    if (item.url === '/quan-ly-tien-do') {
                        setSelected(menu.id);
                        setOpen(true);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);
    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`,
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon> 

                {menu.title === "Nhân sự" && approve !== 0 && listCheckRole.includes('quan-ly-phe-duyet') ? <div>{menu.title}<Badge sx={{ ml: 0.5, mt: -2.5 }} color="error" variant="dot">

                </Badge></div> : <div>{menu.title} </div>}


                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{fontSize:15,ml:-1}}>
                {menus}
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;

import axios from 'axios';
// const baseURL = "http://103.176.179.174:3001";
// const baseURL = "http://192.168.1.10:3001";
const baseURL = "https://management-backend.siginx.com";

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'X-Auth-Token': 'your-auth-token-here',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

instance.interceptors.request.use((config) => {
  // Add Authorization header with access token if available
  const localToken = localStorage.getItem('account');
  const account = JSON.parse(localToken) ? JSON.parse(localToken) : "";

  const token = account.accessToken;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});



instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const localToken = localStorage.getItem('account');
      const account = JSON.parse(localToken) ? JSON.parse(localToken) : "";

      try {
        const response = await axios.post(`${baseURL}/api/v1/auth/refreshToken`, {
          refreshToken: account.refreshToken
        });
        const accessToken = response.data.data.accessToken;
        let data = account;
        data.accessToken = accessToken;

        localStorage.setItem("account", JSON.stringify(data));

        return instance(originalRequest);
      } catch (err) {
        // Handle refresh token failure
        // Redirect to login page, logout user, etc.
        localStorage.removeItem("account");
        window.location = "/login";
      }
    }
    if (error.response.status === 400) {
      return error.response;
    }else{
      return Promise.reject(error);

    }
  }
);

class Axios {

  static async get(url, params = {}, config = {}) {
    try {
      const response = await instance.get(url, {
        ...config,
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async post(url, data = {}, config = {}) {
    try {
      const response = await instance.post(url, data, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async put(url, data = {}, config = {}) {
    try {
      const response = await instance.put(url, data, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async delete(url, data) {
    try {
      const response = await instance.delete(url, {
        data: data || {},
      });
      // console.log(response);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  

  static async getExcel(url, data) {
    let res;
    await instance.get(`${baseURL}${url}`, {
        data: data,
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Cham_cong_thang '+data.month+'_nam '+data.year+'.xlsx');
        document.body.appendChild(link);
        link.click();
        res= response;
      });

      return res;
    
  }


  static async postNoToken(url, data, headers) {
    const response = await axios
      .post(`${baseURL}${url}`, data, {
        headers: {
          ...headers,
        },
      })
      .then((result) => result)
      .catch((error) => error.response);
    return response;
  }


}

export default Axios;

import { useState, useRef, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
  Stack,
  Typography,
  MenuItem,
} from "@mui/material";

// project imports
import MainCard from "./../../../components/cards/MainCard";
import Transitions from "./../../../components/extended/Transitions";

// assets
import { IconSettings } from '@tabler/icons-react';
import Iconify from "../../../components/iconify/Iconify";
import { useNavigate } from "react-router-dom";
import logo from "./../../../logoAvatar.jpg";
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from "react-redux";
import useLogin from "../../../utils/Login/useLogin";
// ==============================|| PROFILE MENU ||============================== //

const MENU_OPTIONS = [
  {
    label: "Trang chủ",
    path: "/",
    icon: "ic:outline-home",
  },
  {
    label: "Đổi mật khẩu",
    path: "/changePassword",
    icon: "carbon:password",
  },
];

const ProfileSection = () => {

  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.userLoginDetail.data);
  const { logout } = useLogin();


  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleRedirect = (path) => {
    navigate(path);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  return (
    <>
      <Chip
        sx={{
          height: "45px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          // backgroundImage: "linear-gradient(to top, #ccf3fc 20%, #cce3fc 80%)",
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            // background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={logo}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}

      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2}}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center" sx={{mt:-1.4}}>
                        <Typography variant="h4">
                          Hi, {userDetail?.fullname}
                        </Typography>
                      </Stack>
                      <Typography sx={{fontSize:15,mt:0}}>
                      {userDetail?.email}
                      </Typography>
                    </Stack>
                    <LinearProgress sx={{ height: "1.4px", mt: 0.2 }} />
                  </Box>
                  <Stack sx={{ p: 0.5,mt:-2 }}>
                    {MENU_OPTIONS.map((option) => (
                      <MenuItem
                        key={option.label}
                        onClick={() => handleRedirect(option.path)}
                      >
                        <Iconify icon={option.icon} sx={{ marginRight: 1, }} />
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem
                     onClick={() => handleRedirect(`/profile/${userDetail._id}`)}
                    >
                      <Iconify icon={"teenyicons:user-circle-outline"} />&nbsp;
                      Thông tin tài khoản
                    </MenuItem>
                  </Stack>
                  <Divider />

                  <MenuItem onClick={logout} sx={{ m: 1, }}>
                    <Iconify icon={"bx:log-out"} sx={{ marginRight: 2 }} />
                    Đăng xuất
                  </MenuItem>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;

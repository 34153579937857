import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import Axios from "../../../../utils/Axios";
import { toast } from "react-toastify";
import useValidator from "../../../../utils/Validator";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";




const CustomButton = styled
  (Button)(({ theme }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));

const styleInputFullField = {
  width: "100%",
  mb: 3,
  ml: 2,
};

const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      {children}
    </Grid>
  );
};

export const RegisterDialog = ({ open = false, setOpen, onChange, listRole }) => {

  const { validate } = useValidator();
  const [userReg, setUserReg] = useState({});
  const [listPosition, setListPosition] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [inputValues, setInputValues] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmpassword: "",
    position: "",
  });

  const [errors, setErrors] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmpassword: "",
    position: "",
  });

  useEffect(() => {
    setErrors({});
  }, [open])

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validate(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      if (userReg.password !== userReg.confirmpassword) {
        setErrors({
          confirmpassword: "Mật khẩu không khớp!",
        });
        return;
      } else {
        createUser();
      }
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validate(name, value) });
  };


  useEffect(() => {
      getAllPosition();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllPosition = async () => {
    setShowLoading(() => true);
    const response = await Axios.Position.getAll(0, 20);
    if (response.status === 200) {
      setListPosition(response.data.listItem);
      setShowLoading(() => false);
    } else {
      setShowLoading(() => false);
    }
  }

  const createUser = async () => {
    setShowLoading(() => true);
    const response = await Axios.User.register(userReg);
    if (response.status === 201) {
      setShowLoading(() => false);
      onChange(0, 10, -1, "");
      toast.success("Đăng ký tài khoản thành công");
      setOpen(false);
    } else {
      setErrors({
        email: "Email đã tồn tại!",
      });
      setShowLoading(() => false);
    }
  };

  const handleChange = (e, value) => {
    setUserReg({
      ...userReg,
      positionId: value?._id,
    })
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800, }}>Tạo Tài Khoản Mới</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <LayoutFormTwoField>
                <Grid item xs={6}>
                  <TextField
                    name="email"
                    label="Email"
                    variant="standard"
                    placeholder="Nhập email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"entypo:mail"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUserReg({ ...userReg, email: e.target.value });
                    }}
                    autoComplete="none"
                    sx={styleInputFullField}
                    error={errors.email ? true : false}
                    helperText={
                      errors.email ? (
                        errors.email
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="fullname"
                    label="Họ Và Tên"
                    // value={userReg?.fullName ? userReg.fullName : newUser.fullName}
                    placeholder="Nhập Họ Và Tên"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"icon-park-solid:edit-name"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUserReg({ ...userReg, fullname: e.target.value });
                    }}
                    autoComplete="none"
                    sx={styleInputFullField}
                    error={errors.fullname ? true : false}
                    helperText={
                      errors.fullname ? (
                        errors.fullname
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>
              </LayoutFormTwoField>
              <LayoutFormTwoField>
                <Grid item xs={12}>
                  <Autocomplete
                    name="position"
                    options={listPosition}
                    getOptionLabel={(option) => option.positionName}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        name="position"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"bx:code-block"} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        variant="standard"
                        label="Phân quyền"
                        placeholder="Chọn phân quyền"
                        sx={styleInputFullField}
                        error={errors.position ? true : false}
                        helperText={
                          errors.position ? (
                            errors.position
                          ) : (
                            <Box component="span" sx={{ color: "white" }}>
                              &nbsp;
                            </Box>
                          )
                        }
                      />
                    )}
                  />
                </Grid>
              </LayoutFormTwoField>
              <LayoutFormTwoField>
                <Grid item xs={6}>
                  <TextField
                    name="password"
                    label="Mật khẩu"
                    placeholder="Nhập Mật Khẩu"
                    type={showNewPassword ? "text" : "password"}
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"carbon:password"} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setNewShowPassword(!showNewPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showNewPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUserReg({ ...userReg, password: e.target.value });
                    }}
                    autoComplete="none"
                    sx={styleInputFullField}
                    error={errors.password ? true : false}
                    helperText={
                      errors.password ? (
                        errors.password
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="confirmpassword"
                    label="Nhập lại mật khẩu"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Nhập Lại Mật Khẩu"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"material-symbols:password"} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setConfirmShowPassword(!showConfirmPassword)
                            }
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showConfirmPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUserReg({
                        ...userReg,
                        confirmpassword: e.target.value,
                      });
                    }}
                    sx={styleInputFullField}
                    autoComplete="none"
                    error={errors.confirmpassword ? true : false}
                    helperText={
                      errors.confirmpassword ? (
                        errors.confirmpassword
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  // autoFocus
                  />
                </Grid>
              </LayoutFormTwoField>

            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton type="submit" variant="contained"> Đăng Ký</CustomButton>
            <CustomButton
              sx={{ background: "#f72533" }}
              onClick={handleClose} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

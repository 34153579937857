import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { useState } from "react";
import { toast } from "react-toastify";
import useValidator from "../../../../utils/Validator";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";
import useLogin from "../../../../utils/Login/useLogin";
import AxiosLogin from "../../../../utils/Axios/Axios";

// ==============================||  ||============================== //
const styleInputFullField = {
  width: "100%",
  mb: 3,
  ml: 2,
};

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));


const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      {children}
    </Grid>
  );
};

const ActiveUserDialog = ({ open, setOpen, email, password, remember }) => {

  const { setAccount } = useLogin();
  const [userActive, setUserActive] = useState({});
  const { validateActiveUser } = useValidator();
  const [showLoading, setShowLoading] = useState(false);

  let url = localStorage.getItem("url");

  const handleClose = () => {
    setOpen(false);
  };

  const [errors, setErrors] = useState({
    activeCode: "",
  });
  const [inputValues, setInputValues] = useState({
    activeCode: "",
  });

  const activeUser = async () => {
    userActive.email = email;
    setShowLoading(() => true);
    try {
      const responseActive = await Axios.User.activeUser(userActive);


      if (responseActive.status === 200) {
        setShowLoading(() => false);
        toast.success("Kích hoạch tài khoản thành công");
        setOpen(false);
        setShowLoading(() => true);
        const data = {};
        data.email = email
        data.password = password
        const response = await AxiosLogin.postNoToken("/api/v1/auth/login", data);

        if (response.status === 200 && response.data.code === 1) {
          localStorage.removeItem("account");
          let account = {};
          let accessToken = response.data.data.accessToken;
          account.accessToken = accessToken;
          localStorage.setItem("account", JSON.stringify(account));

          const responseDetail = await AxiosLogin.get(
            "/api/v1/auth/userLoginDetail",
          );

          let listRole = [];
          let listDetail = [];
          if (responseDetail.status === 200) {
            localStorage.removeItem("account");
            response.data.data._id = responseDetail.data._id;
            // response.data.data.fullname = responseDetail.data.fullname;
            // response.data.data.email = responseDetail.data.email;

            responseDetail.data.positionId.listPermission.forEach(element => {
              listDetail.push(element.permissionCode)
            });
            // response.data.data.role = listDetail;

            setAccount(response.data.data, inputValues.email, inputValues.password, remember);
          } else {

          }

          if (listDetail.includes("quan-ly-du-an") === true) {
            listRole.push("quan-ly-du-an")
          }
          if (listDetail.includes("quan-ly-nguoi-dung") === true) {
            listRole.push("quan-ly-nguoi-dung")
          }
          if (listDetail.includes("quan-ly-chuc-vu") === true) {
            listRole.push("quan-ly-chuc-vu")
          }
          if (listDetail.includes("quan-ly-cham-cong") === true) {
            listRole.push("quan-ly-cham-cong")
          }
          if (listDetail.includes("bao-cao-cham-cong") === true) {
            listRole.push("bao-cao-cham-cong")
          }
          if (listDetail.includes("quan-ly-phe-duyet") === true) {
            listRole.push("quan-ly-phe-duyet")
          }
          if (listDetail.includes("quan-ly-ngay-phep") === true) {
            listRole.push("quan-ly-ngay-phep")
          }
          if (listDetail.includes("quan-ly-ngay-le") === true) {
            listRole.push("quan-ly-ngay-le")
          }
          if (listDetail.includes("quan-ly-du-an-sale") === true) {
            listRole.push("quan-ly-du-an-sale")
          }
          if (listDetail.includes("quan-ly-tien-do") === true) {
            listRole.push("quan-ly-tien-do")
          }

          url = url + "";

          if (url === "/quan-ly-du-an") {
            if (listRole.includes('quan-ly-du-an') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/quan-ly-nguoi-dung") {
            if (listRole.includes('quan-ly-nguoi-dung') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/quan-ly-chuc-vu") {
            if (listRole.includes('quan-ly-chuc-vu') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/quan-ly-cham-cong") {
            if (listRole.includes('quan-ly-cham-cong') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/bao-cao-cham-cong") {
            if (listRole.includes('bao-cao-cham-cong') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/quan-ly-phe-duyet") {
            if (listRole.includes('quan-ly-phe-duyet') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }

          if (url === "/quan-ly-ngay-phep") {
            if (listRole.includes('quan-ly-ngay-phep') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/quan-ly-ngay-le") {
            if (listRole.includes('quan-ly-ngay-le') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/quan-ly-du-an-sale") {
            if (listRole.includes('quan-ly-du-an-sale') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/quan-ly-tien-do") {
            if (listRole.includes('quan-ly-tien-do') === true) {
              window.location = url;
            } else {
              window.location = "/" + listRole[0];
            }
          }
          if (url === "/" || url === "/dashboard" || url === "/login") {
            if (listRole.includes('quan-ly-du-an') || listRole.includes('quan-ly-nguoi-dung')) {
              window.location = "/dashboard";
            } else {
              window.location = "/" + listRole[0];
            }
          }
          return true;
        }

      }
      else {
        setShowLoading(() => false);
        if (responseActive.status === 400 && responseActive.code === 10) {
          setErrors({
            activeCode: "Mã code không chính xác!",
          });
        }
      }
    } catch (error) {
      setShowLoading(() => false);
      // setErrors({
      //   activeCode: "Mã code không chính xác!",
      // });
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateActiveUser(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      activeUser();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateActiveUser(name, value) });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle>Kích hoạt tài khoản</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <LayoutFormTwoField>
                <Grid item xs={6}>
                  <TextField
                    component="div"
                    name="email"
                    label="Email"
                    value={email}
                    variant="standard"
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"entypo:mail"} />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    sx={styleInputFullField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="activeCode"
                    label="Mã Code"
                    placeholder="Nhập Mã Code"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"ic:baseline-qr-code-scanner"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUserActive({ ...userActive, activeCode: e.target.value });
                    }}
                    autoComplete="none"
                    sx={styleInputFullField}
                    error={errors.activeCode ? true : false}
                    helperText={
                      errors.activeCode ? (
                        errors.activeCode
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          .
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                    autoFocus
                  />
                </Grid>
              </LayoutFormTwoField>
              <LayoutFormTwoField >
                <Grid item xs={6} >
                </Grid>
                <Grid item xs={6}>
                  {/* <TextField /> */}
                  <Box sx={{ background: 'white', ml: 20, color: 'white' }}>.</Box>
                </Grid>
              </LayoutFormTwoField>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton sx={{
              textShadow: '0 -1px 0 #EFB7DE',
              borderRadius: '20px'
            }} type="submit" variant="contained">
              Kích hoạt
            </CustomButton>
          </DialogActions>
        </form>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

export default ActiveUserDialog;

// material-ui
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";

import { Helmet } from "react-helmet";

import { useState } from "react";
import { makeStyles } from "@mui/styles";
import LaveDayListToolbar from "../sections/@dashboard/LeaveDay/LeaveDayListToolbar";
import Axios from "../../../utils/Axios";
import DateTimeOfDay from "../../../utils/DateTimeOfMessage/DateTimeOfDay";
import LeaveDayListHead from "../sections/@dashboard/LeaveDay/LeaveDayListHead";
import { addLeaveDay } from '../../../utils/store/actions';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


// ==============================|| LEAVE DAY ||============================== //

const TABLE_HEAD = [
  { id: "1", label: "Họ tên", alignRight: false },
  { id: "2", label: "Ngày", alignRight: false },
  { id: "3", label: "Nội dung", alignRight: false },
  { id: "4", label: "Lý do", alignRight: false },
];

const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#dee2e3",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#dee2e3",
  },
};

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});

const LeaveDay = (props) => {

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [listRole, setListRole] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [countPage, setCountPage] = useState(0);
  const [listApprove, setListApprove] = useState([]);
  const dispatch = useDispatch();

  const approve = useSelector((state) => state.approve.data);


  useEffect(()=>{
    setListRole(props.listRole)
  },[props])

  const getAllApprove = async (page, limit, sortOrder, property) => {
    setShowLoading(() => true);
    const response = await Axios.LeaveDay.getAll(
      page,
      limit,
      sortOrder,
      "",
      property
    );
    if (response.status === 200) {
      setShowLoading(() => false);
      setSelected([]);
      setPage(page);
      setLimit(limit);
      setListApprove(response.data);
      setCountPage(parseInt(response.data.totalItem));

      if(approve!==undefined){
        dispatch(addLeaveDay(parseInt(response.data.totalItem)))
      }
      // 
    } else {
      setShowLoading(() => false);
      toast.error("Lấy danh sách phê duyệt thất bại!");
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllApprove(newPage, 5, -1, "");
  };

  const handleChangeRowsPerPage = (event) => {
    getAllApprove(0, event.target.value, -1, "");
    setLimit(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = ((orderBy === property || orderBy !== property) && order === "asc");
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listApprove?.listItem?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    // if (listRole.includes('quan-ly-nguoi-dung-xoa') === true) {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    // }

  };

  return (
    <>
      <Helmet>
        <title> Phê duyệt </title>
      </Helmet>
      <Grid container sx={{width: '100%'}}>
        <Grid item xs={12}>
          <Grid container >
            <Grid item xs={12} sm={6} md={12}>
              <LaveDayListToolbar
                numSelected={selected.length}
                selected={selected}
                onChange={getAllApprove}
                page={page}
                limit={limit}
              />
                <TableContainer
                  sx={{
                    minWidth: 100,
                    maxHeight: 560,
                    ...scrollbar,
                    borderRadius: 2,
                    borderRight: 1,
                    borderLeft: 1,
                    borderBottom: 1,
                    borderColor: "#dee2e3",
                    margin: 'auto',
                    width:"98.5%"
                  }}
                >
                  <Table className={classes.table}>
                    <LeaveDayListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={listApprove?.listItem?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      onChange={getAllApprove}
                      page={page}
                      limit={limit}
                      listRole={listRole}
                    />
                    <TableBody>
                      {listApprove?.listItem?.map((row) => {
                        const { _id, userId, date, content, note } =
                          row;
                        const selectedApprove = selected.indexOf(_id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedApprove}
                            sx={{ cursor: "pointer"}}
                          >
                            <TableCell padding="checkbox">
                              {listRole.includes('quan-ly-phe-duyet-cap-nhat') ? <Checkbox
                                checked={selectedApprove}
                                onChange={(event) => handleClick(event, _id)}
                              /> : ""}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: 250, whiteSpace: "pre-line" ,fontWeight: 700}}
                              onClick={(event) => handleClick(event, _id)}
                            >
                              {userId?.fullname}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: 250, whiteSpace: "pre-line" }}
                              onClick={(event) => handleClick(event, _id)}

                            >
                              <DateTimeOfDay dateTime={date} />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: 250, whiteSpace: "pre-line" }}
                              onClick={(event) => handleClick(event, _id)}
                            >
                              {content}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: 250, whiteSpace: "pre-line" }}
                              onClick={(event) => handleClick(event, _id)}
                            >
                              {note}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {listApprove?.listItem?.length !== 0 ?
                  <TablePagination
                  sx={{ float: "left" }}
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={countPage}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Số hàng mỗi trang"
                  />
                  : <Box sx={{ marginTop: '40px', marginLeft: '40%', fontSize: '20px', height: '100px',color:localStorage.getItem("theme")==="dark"?"white":'black' }}>Danh sách trống</Box>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff",background:'rgba(0, 0, 0, 0)',width:"50%",margin:'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

export default LeaveDay;

// @mui
import { styled } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
// components
import Logo from "./../../utils/Logo/Logo";
// sections
import { LoginForm } from "./components/LoginForm";
import { Helmet } from "react-helmet";
import bg from "./../../bg-login.jpeg";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
	backgroundImage: `url(${bg})`
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
	padding: "30",
  minHeight: "100vh",
  fontSize: "2",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
	borderRadius: "12px",
	background: "#fff"
}));

// ----------------------------------------------------------------------

export default function LoginPage() {

  return (
    <>
      <Helmet>
      <title> Login | Project Management </title>
      </Helmet>
      
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography
              align="center"
							marginBottom={3}
            >
              <Logo sx={{
								height:150
							}}/>
            </Typography>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

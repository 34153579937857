import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { useState } from "react";
import { toast } from "react-toastify";
import useValidator from "../../../../utils/Validator";
import { useEffect } from "react";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";


const styleInputFullField = {
  width: "100%",
  mb: 3,
  ml: 2,
};



const LayoutFormTwoField = ({ children }) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%", mt: 1 }}>
      {children}
    </Grid>
  );
};
const CustomButton = styled
  (Button)(({ theme }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));


export const DialogEditUser = ({ open, setOpen, user, onChange, getOneUser, _id }) => {
  const { validate } = useValidator();
  const [userEdit, setUserEdit] = useState(user);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogResetPassword, setOpenDialogResetPassword] =
    React.useState(false);
  const [listPosition, setListPosition] = useState([]);
  const [index, setIndex] = useState();

  const [showLoading, setShowLoading] = useState(false);

  let notePassword = "Password: ";
  let newPass = "12345";

  const [errors, setErrors] = useState({
    email: "",
    fullname: "",
    position: "",
  });
  const [inputValues, setInputValues] = useState(user);

  useEffect(() => {

    getAllPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getAllPosition = async () => {
    setShowLoading(() => true);
    const response = await Axios.Position.getAll(0, 100);
    if (response.status === 200) {
      try {

        setShowLoading(() => false);
        let arr = [];
        setListPosition(response.data.listItem);

        response.data.listItem.forEach((element) => {
          arr.push(element.positionName);
        });

        setIndex(arr.indexOf(user?.positionId?.positionName));
      } catch (error) { }


    } else {
      setShowLoading(() => false);
    }
  }

  const handleCloseDialogResetPassword = () => {
    setOpenDialogResetPassword(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClickOpenResetPassword = () => {
    setOpenDialogResetPassword(true);
  };

  const updateUser = async () => {
    setShowLoading(() => true);
    const response = await Axios.User.update(userEdit._id, userEdit);
    if (response.status === 200) {
      setShowLoading(() => false);
      setOpenDialog(false);
      toast.success("Cập nhật người dùng thành công");
      setOpen(false);
      if (_id === undefined) {
        onChange(0, 10, -1, "");
      } else {
        getOneUser(_id);
      }
    } else {
      setShowLoading(() => false);
      setOpenDialog(false);
      setErrors({
        email: "Email đã tồn tại!",
      });
    }
  };

  const resetPassword = async () => {
    setShowLoading(() => true);
    const response = await Axios.Profile.resetPassword({ id: userEdit._id });
    if (response.status === 200) {
      setShowLoading(() => false);
      setOpenDialogResetPassword(false);

      toast.success("Reset password thành công");
      setOpen(false);
    } else {
      setShowLoading(() => false);
      toast.error("Reset password thất bại!");
    }
  };



  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validate(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      handleClickOpen();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validate(name, value) });
  };


  const handleChange = (e, value) => {
    setUserEdit({
      ...userEdit,
      positionId: value?._id,
    })
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800, }}>Chỉnh sửa tài khoản</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <LayoutFormTwoField>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    component="div"
                    name="email"
                    label="Email"
                    value={userEdit?.email}
                    variant="standard"
                    placeholder="Nhập email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"entypo:mail"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUserEdit({ ...userEdit, email: e.target.value });
                    }}
                    autoComplete="none"
                    sx={styleInputFullField}
                    error={errors.email ? true : false}
                    helperText={
                      errors.email ? (
                        errors.email
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="fullname"
                    label="Họ Và Tên"
                    value={userEdit?.fullname}
                    placeholder="Nhập Họ Và Tên"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={"icon-park-solid:edit-name"} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUserEdit({ ...userEdit, fullname: e.target.value });
                    }}
                    autoComplete="none"
                    sx={styleInputFullField}
                    error={errors.fullname ? true : false}
                    helperText={
                      errors.fullname ? (
                        errors.fullname
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &nbsp;
                        </Box>
                      )
                    }
                    onInput={handleOnInput}
                  />
                </Grid>
              </LayoutFormTwoField>
              <LayoutFormTwoField>
                <Grid item xs={12} sx={{ mt: -5 }}>
                  {listPosition.length > 0 &&
                    <Autocomplete
                      name="position"
                      options={listPosition}
                      defaultValue={listPosition[index]}
                      getOptionLabel={(option) => option?.positionName}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          name="position"
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"bx:code-block"} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          variant="standard"
                          label="Phân quyền"
                          placeholder="Chọn phân quyền"
                          sx={styleInputFullField}
                          error={errors.position ? true : false}
                          helperText={
                            errors.position ? (
                              errors.position
                            ) : (
                              <Box component="span" sx={{ color: "white" }}>
                                &nbsp;
                              </Box>
                            )
                          }
                        />
                      )}

                    />
                  }
                </Grid>
              </LayoutFormTwoField>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton
              sx={{ background: "green" }}
              onClick={handleClickOpenResetPassword} variant="contained">Reset password</CustomButton>
            <CustomButton type="submit" variant="contained">Cập nhật</CustomButton>
            <CustomButton
              sx={{ background: "#f72533" }}
              onClick={handleClose} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận cập nhật?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                sx={{ fontSize: 20, fontFamily: "bold" }}
              >
                <span style={{ fontWeight: "bold", }}></span>{" "}
                ( {inputValues.email} )
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={updateUser}>Xác nhận</CustomButton>
            <CustomButton onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
              Huỷ
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
      {/* resetPassword */}
      <div>
        <Dialog
          open={openDialogResetPassword}
          onClose={handleCloseDialogResetPassword}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận"} <span style={{ color: "black", fontWeight: "bold" }}>
                {notePassword}{" "}
              </span>
              <span style={{ color: "red", fontWeight: "bold" }}>
                {" "}
                {newPass}
              </span>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                sx={{ fontSize: 20, fontFamily: "bold", }}
              >
                ( {inputValues.email} )
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={resetPassword}>Xác nhận</CustomButton>
            <CustomButton
              onClick={handleCloseDialogResetPassword}
              sx={{ color: 'white', background: 'red' }}
              autoFocus
            >
              Huỷ
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

import Axios from "./../../../../utils/Axios/Axios";

class TaskProject {

  static getListTaskStatus = async (id) =>
   Axios.get(`/api/v1/task/getListTaskStatus/${id}`);

  static createTaskStatus = async (id,data) => Axios.post(`/api/v1/task/createTaskStatus/${id}`, data);

  static createTask = async (id,data) => Axios.post(`/api/v1/task/createTask/${id}`, data);

  static deleteTaskStatus = async (id) => Axios.delete(`/api/v1/task/deleteTaskStatus/${id}`);

  static updateTaskStatus = async (id,data) => Axios.put(`/api/v1/task/updateTaskStatus/${id}`, data);

  static updateTask = async (id,data) => Axios.put(`/api/v1/task/updateTask/${id}`, data);

  static deleteTask = async (id) => Axios.delete(`/api/v1/task/deleteTask/${id}`);

  static changeStatus = async (data) => Axios.put(`/api/v1/task/changeStatus`, data);

  static addUserToTask = async (data) => Axios.put(`/api/v1/task/addUserToTask`, data);


  static removeUserFromTask = async (data) => Axios.put(`/api/v1/task/removeUserFromTask`,data);

  static removeTimer = async (id) => Axios.put(`/api/v1/task/removeTimer/${id}`);


}

export default TaskProject;
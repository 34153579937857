/* eslint-disable no-empty-pattern */
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import { toast } from "react-toastify";
  import Iconify from "../../components/iconify/Iconify";
  
  import useValidator from "../../utils/Validator";
  import Axios from "../../utils/Axios";
  import useLogin from "../../utils/Login/useLogin";
  import { Helmet } from "react-helmet";
  import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
  
  
  const CustomButton = styled
    (Button)(({  }) => ({
      backgroundColor: '#5351ed', // màu nền button
      color: 'white', // màu chữ button
      fontWeight: 'bold',
      borderRadius: 15,
      marginBottom: 18,
      paddingLeft: 30,
      paddingRight: 30,
      marginTop: 5,
      '&:hover': {
        backgroundColor: '#f7f9fa', // màu nền khi hover vào button
        color: 'red', // màu chữ khi hover vào button
      },
    }));
  
  const styleInputFullField = {
    width: "100%",
    mb: 3,
    mr: 3,
  };
  
  export default function ChangePassword(props) {
    const { account, logout } = useLogin();

  const userDetail = useSelector((state) => state.userLoginDetail.data);

  
    const { validate } = useValidator();
  
    const [showNewPassword, setNewShowPassword] = useState(false);
  
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  
    const [openDialog, setOpenDialog] = React.useState(false);
  
    const [showLoading, setShowLoading] = useState(false);
  
    const handleClickOpen = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    const [inputValues, setInputValues] = useState({
      newPassword: "",
      confirmpassword: "",
    });
  
    const [user, setUser] = useState({
      newPassword: "",
      confirmPassword: "",
    });
  
    const [errors, setErrors] = useState({
      newPassword: "",
      confirmPassword: "",
    });
  
    const handleOnInput = (event) => {
      const { name, value } = event.target;
      setInputValues({ ...inputValues, [name]: value });
      setErrors({ ...errors, [name]: validate(name, value) });
    };
  
    const handleOnSubmit = (e) => {
      e.preventDefault();
      let target = Array.from(e.target);
      let validForm = true;
      let error = {};
      target.forEach((data) => {
        if (data.name) {
          let err = validate(data.name, data.value);
          if (err) {
            error[data.name] = err;
            validForm = false;
          }
        }
      });
      setErrors(() => ({ ...errors, ...error }));
  
      if (validForm) {
        if (user.newPassword !== user.confirmPassword) {
          setErrors({
            confirmpassword: "Mật khẩu mới không khớp!",
          });
          return;
        } else {
          handleClickOpen();
        }
      } else {
        toast.error("Vui lòng điền đầy đủ thông tin!");
      }
    };
  
    const updatePassword = async () => {
      setShowLoading(() => true);
      const response = await Axios.User.changePassword({
        newPassword: user.newPassword,
      });
      if (response.status === 200) {
        setShowLoading(() => false);
        toast.success("Đổi mật khẩu thành công");
        setOpenDialog(false);
        localStorage.removeItem("account");
        setTimeout(() => {
          logout();
        }, 1500);
      } else {
        setShowLoading(() => false);
        toast.error("Đổi mật khẩu thất bại!");
      }
    };
  
    return (
      <>
        <Grid container  sx={{mt:24}}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <form onSubmit={handleOnSubmit}>
                  <Helmet>
                    <title> Đổi mật khẩu </title>
                  </Helmet>
  
                  <Box
                    margin="auto"
                    position="relative"
                    width="100%"
                    height="100%"
                    boxShadow={3}
                    borderRadius={3}
                    padding={3}
                    maxWidth={600}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: "bold", fontSize: "20" }}>
                        Đổi mật khẩu
                      </Typography>
                      <Box sx={{ textAlign: "center", mt: 3 }}>
                        <TextField
                          name="fullname"
                          label="Họ Và Tên"
                          disabled
                          value={userDetail?.fullname?userDetail?.fullname:''}
                          variant="standard"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"icon-park-solid:edit-name"} />
                              </InputAdornment>
                            ),
                          }}
                          autoComplete="none"
                          sx={styleInputFullField}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <TextField
                        name="newpassword"
                        label="Mật khẩu mới"
                        type={showNewPassword ? "text" : "password"}
                        placeholder="Nhập mật khẩu mới"
                        onChange={(e) => {
                          setUser({ ...user, newPassword: e.target.value });
                        }}
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"material-symbols:password"} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setNewShowPassword(!showNewPassword)}
                                edge="end"
                              >
                                <Iconify
                                  icon={
                                    showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="none"
                        error={errors.newpassword ? true : false}
                        helperText={errors.newpassword}
                        onInput={handleOnInput}
                        // autoFocus
                        sx={styleInputFullField}
                      />
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <TextField
                        name="confirmpassword"
                        label="Nhập lại mật khẩu mới"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Nhập lại mật khẩu mới"
                        onChange={(e) => {
                          setUser({ ...user, confirmPassword: e.target.value });
                        }}
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"material-symbols:password"} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setConfirmShowPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                <Iconify
                                  icon={
                                    showConfirmPassword
                                      ? "eva:eye-fill"
                                      : "eva:eye-off-fill"
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={styleInputFullField}
                        autoComplete="none"
                        error={errors.confirmpassword ? true : false}
                        helperText={errors.confirmpassword}
                        onInput={handleOnInput}
                      // autoFocus
                      />
                    </Box>
                    <Box textAlign={"right"}>
                      <Button type="submit" variant="contained" color="warning">
                        Đổi mật khẩu
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle width={400} id="alert-dialog-title">
              <Typography
                component="div"
                sx={{ fontSize: 20 }}
                variant="subtitl1"
              >
                {"Xác nhận đổi mật khẩu?"}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  component="span"
                  sx={{ fontSize: 20, fontFamily: "bold" }}
                >
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {/* {numSelected} */}
                  </span>{" "}
                  {account.fullname}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={updatePassword}>Xác nhận</CustomButton>
              <CustomButton onClick={handleCloseDialog} sx={{ background: "red" }} autoFocus>
                Huỷ
              </CustomButton>
            </DialogActions>
          </Dialog>
        </div>
        <Backdrop
          sx={{ color: "#fff",background:'rgba(0, 0, 0, 0)',width:"50%",margin:'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoading}
        >
          <CircularProgress color="primary" sx={{ zIndex: 9 }} />
        </Backdrop>
      </>
    );
  }
  

export default function DateTimeSaleProject({ dateTime }) {
  if(dateTime === null){
  return <span>{""}</span>;
  }else{
    let today = new Date(dateTime);
    let day = today.getDate();
    if(day < 10){
      day = "0"+day;
    }

    let month = today.getMonth()+1
    if(month < 10){
      month = "0"+month;
    }

    let date =
      day +
      "-" +
      month +
      "-" +
      today.getFullYear() ;

  return <span>{date}</span>;
  }
   
}

import { Grid, TextField } from "@mui/material";
import Iconify from "../../../../iconify";

export default function SearchInput({ sx, ...props }) {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container >
            <Grid item xs={6} sm={6} md={12}>
              <TextField
                size="medium"
                InputProps={{
                  endAdornment: (
                    <Iconify
                      icon="ic:baseline-search"
                      width={36}
                      sx={{ mr: 1, color: "grey"}}
                    />
                  ),

                }}
                placeholder="Tìm kiếm..."
                sx={{
                  mt: 1.5,
                  width: "100%",
                  "&:hover": {
                    "& .MuiInputLabel-root": {

                    },
                    "& .MuiInputBase-root": {
                      "& > fieldset": {
                        borderColor: "rgb(162, 205, 150)",
                      },

                    },
                  },
                  "& .Mui-focused": {
                    "&.MuiInputLabel-root": {
                      // color: "red",
                    },
                    "&.MuiInputBase-root": {
                      "& > fieldset": {
                        // borderColor: "red !important",
                      },

                    },
                  },
                  "&.rounded .MuiInputBase-root": {
                    paddingRight: 1.5,
                   
                  },
                  "&.input-24 input": {
                    fontSize: "18px",
                    
                  },
                  pr: 1,
                  ...sx,
                }}
                {...props}
                className="cardSearch"

              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import logo from "./../../logo.jpg"
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (
        <>
         <img src={logo} alt="Siginx" width="110" />
         </>
    );
};

export default Logo;

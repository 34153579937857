import {  Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


export default function ProjectSaleSearchBox({ searchData,setOpen }) {
  const { _id, companyName,phoneNumber } = searchData;
  const customization = useSelector((state) => state.customization);


  return (
    <>
      <Link
        to={`/saleProjectDetailsPage/${_id}`}
        style={{ textDecoration: "none" }}
        className={customization.mode}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
          className="searchBox"
          onClick={(event) => setOpen(false)}
        >
          <Box display={"flex"} maxWidth="70%">
            <Box sx={{ maxWidth: "99%", ml: 2 }}>
              <Typography
                sx={{ fontWeight:600 }}
                variant="subtitle2"
                noWrap
                fontSize={16}
              >
                {companyName}
              </Typography>

              <Typography variant="body3" noWrap>
                <Box display={"flex"} maxWidth="150%">
                  <Box sx={{ maxWidth: "135%", }}>
                    <Typography
                      noWrap
                      fontSize={16}
                    >
                    <span style={{ fontWeight:'bold'}}>{phoneNumber}</span>
                    </Typography>

                  </Box>
                </Box>

              </Typography>
            </Box>
          </Box>
        </Box>
      </Link>
    </>
  );
}

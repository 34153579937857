import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "../../components/iconify";

import useValidator from "../../utils/Validator";
import Axios from "../../utils/Axios";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import SearchInput from "../../components/views/sections/@dashboard/search/SearchInput";
import UserSearchBox from "../../components/views/sections/@dashboard/search/UserSearchBox";
import { DialogEditUser } from "../../components/views/userManagement/components/DialogEditUser";
import { styled } from "@mui/material/styles";
import "./indx.scss"
import useLogin from "../../utils/Login/useLogin";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addTodo } from "../../utils/store/actions";

// import useValidator from "../../../utils/Validator";
const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));

const styleInputFullField = {
  width: "100%",
  mb: 3,
  mr: 3,
};

const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#f7ebf5",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#f7ebf5",
  },
};

export default function Profile({ listRoleProfile }) {

  const { _id } = useParams();
  const [listRole, setListRole] = useState(listRoleProfile);
  const { account } = useLogin();

  const { validate } = useValidator();
  const [userEdit, setUserEdit] = useState({});
  const [searchList, setSearchList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isFocusPopup, setFocusPopup] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.userLoginDetail.data);


  useEffect(() => {
    setListRole(listRoleProfile);
  }, [listRoleProfile]);


  useEffect(() => {
    getOneUser(_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const getOneUser = async (_id) => {
    if (_id !== undefined) {
      if (_id === account._id) {
        setShowLoading(() => true);
        const userDetail = await Axios.User.getUserDetail();
        if (userDetail.status === 200) {
          setShowLoading(() => false);
          setInputValues(userDetail.data);
          setUserEdit(userDetail.data);
        } else {
          setShowLoading(() => false);
        }
      } else {
        setShowLoading(() => true);
        const response = await Axios.User.getOne(_id);
        if (response.status === 200) {
          setShowLoading(() => false);
          setInputValues(response.data);
          setUserEdit(response.data);
        } else {
          setShowLoading(() => false);
        }
      }
    }
  };

  const handleClickOpen = () => {
    if (_id === account._id) {
      setOpenDialog(true);
    } else {
      setIsEdit(true)

    }

  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [inputValues, setInputValues] = useState({
    email: "",
    fullname: "",
  });

  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validate(name, value) });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validate(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      handleClickOpen();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };
  const updateUserLogin = async () => {
    setShowLoading(() => true);
    const response = await Axios.User.updateUserLogin(userEdit);
    if (response.status === 200) {
      setShowLoading(() => false);
      getOneUser(_id);
      let data = userDetail;
      data.fullname = userEdit.fullname;
      dispatch(addTodo(data))
      setOpenDialog(false);
      toast.success("Cập nhật thông tin thành công");
    } else {
      setShowLoading(() => false);
      setOpenDialog(false);
    }
  };

  const handleClose = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
    }
  };
  const handleClose2 = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
      setFocusPopup(false);
    } else {
      setFocusPopup(false);
    }
  };

  const searchAllUser = async () => {
    const response = await Axios.User.getAll(0, 20, -1, "", "");
    if (response.status === 200) {
      setSearchList(response.data.listItem);
    }
  };

  const handleSearch = async (e) => {
    const response = await Axios.User.getAll(
      0,
      20,
      -1,
      e.target.value,
      ""
    );
    setSearchList(response.data.listItem);
  };

  const handleOpen = (event) => {
    searchAllUser();
    setOpen(event.currentTarget);
  };

  return (
    <>

      <Helmet>
        <title> Thông tin cá nhân </title>
      </Helmet>
      <div style={{ width: '100%' }}>

        <form onSubmit={handleOnSubmit} style={{ marginTop: '65px' }}>
          {listRole.includes('quan-ly-nguoi-dung') ? <Box onFocus={handleOpen} onBlur={handleClose}>
            <SearchInput className="searchInput" position="start" onInput={handleSearch} sx={{ mt: 5 }} />
            <Card
              className="cardSearch"
              hidden={!open}
              sx={{
                position: "fixed",
                p: 0,
                borderRadius: 2,
                boxShadow: "0 0 8px #976235",
                maxHeight: "45vh",
                zIndex: 99999,

              }}
            >
              <Box
                onBlur={handleClose}
                onMouseEnter={() => setFocusPopup(true)}
                onMouseLeave={handleClose2}
              >
                <CardHeader
                  title={
                    <Typography fontWeight={600} fontSize={16}>
                      {"Danh sách tìm kiếm"}
                    </Typography>
                  }
                  sx={{ pb: 2, height: 24 }}
                />
                <Divider />

                <CardContent
                  sx={{
                    p: "0 !important",
                    maxHeight: "calc(40vh - (14*2px))",
                    overflow: "auto",
                    ...scrollbar,
                  }}
                >
                  {searchList.length !== 0 ? "" : <span style={{ pb: 2, marginLeft: '5%' }}>( Thông tin tìm kiếm không có! )<p></p></span>}
                  {searchList?.map((searchData, index) => (
                    <UserSearchBox key={index} searchData={searchData} setOpen={setOpen} />
                  ))}
                </CardContent>
              </Box>
            </Card>
          </Box> : ""}

          <p></p>
          <p>&nbsp;</p>
          <Grid container sx={{ width: '90%', margin: 'auto' }}>
            <Grid item xs={12}>
              <Grid container >
                <Grid item xs={12} sm={6} md={12}>
                  <Box
                    margin="auto"
                    position="relative"
                    width="100%"
                    height="100%"
                    boxShadow={3}
                    borderRadius={3}
                    padding={3}
                    maxWidth={600}
                  >
                    <Box>
                      <Typography
                        component="div"
                        sx={{ fontWeight: "bold", fontSize: "20", color: localStorage.getItem("theme") === "dark" ? "white" : 'black' }}
                      >
                        Thông tin cá nhân
                      </Typography>
                      <Box sx={{ textAlign: "center", mt: 3 }}>
                        <TextField
                          disabled
                          component="div"
                          name="email"
                          label="Email"
                          value={inputValues?.email}
                          variant="standard"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"ic:outline-email"} />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setUserEdit({ ...inputValues, email: e.target.value });
                          }}
                          autoComplete="none"
                          sx={styleInputFullField}
                          error={errors.email ? true : false}
                          helperText={
                            errors.email ? (
                              errors.email
                            ) : (
                              <Box component="span" sx={{ color: "white" }}>
                                &nbsp;
                              </Box>
                            )
                          }
                          onInput={handleOnInput}
                        />
                      </Box>
                      <Box sx={{ textAlign: "center", mt: 3 }}>
                        <TextField
                          disabled={account._id !== _id}
                          component="div"
                          name="fullname"
                          label="Họ Và Tên"
                          value={inputValues?.fullname}
                          variant="standard"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"icon-park-solid:edit-name"} />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setUserEdit({ ...inputValues, fullname: e.target.value });
                          }}
                          autoComplete="none"
                          sx={styleInputFullField}
                          error={errors.fullname ? true : false}
                          helperText={
                            errors.fullname ? (
                              errors.fullname
                            ) : (
                              <Box component="span" sx={{ color: "white" }}>
                                &nbsp;
                              </Box>
                            )
                          }
                          onInput={handleOnInput}
                        />
                      </Box>
                    </Box>
                    <Grid item xs={6}>
                      {account._id === _id ?
                        <CustomButton type="submit" >
                          Cập nhật tài khoản
                        </CustomButton>
                        : ""}
                    </Grid>
                    <Grid item xs={6}>
                      {listRole.includes('quan-ly-nguoi-dung-cap-nhat') & account._id !== _id ?
                        <CustomButton onClick={() => setIsEdit(true)} >
                          Chỉnh sửa
                        </CustomButton>
                        : ""}
                    </Grid>

                  </Box>
                </Grid></Grid></Grid></Grid>

        </form>
      </div>
      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 17 }}
              variant="subtitl1"
            >
              {"Xác nhận cập nhật?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                sx={{ fontSize: 20, fontFamily: "bold" }}
              >
                <span style={{ color: "red", fontWeight: "bold" }}></span>{" "}
                {inputValues.email}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={updateUserLogin}>Xác nhận</CustomButton>
            <CustomButton onClick={handleCloseDialog} sx={{ background: 'red' }} autoFocus>
              Huỷ
            </CustomButton>
            &emsp;
          </DialogActions>
        </Dialog>
      </div>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
      {isEdit &&
        <DialogEditUser
          // key={1}
          open={isEdit}
          setOpen={setIsEdit}
          user={userEdit}
          getOneUser={getOneUser}
          _id={_id}
        />
      }
    </>
  );
}

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import React, { useEffect, useState } from "react";
import useValidator from "../../../../utils/Validator";
import { toast } from "react-toastify";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";

const styleInputFullField = {
  width: "100%",
  mt: 2,
};

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));


export const DialogCreateWorkProject = ({ open, setOpen, onChange, page, limit }) => {
  const { validateWorkProject } = useValidator();

  const [workCreate, setWorkCreate] = useState({
    name: "",
    description: "",
  });

  const [showLoading, setShowLoading] = useState(false);
  const [listUser, setListUser] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [listUserId, setListUserId] = useState([]);


  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({
    name: "",
    description:"",
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllUser(0,1000)
    setErrors({});
  }, [])

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateWorkProject(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      createWorkProject();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };


  const createWorkProject = async () => {
    setShowLoading(() => true);
    workCreate.listUserId = listUserId;
    let data = workCreate;
    const response = await Axios.WorkProject.createWorkProject(data);
    if (response.status === 200) {
      setShowLoading(() => false);
      handleReset();
      toast.success("Tạo dự án thành công");
      setOpen(false);
      onChange(page, limit, -1, "");
    } else {
      setErrors({
        name: "Tên dự án đã tồn tại!",
      });
      toast.error("Tạo dự án thất bại!");
      setShowLoading(() => false);
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateWorkProject(name, value) });
  };

  const handleReset = () => {
    setSelectedValue([]);
    setWorkCreate({
      name: "",
      description: "",
    });
    setErrors({});
  };


  const handleChangeListUser = (target) => {
    let arr = [];
        target.forEach(element => {
            let id = element.split("_")[0];
            arr.push(id)
        });
        if (arr.length > 0) {
            setErrors({})
        }
        setListUserId(arr);
  };

  const getAllUser = async (page, limit, sortOrder, property) => {
    setShowLoading(() => true);
    const response = await Axios.User.getAll(
      page,
      limit,
      sortOrder,
      "",
      property
    );

    if (response.status === 200) {
      setShowLoading(() => false);
      setListUser(response.data.listItem);
    } else {
      setShowLoading(() => false);
      if (response.status === 403) {
        toast.error("Không có quyền xem!");
      } else {
        toast.error("Lấy danh sách người dùng thất bại!");
      }

    }
  };


  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle
            sx={{  fontSize: 20.28, textAlign: 'center', fontWeight: 800, }}
          >
            Tạo Dự Án
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ width: 800 }}>
              <Box tag={"p"} sx={{ mt: 2, display: "flex", width: "100%" }}>
                <Box sx={{ width: "100%", ml: 1 }}>
                    <TextField
                      component="div"
                      name="name"
                      label="Tên Dự Án"
                      value={workCreate?.name}
                      multiline
                      maxRows={5}
                      onChange={(e) =>
                        setWorkCreate({
                          ...workCreate,
                          name: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tên Dự Án?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.name ? true : false}
                      helperText={
                        errors.name ? (
                          errors.name
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                        {
                          ...styleInputFullField,
                          ml: 2,
                          width: "90%",
                          fontFamily: "inherit",
                        })
                      }
                    />
                </Box>

                <Box sx={{ width: "100%" }}>
                  {/* {isMultilinedescription === false ? ( */}
                    <TextField
                      component="div"
                      label="Nội Dung"
                      multiline
                      maxRows={5}
                      value={workCreate?.description}
                      onChange={(e) =>
                        setWorkCreate({
                          ...workCreate,
                          description: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Nội Dung?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                </Box>
              </Box>
              <Box sx={{width:'100%'}}>
                <Autocomplete
                  multiple
                  options={listUser.map((option) => option._id + '_' + option.fullname + ' - ( ' + option.email + ' )')}
                  value={selectedValue}
                  freeSolo
                  renderOption={(props, option) => {
                    const parts = option.split("_"); // Tách theo dấu "_"
                    // const _id = parts[0];

                    const nameAndEmail = parts[1];

                    const nameAndEmailParts = nameAndEmail.split("-"); // Tách theo dấu "-"
                    const fullname = nameAndEmailParts[0]; // Chuỗi name là phần tử đầu tiên trong mảng nameAndEmailParts
                    const email = nameAndEmailParts[1];
                    return (
                      <li style={{ color: 'blue', fontSize: '14px' }} {...props}>
                        {fullname} &nbsp;
                        <span style={{ color: 'black' }}>{email}</span>
                        <p></p>
                      </li>

                    );
                  }}
                  onChange={(event, newValue) => {
                    setSelectedValue(newValue);
                    handleChangeListUser(newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.split("_")[1]} {...getTagProps({ index })} color="default" />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Thêm thành viên"
                      sx={{ ml: 3.5, width: '95%', }}

                      onInput={handleOnInput}
                    />
                  )}

                  sx={{ width: '100%' }}
                />
              </Box>

            </Grid>

          </DialogContent>

          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton type="submit" variant="contained">Thêm mới</CustomButton>
            <CustomButton onClick={handleClose} sx={{ background: "#f72533" }} variant="contained">Hủy</CustomButton>

          </DialogActions>
        </form>
      </Dialog >
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { useState } from "react";
import { toast } from "react-toastify";
import useValidator from "../../../../utils/Validator";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";



const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));

const styleInputFullField = {
  width: "100%",
  mb: 3,
};

export const DialogEditWorkProject = ({
  open,
  setOpen,
  project,
  getAllWorkProject,
  getOneProject,
}) => {

  const { validateCreateProject } = useValidator();

  const [projectEdit, setProjectEdit] = useState(project);

  const [showLoading, setShowLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const updateWork = async () => {
    setShowLoading(() => true);
    let data = projectEdit;

    const response = await Axios.WorkProject.updateWorkProject(project._id, data);
    if (response.status === 200) {
      setShowLoading(() => false);
      handleReset();
      handleCloseDialog();
      toast.success("Cập nhật dự án thành công");
      setOpen(false);
      if (getOneProject === undefined) {
        getAllWorkProject(0, 10, -1, "");
      } else {
        getOneProject(project._id);
      }
    } else {
      setErrors({
        name: "Tên dự án đã tồn tại!",
      });
      toast.error("Cập nhật dự án thất bại!");
      setShowLoading(() => false);
    }
  };

  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({
    name: "",
    description: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateCreateProject(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      handleClickOpen();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateCreateProject(name, value) });
  };

  const handleReset = () => {
    setProjectEdit({
      name: "",
      description: "",
    });
    setErrors({});
  };



  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000" >
        <form onSubmit={handleOnSubmit}>
          <DialogTitle
            sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800,}}
          >
            Cập Nhật Dự Án
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ width: 800 }}>
              <Box sx={{ mt: 1, display: "flex", width: "100%" }}>
                <Box sx={{ width: "100%", ml: 1 }}>
                  <TextField
                    component="div"
                    name="NameOfProject"
                    label="Tên Dự Án"
                    multiline
                    maxRows={5}
                    value={projectEdit?.name}
                    onChange={(e) =>
                      setProjectEdit({
                        ...projectEdit,
                        name: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Tên Dự Án?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    error={errors.NameOfProject ? true : false}
                    helperText={
                      errors.NameOfProject ? (
                        errors.NameOfProject
                      ) : (
                        <Box component="span" sx={{ color: "white" }}>
                          &emsp;
                        </Box>
                      )
                    }
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    onInput={handleOnInput}
                  />
                </Box>

                <Box sx={{ width: "100%" }}>
                  <TextField
                    multiline
                    maxRows={5}
                    component="div"
                    label="Nội Dung"
                    value={projectEdit?.description}
                    onChange={(e) =>
                      setProjectEdit({
                        ...projectEdit,
                        description: e.target.value,
                      })
                    }
                    variant="standard"
                    placeholder="Nội Dung?"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={"material-symbols:edit-note-sharp"}
                          />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="none"
                    sx={
                      (styleInputFullField,
                        { ...styleInputFullField, ml: 2, width: "90%" })
                    }
                    onInput={handleOnInput}
                  />
                </Box>
              </Box>

            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            <CustomButton
              type="submit"
              variant="contained"> Cập Nhật</CustomButton>
            <CustomButton
              sx={{ background: "#f72533" }}
              onClick={handleClose} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận cập nhật?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                sx={{ fontSize: 20, fontFamily: "bold" }}
              >
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {/* {numSelected} */}
                </span>{" "}
                {/* {nameWork} */}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={updateWork}>Xác nhận</CustomButton>
            <CustomButton onClick={handleCloseDialog} sx={{ background: 'red' }} autoFocus>
              Huỷ
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>

      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
};

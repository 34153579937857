import './App.css';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import themes from "./utils/Theme/theme";
import Route from './routes';
import { ToastContainer } from 'react-toastify';
import useLogin from './utils/Login/useLogin';
import { useEffect } from 'react';
import Axios from "./utils/Axios";
import { addTodo } from './utils/store/actions';
import "./index.scss"
import 'react-toastify/dist/ReactToastify.css';


function App() {
    const { account } = useLogin();
    const dispatch = useDispatch();
    useEffect(() => {
        if (account && account?.status === true) {
            getUserDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserDetail = async () => {
        const response = await Axios.User.getUserDetail();
        if (response.status === 200) {
            dispatch(addTodo(response.data));
        } else {
            if (response.status === 400) {
                localStorage.removeItem("account");
                window.location = "/login"
            }
        }
    }


    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <Route />
                <ToastContainer autoClose={600} />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
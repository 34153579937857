import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import Iconify from "../../components/iconify/Iconify";
import { DialogDetail } from "./components/DialogDetail";
import Axios from "../../utils/Axios";
import { toast } from "react-toastify";
import DateTimeStartEnd from "../../utils/DateTimeOfMessage/DateTimeStartEnd";

export default function Task(props) {
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [task, setTask] = useState();
  const [openDialog, setOpenDialog] = React.useState(false);

  // const [idTask, setIdTask] = useState();
  const [open, setOpen] = useState(null);
  const [taskDetail, setTaskDetail] = useState();
  const [showIcon, setShowIcon] = useState(false);

  let backgroundLabel;
  let checkBackgroundLabel = props?.task?.priority;
  if (checkBackgroundLabel === "1") {
    backgroundLabel = "#ff1744";
  }
  if (checkBackgroundLabel === "2") {
    backgroundLabel = "#ffea00";
  }
  if (checkBackgroundLabel === "3") {
    backgroundLabel = "#2979ff";
  }
  if (checkBackgroundLabel === "4") {
    backgroundLabel = "#1de9b6";
  }

  let s = props?.task?.listUser[0]?.fullname;
  let words = s?.split(" ");
  let fullname = words?.slice(-2).join(" ");


  useEffect(() => {
    setTaskDetail(props.task);
    setTask(props.task);
  }, [props]);

  const handClick = () => {
    setOpenUpdate(true);
  };

  const handDetail = (data) => {
    setTaskDetail(data);
    setOpenDetail(true);
  };

  const updateTask = async (id) => {
    setOpenUpdate(false);
    if (Object.keys(task).length !== 0) {
      if (props.task.name !== task.name) {
        if (checkIfAllWhitespace(task.name) === false) {
          const response = await Axios.TaskProject.updateTask(id, task);
          if (response.status === 200) {
            props.getListTaskStatus();
          } else {
            toast.error("Cập nhật thất bại");
          }
        } else {
          setTask(props.task);
        }
      }
    }
  };

  function checkIfAllWhitespace(str) {
    return str.trim().length === 0;
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = (id, name) => {
    setOpenDialog(true);
  };

  // const deleteTask = async () => {
  //   const response = await Axios.TaskProject.deleteTask(idTask);
  //   if (response.status === 200) {
  //     props.getListTaskStatus();
  //     toast.success("Xoá thẻ thành công");
  //   } else {
  //     toast.error("Xoá thẻ thất bại");
  //   }
  // };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleMouseEnter = () => {
    setShowIcon(true);
  };

  const handleMouseLeave = () => {
    setShowIcon(false);
  };

  return (
    <>
      <Draggable draggableId={props.task._id} index={props.index} >
        {(provided) => (
        
          <div
            ref={provided?.innerRef}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                marginLeft: "0px",
                borderRadius: "25px",
                padding: "8px",
                marginBottom: "8px",
                boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.15)",
                background: "#ffffff",
                transition: "all 0.2s ease-in-out",
                display: "flex",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <form>
                {openUpdate === false ? (
                  <div
                    onClick={(e) => {
                      handDetail(props.task);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${
                          backgroundLabel ? backgroundLabel : "#fff"
                        }`,
                        marginLeft: "0px",
                        borderRadius: "35px",
                        background: `${
                          backgroundLabel ? backgroundLabel : "#fff"
                        }`,
                        width: "65px",
                        height: "10px"
                      }}
                    ></div>
                    <div
                      style={{
                        textAlign: "center",
                        marginLeft: "70px",
                        marginTop: "-18px",
                        width: "150px",
                        color: "red"
                      }}
                    >
                      {fullname?fullname:<p></p>}
                    </div>
                    <TextField
                      className="textField"
                      multiline
                      variant="standard"
                      value={taskDetail?.name}
                      InputProps={{
                        style: { color: "black" }
                      }}
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottom: "none"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottom: "none"
                        },
                        width: "93%",
                        ml: 1,
                        mb: 1
                      }}
                    />
                    {props.task.startDate === undefined &&
                    props.task.expireDate === undefined ? (
                      <Box
                        sx={{
                          textAlign: "center",
                          boxShadow: 1,
                          borderRadius: 5,
                          width: "210px"
                        }}
                      ></Box>
                    ) : (
                      <Box
                        sx={{
                          textAlign: "center",
                          boxShadow: 3,
                          background: "#00a152",
                          borderRadius: 5,
                          color: "#fff",
                          width: "210px",
                          padding: 0.5,
                          mt: -0.5,
                          ml: 1,
                          paddingBottom: 1,
                          marginBottom: 0.5
                        }}
                      >
                        <Iconify
                          icon={"mdi:clock-time-eight-outline"}
                          onClick={handClick}
                          sx={{
                            width: 15,
                            position: "relative",
                            top: "5px",
                            mr: 0.3
                          }}
                        />
                        <DateTimeStartEnd
                          start={props?.task?.startDate}
                          end={props?.task?.expireDate}
                        />
                      </Box>
                    )}
                  </div>
                ) : (
                  <TextField
                    onBlur={() => updateTask(props.task._id)}
                    variant="standard"
                    value={task?.name}
                    onFocus={(e) =>
                      e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length
                      )
                    }
                    InputProps={{
                      style: { color: "black" }
                    }}
                    multiline
                    sx={{ width: "222px" }}
                    inputRef={(input) => input && input.focus()}
                    onChange={(e) => {
                      setTask({ ...task, name: e.target.value });
                    }}
                  />
                )}
              </form>
              <span className="icon">
                {showIcon && openUpdate === false && (
                  <Iconify
                    onMouseEnter={handleMouseEnter}
                    icon={"material-symbols:edit-outline"}
                    sx={{
                      ml: -1.5,
                      mt: 1,
                      position: "sticky",
                      color: "black",
                      zIndex: 2,
                      cursor: "context-menu"
                    }}
                    onClick={handClick}
                  />
                )}
              </span>
            </div>
          </div>
        )}
      </Draggable>

      <DialogDetail
        open={openDetail}
        setOpen={setOpenDetail}
        taskDetail={taskDetail}
        getListTaskStatus={props}
        listUser={props.listUser}
      />

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle width={400} id="alert-dialog-title">
            <Typography
              component="div"
              sx={{ fontSize: 20 }}
              variant="subtitl1"
            >
              {"Xác nhận xoá?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ width: "95%" }}></Typography>
          </DialogContent>
          <DialogActions sx={{ mt: -3 }}>
            <Button
              sx={{
                backgroundColor: "#5351ed", // màu nền button
                color: "white", // màu chữ button
                fontWeight: "bold",
                borderRadius: 15,
                marginBottom: 1,
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 5,
                "&:hover": {
                  backgroundColor: "#f7f9fa", // màu nền khi hover vào button
                  color: "red" // màu chữ khi hover vào button
                }
              }}
              // onClick={deleteTask}
            >
              Xác nhận 123
            </Button>
            <Button
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: "red", // màu nền button
                color: "white", // màu chữ button
                fontWeight: "bold",
                borderRadius: 15,
                marginBottom: 1,
                paddingLeft: 3,
                paddingRight: 3,
                marginTop: 5,
                "&:hover": {
                  backgroundColor: "#f7f9fa", // màu nền khi hover vào button
                  color: "red" // màu chữ khi hover vào button
                }
              }}
            >
              Huỷ
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 155,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75
            }
          }
        }}
      >
        <MenuItem
          sx={{ color: "blue !important" }}
          onClick={(e) => {
            // eslint-disable-next-line no-unused-expressions
            handDetail(props.task) & handleCloseMenu();
          }}
        >
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Chỉnh sửa
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickOpen} sx={{ color: "red !important" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Xoá
        </MenuItem>
      </Popover>
    </>
  );
}

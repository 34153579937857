import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { useState } from "react";
import { toast } from "react-toastify";
import useValidator from "../../../../utils/Validator";
import { useEffect } from "react";
import Axios from "../../../../utils/Axios";
import { styled } from "@mui/material/styles";

// import useValidator from "../../../utils/Validator";
const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5351ed', // màu nền button
  color: 'white', // màu chữ button
  fontWeight: 'bold',
  borderRadius: 15,
  marginBottom: 18,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 5,
  '&:hover': {
    backgroundColor: '#f7f9fa', // màu nền khi hover vào button
    color: 'red', // màu chữ khi hover vào button
  },
}));


const styleInputFullField = {
  width: "100%",
  mb: 3,
};

export const DialogEditWork = ({
  open,
  setOpen,
  project,
  getAllWork,
  getOneProject,
  listRole
}) => {

  const { validateCreateProject } = useValidator();

  const [projectEdit, setProjectEdit] = useState(project);

  const [isMultilineName, setIsMultilineName] = useState(false);
  const [isMultilineIntermediary, setIsMultilineIntermediary] = useState(false);
  const [isMultilineProcessingTime, setIsMultilineProcessingTime] =
    useState(false);
  const [isMultilineTechnicalProgres, setIsMultilineTechnicalProgress] =
    useState(false);
  const [isMultilineIssue, setIsMultilineIssue] = useState(false);
  const [isMultilineInvestor, setIsMultilineInvestor] = useState(false);
  const [isMultilineInstallationProgress, setIsMultilineInstallationProgress] =
    useState(false);
  const [isMultilineSolution, setIsMultilineSolution] = useState(false);
  const [isMultilineProjectStatus, setIsMultilineProjectStatus] =
    useState(false);

  const [showLoading, setShowLoading] = useState(false);
  const [listContractStatus, setListContractStatus] = useState([]);
  const [indexListContact, setIndexListContact] = useState();

  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (listRole.includes('quan-ly-du-an-cap-nhat') === true) {
      getAllContactStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const getAllContactStatus = async () => {
    setShowLoading(() => true);
    const response = await Axios.Project.getAllContactStatus();

    if (response.status === 200) {
      try {
        setListContractStatus(response.data);

        let arr = [];
        response.data.forEach((element) => {
          arr.push(element.statusName);
        });
        setIndexListContact(arr.indexOf(project.contractStatus.statusName));
      } catch (error) { }
      setShowLoading(() => false);
    } else {
      toast.error("Lấy danh sách hiện trạng thất bại!");
      setShowLoading(() => false);
    }
  };

  const updateWork = async () => {
    setShowLoading(true);

    const response = await Axios.Project.update(project._id, projectEdit);
    if (response.status === 200) {
      handleCloseDialog();
      toast.success("Cập nhật dự án thành công");
      setOpen(false);
      if (getOneProject === undefined) {
        getAllWork(0, 10, -1, "");
      } else {
        getOneProject(project._id);
      }
    } else {
      handleCloseDialog();
      setErrors({
        NameOfProject: "Tên dự án đã tồn tại!",
      });
      toast.error("Cập nhật dự án thất bại!");
      setShowLoading(() => false);
    }
  };

  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({
    NameOfProject: "",
    Investor: "",
    Clue: "",
    TechnicalProgress: "",
    CurrentStatus: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateCreateProject(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      handleClickOpen();
    } else {
      toast.error("Vui lòng điền đầy đủ thông tin!");
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateCreateProject(name, value) });
  };
  const handleReset = () => {
    setProjectEdit({
      projectName: "",
      intermediary: "",
      technicalProgress: "",
      processingTime: "",
      issue: "",
      investor: "",
      // contractStatus: "",
      installationProgress: "",
      solution: "",
      projectStatus: "",
    });
    setErrors({});
  };

  const handleChange = (e, target) => {
    setProjectEdit({
      ...projectEdit,
      contractStatus: target?._id,
    });
  };

  const renderOption = (props, option) => (
    <li {...props}
      className={
        option.statusName === 'Cancel' ? 'red-background' :
          option.statusName === 'Pending' ? 'blue-background' :
            'no-background'
      }
    >
      {option.statusName}
    </li>
  );

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <form onSubmit={handleOnSubmit}>
          <DialogTitle
            sx={{ fontSize: 20.28, textAlign: 'center', fontWeight: 800 }}
          >
            Cập Nhật Dự Án
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2} sx={{ maxWidth: 800 }}>
              <Box sx={{ mt: 1, display: "flex", width: "100%" }}>
                <Box sx={{ width: "49%", ml: 1 }}>
                  {/* isMultilineName */}
                  {isMultilineName === false ? (
                    <TextField
                      component="div"
                      name="projectName"
                      label="Tên Dự Án"
                      onFocus={() => setIsMultilineName(true)}
                      value={projectEdit?.projectName}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          projectName: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tên Dự Án?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.projectName ? true : false}
                      helperText={
                        errors.projectName ? (
                          errors.projectName
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  ) : (
                    <TextField
                      component="div"
                      name="projectName"
                      label="Tên Dự Án"
                      onBlur={() => setIsMultilineName(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.projectName}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          projectName: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tên Dự Án?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.projectName ? true : false}
                      helperText={
                        errors.projectName ? (
                          errors.projectName
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                      autoFocus
                    />
                  )}
                  {/* isMultilineIntermediary */}
                  {isMultilineIntermediary === false ? (
                    <TextField
                      component="div"
                      // name="intermediary"
                      label="Đầu Mối"
                      onFocus={() => setIsMultilineIntermediary(true)}
                      value={projectEdit?.intermediary}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          intermediary: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tên Đầu Mối?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.intermediary ? true : false}
                      helperText={
                        errors.intermediary ? (
                          errors.intermediary
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="intermediary"
                      label="Đầu Mối"
                      onBlur={() => setIsMultilineIntermediary(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.intermediary}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          intermediary: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tên Đầu Mối?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.intermediary ? true : false}
                      helperText={
                        errors.intermediary ? (
                          errors.intermediary
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  )}

                  {/* isMultilineTechnicalProgres */}
                  {isMultilineTechnicalProgres === false ? (
                    <TextField
                      component="div"
                      // name="technicalProgress"
                      label="Tiến Độ Kỹ Thuật"
                      onFocus={() => setIsMultilineTechnicalProgress(true)}
                      value={projectEdit?.technicalProgress}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          technicalProgress: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tiến Độ Kỹ Thuật?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.technicalProgress ? true : false}
                      helperText={
                        errors.technicalProgress ? (
                          errors.technicalProgress
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="technicalProgress"
                      label="Tiến Độ Kỹ Thuật"
                      onBlur={() => setIsMultilineTechnicalProgress(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.technicalProgress}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          technicalProgress: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tiến Độ Kỹ Thuật?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.technicalProgress ? true : false}
                      helperText={
                        errors.technicalProgress ? (
                          errors.technicalProgress
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}
                  {/* isMultilineProcessingTime */}
                  {isMultilineProcessingTime === false ? (
                    <TextField
                      component="div"
                      // name="processingTime"
                      label="Thời Gian Xử Lý"
                      onFocus={() => setIsMultilineProcessingTime(true)}
                      value={projectEdit?.processingTime}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          processingTime: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Thời Gian Xử Lý?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.processingTime ? true : false}
                      helperText={
                        errors.processingTime ? (
                          errors.processingTime
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="processingTime"
                      label="Thời Gian Xử Lý"
                      onBlur={() => setIsMultilineProcessingTime(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.processingTime}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          processingTime: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Thời Gian Xử Lý?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.processingTime ? true : false}
                      helperText={
                        errors.processingTime ? (
                          errors.processingTime
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}

                  {/* isMultilineIssue */}
                  {isMultilineIssue === false ? (
                    <TextField
                      component="div"
                      // name="issue"
                      label="Tồn Đọng Kỹ Thuật / Thiết Bị"
                      onFocus={() => setIsMultilineIssue(true)}
                      value={projectEdit?.issue}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          issue: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tồn Đọng?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.issue ? true : false}
                      helperText={
                        errors.issue ? (
                          errors.issue
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="issue"
                      label="Tồn Đọng Kỹ Thuật / Thiết Bị"
                      onBlur={() => setIsMultilineIssue(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.issue}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          issue: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tồn Đọng?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.issue ? true : false}
                      helperText={
                        errors.issue ? (
                          errors.issue
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}
                </Box>

                <Box sx={{ width: "49%" }}>
                  {/* isMultilineInvestor */}
                  {isMultilineInvestor === false ? (
                    <TextField
                      onFocus={() => setIsMultilineInvestor(true)}
                      component="div"
                      // name="investor"
                      label="Chủ Đầu Tư"
                      value={projectEdit?.investor}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          investor: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Chủ Đầu Tư?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.investor ? true : false}
                      helperText={
                        errors.investor ? (
                          errors.investor
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="investor"
                      label="Chủ Đầu Tư"
                      onBlur={() => setIsMultilineInvestor(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.investor}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          investor: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Chủ Đầu Tư?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.investor ? true : false}
                      helperText={
                        errors.investor ? (
                          errors.investor
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                      onInput={handleOnInput}
                    />
                  )}

                  {/* isMultilineContractStatus */}
                  {listContractStatus.length > 0 &&
                    <Autocomplete
                      name="listContractStatus"
                      options={listContractStatus}
                      defaultValue={listContractStatus[indexListContact]}
                      getOptionLabel={(option) => option?.statusName}
                      onChange={handleChange}
                      renderOption={renderOption}
                      renderInput={(params) => (
                        <TextField
                          name="contractStatus"
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={"bx:code-block"} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          autoComplete="none"
                          variant="standard"
                          label="Hiện trạng"
                          placeholder="Chọn hiện trạng"
                          sx={
                            (styleInputFullField,
                            {
                              ...styleInputFullField,
                              ml: 2,
                              width: "90%",
                            })
                          }
                          error={errors.contractStatus ? true : false}
                          helperText={
                            errors.contractStatus ? (
                              errors.contractStatus
                            ) : (
                              <Box component="span" sx={{ color: "white" }}>
                                &emsp;
                              </Box>
                            )
                          }
                        />
                      )}

                    />
                  }
                  {/* isMultilineInstallationProgress */}
                  {isMultilineInstallationProgress === false ? (
                    <TextField
                      component="div"
                      // name="installationProgress"
                      label="Tiến Độ Lắp Đặt"
                      onFocus={() => setIsMultilineInstallationProgress(true)}
                      value={projectEdit?.installationProgress}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          installationProgress: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tiến Độ?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.installationProgress ? true : false}
                      helperText={
                        errors.installationProgress ? (
                          errors.installationProgress
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="installationProgress"
                      label="Tiến Độ Lắp Đặt"
                      onBlur={() => setIsMultilineInstallationProgress(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.installationProgress}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          installationProgress: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Tiến Độ?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.installationProgress ? true : false}
                      helperText={
                        errors.installationProgress ? (
                          errors.installationProgress
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}

                  {/* isMultilineSolution */}
                  {isMultilineSolution === false ? (
                    <TextField
                      component="div"
                      // name="solution"
                      label="Hướng Xử Lý"
                      onFocus={() => setIsMultilineSolution(true)}
                      value={projectEdit?.solution}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          solution: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Hướng Xử Lý?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.solution ? true : false}
                      helperText={
                        errors.solution ? (
                          errors.solution
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="solution"
                      label="Hướng Xử Lý"
                      onBlur={() => setIsMultilineSolution(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      value={projectEdit?.solution}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          solution: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Hướng Xử Lý?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.solution ? true : false}
                      helperText={
                        errors.solution ? (
                          errors.solution
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}

                  {/* isMultilineProjectStatus */}
                  {isMultilineProjectStatus === false ? (
                    <TextField
                      component="div"
                      // name="projectStatus"
                      label="Giao Hàng - Lắp Đặt"
                      onFocus={() => setIsMultilineProjectStatus(true)}
                      value={projectEdit?.projectStatus}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          projectStatus: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Giao Hàng?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.projectStatus ? true : false}
                      helperText={
                        errors.projectStatus ? (
                          errors.projectStatus
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  ) : (
                    <TextField
                      component="div"
                      // name="projectStatus"
                      label="Giao Hàng - Lắp Đặt"
                      onBlur={() => setIsMultilineProjectStatus(false)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      multiline
                      rows={5}
                      inputRef={(input) => input && input.focus()}
                      // autoFocus
                      value={projectEdit?.projectStatus}
                      onChange={(e) =>
                        setProjectEdit({
                          ...projectEdit,
                          projectStatus: e.target.value,
                        })
                      }
                      variant="standard"
                      placeholder="Giao Hàng?"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={"material-symbols:edit-note-sharp"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.projectStatus ? true : false}
                      helperText={
                        errors.projectStatus ? (
                          errors.projectStatus
                        ) : (
                          <Box component="span" sx={{ color: "white" }}>
                            &emsp;
                          </Box>
                        )
                      }
                      onInput={handleOnInput}
                      sx={
                        (styleInputFullField,
                          { ...styleInputFullField, ml: 2, width: "90%" })
                      }
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: "0 24px 12px 24px" }}>
            {projectEdit.projectName !== "" ||
              projectEdit.intermediary !== "" ||
              projectEdit.technicalProgress !== "" ||
              projectEdit.processingTime !== "" ||
              projectEdit.issue !== "" ||
              projectEdit.investor !== "" ||
              projectEdit.contractStatus !== "" ||
              projectEdit.installationProgress ||
              projectEdit.solution ||
              projectEdit.projectStatus !== "" ||
              Object.keys(errors).length !== 0 ? (

              <CustomButton
                sx={{ background: "#f78b25" }}
                onClick={handleReset} variant="contained"> Làm mới</CustomButton>

            ) : (
              ""
            )}
            <CustomButton
              type="submit"
              variant="contained"> Cập Nhật</CustomButton>
            <CustomButton
              sx={{ background: "#f72533" }}
              onClick={handleClose} variant="contained">Hủy</CustomButton>
          </DialogActions>
        </form>
      </Dialog>

      {openDialog &&
        <div>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle width={400} id="alert-dialog-title">
              <Typography
                component="div"
                sx={{ fontSize: 20 }}
                variant="subtitl1"
              >
                {"Xác nhận cập nhật?"}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  component="span"
                  sx={{ fontSize: 20, fontFamily: "bold" }}
                >
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {/* {numSelected} */}
                  </span>{" "}
                  {/* {nameWork} */}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={updateWork}>Xác nhận</CustomButton>
              <CustomButton onClick={handleCloseDialog} sx={{ background: 'red' }} autoFocus>
                Huỷ
              </CustomButton>
            </DialogActions>
          </Dialog>
        </div>
      }
      <Backdrop
        sx={{ color: "#fff", background: 'red', width: "5%",height:'10vh', margin: 'auto', zIndex: 9999 }}
        open={showLoading}
      >
        <CircularProgress color="primary"  />
      </Backdrop>
    </>
  );
};

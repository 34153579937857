import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Backdrop,
  CircularProgress,
  Divider,
} from "@mui/material";
// component
import Iconify from "../../../../../components/iconify";
import Axios from "../../../../../utils/Axios";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import SearchInput from "../search/SearchInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import PositionSearchBox from "../search/PositionSearchBox";



const CustomButton = styled
  (Button)(({ theme }) => ({
    backgroundColor: '#5351ed', // màu nền button
    color: 'white', // màu chữ button
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#f7f9fa', // màu nền khi hover vào button
      color: 'red', // màu chữ khi hover vào button
    },
  }));

// ----------------------------------------------------------------------
const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#f7ebf5",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#f7ebf5",
  },
};

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
  marginTop:'80px'

}));

// ----------------------------------------------------------------------

PositionListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function PositionListToolbar({
  numSelected,
  selected,
  onChange,
  page,
  limit,
  handlePositionUpdate,
  listRole,
  setListPosition
}) {
  const [searchList, setSearchList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isFocusPopup, setFocusPopup] = useState(false);
  const [showLoading, setShowLoading] = useState(false);



  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogDelete = () => {
    deletePosition();
    setOpenDialog(false);
  };
  useEffect(() => {
    if (numSelected === 0) {
      searchAllPosition();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numSelected])


  const searchAllPosition = async () => {
    const response = await Axios.Position.getAll(0, 20, -1, "", "");
    if (response.status === 200) {
      setSearchList(response.data.listItem);
      setListPosition(response.data.listItem)
    }
  };

  const handleSearch = async (e) => {
    const response = await Axios.Position.getAll(0, 20, -1, e.target.value, "");
    if (response.status === 200) {
      setSearchList(response.data.listItem);
      setListPosition(response.data.listItem)
    }


  };

  const handleOpen = (event) => {
    searchAllPosition();
    setOpen(event.currentTarget);
  };

  const handleClose = () => {

    if (!isFocusPopup) {
      setOpen(false);
    }
  };
  const handleClose2 = (event) => {

    if (!isFocusPopup) {
      setOpen(false);
      setFocusPopup(false);
    } else {
      setFocusPopup(false);
    }
  };

  const deletePosition = async () => {
    setShowLoading(() => true);
    const response = await Axios.Position.delete({ listId: selected });
    if (response.status === 200) {
      setShowLoading(() => false);
      onChange(page, limit, -1, "");
      toast.success("Xoá chức vụ thành công!");
    } else {
      if (response.status === 400 && response.code === 29) {
        setShowLoading(() => false);
        toast.error("Chức vụ đang có người dùng sử dụng!");
      } else {
        setShowLoading(() => false);
        toast.error("Xoá chức vụ thất bại!");
      }
    }
  };

  return (
    <>
      <StyledRoot
        sx={{
          ...(numSelected > 0 && {
            borderRadius: 50,
          }),
        }}
      >
        {numSelected > 0 && listRole.includes('quan-ly-chuc-vu-xoa') ? (
          <Typography component="div" variant="subtitl1" sx={{ fontSize: 20, fontWeight: 600, fontFamily: "sans-serif" }}>
            <span style={{  fontWeight: "bold" }}>
              {numSelected}
            </span>{" "}
            chức vụ đã chọn
          </Typography>
        ) : (
          <Box onFocus={handleOpen} onBlur={handleClose}>
            <SearchInput position="start" onInput={handleSearch} onBlur={handleClose2} />
            <Card
              hidden={!open}
              className="cardSearch"
              sx={{
                position: "fixed",
                p: 0,
                borderRadius: 2,
                boxShadow: "0 0 8px #976235",
                maxHeight: "45vh",
                zIndex: 99999,
                cursor: "pointer"
              }}
            >
              <Box
                onBlur={handleClose}
                onMouseEnter={() => setFocusPopup(true)}
                onMouseLeave={handleClose2}
              >
                <CardHeader
                  title={
                    <Typography fontWeight={600} fontSize={16}>
                      {"Danh sách tìm kiếm"}
                    </Typography>
                  }
                  sx={{ pb: 2, height: 24 }}
                />
                <Divider />

                <CardContent
                  sx={{
                    p: "0 !important",
                    maxHeight: "calc(40vh - (14*2px))",
                    overflow: "auto",
                    ...scrollbar,
                  }}
                >
                  {searchList.length !== 0 ? "" : <span style={{ pb: 2, marginLeft: '5%' }}>( Thông tin tìm kiếm không có! )<p></p></span>}
                  {searchList?.map((searchData, index) => (
                    <PositionSearchBox key={index} searchData={searchData} handlePositionUpdate={handlePositionUpdate} setOpen={setOpen} />
                  ))}
                </CardContent>
              </Box>
            </Card>
          </Box>
        )}

        {numSelected > 0 && listRole.includes('quan-ly-chuc-vu-xoa') ? (
          <div>
            <Tooltip title="Xoá">
              <Button
                sx={{ color: "red", border: "none" }}
                onClick={handleClickOpen}
              >
                <Iconify icon="eva:trash-2-fill" />
              </Button>
            </Tooltip>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle width={400} id="alert-dialog-title">
                <Typography
                  component="div"
                  sx={{ fontSize: 20 }}
                  variant="subtitl1"
                >
                  {"Xác nhận xoá?"}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography
                    component="span"
                    sx={{ fontSize: 20, fontFamily: "bold", }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {numSelected}
                    </span>{" "}
                    chức vụ đã chọn
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <CustomButton onClick={handleCloseDialogDelete}>Xác nhận</CustomButton>
                <CustomButton onClick={handleCloseDialog} sx={{ background: "#f72533" }} autoFocus>
                  Huỷ
                </CustomButton>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          ""
        )}
      </StyledRoot>
      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
}

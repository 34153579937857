import User from "./User/User";
import Project from "./User/Project";
import Timekeeping from "./User/Timekeeping";
import Statistical from "./Admin/Statistical";
import Permission from "./Admin/Permission";
import Position from "./Admin/Position";
import TimekeepingManagement from "./Admin/TimekeepingManagement";
import LeaveDay from './Admin/LeaveDay';
import Holiday from "./Admin/Holiday";
import WorkProject from "./User/WorkProject";
import TaskProject from "./User/TaskProject";
import SaleProject from "./User/SaleProject";

const Axios = {
    User,
    Project,
    Timekeeping,
    Statistical,
    Permission,
    Position,
    TimekeepingManagement,
    LeaveDay,
    Holiday,
    WorkProject,
    TaskProject,
    SaleProject
};
  
export default Axios;
/* eslint-disable no-useless-concat */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DialogContentText,
  Divider,
} from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/vi';
import "./../index.scss"
import { Box } from "@mui/system";


export const TimekeepingHoliday = ({ open, setOpen, data }) => {
  const handleClose = () => {
    setOpen(false);
  };

  function getMonthFromStringToday(dateString) {

    var date = new Date(dateString);
    let day = date.getDate();

    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    let year = date.getFullYear();

    if (month > 12) {
      year = year + 1;
      month = '01';
    }
    var result = "Nghỉ lễ " + "ngày " + day + " tháng " + month + " năm " + year;
    return result;
  }



  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="800">
        <DialogTitle sx={{ textAlign: 'center', fontSize: 20, fontWeight: 800 }}> {getMonthFromStringToday(data?.start)}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText />
          <Box sx={{ maxWidth: 500, minWidth: 500 }}>
            <Box sx={{  fontWeight: 900 }}><span >Nội dung: </span>( {data?.title} )</Box>
            {data?.note?.includes('holiday-') ? <Box sx={{ fontWeight: 900, mt: 2 }}><span >Ngày lễ: </span>{data?.note ? data?.note.slice(8) : "( Không có lý do )"}</Box> :
              <Box sx={{ fontWeight: 900, mt: 2 }}><span >Lý do: </span>{data?.note ? data?.note : "( Không có lý do )"}</Box>}

          </Box>
        </DialogContent>

      </Dialog>
    </>
  );
}


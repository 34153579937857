import styled from "tachyons-components";

export const Section = styled('div')`
flex flex-wrap content-center justify-center w-100 h-100 bg-#f4f6fc`;

export const Article = styled('div')`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;

export const list = [
  {
    prop: "spokes",
    name: "Spokes"
  }
];

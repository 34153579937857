// import { format } from "date-fns";
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import SearchInput from "../../components/views/sections/@dashboard/search/SearchInput";
import LeaveDayManagementSearchBox from "../../components/views/sections/@dashboard/search/LeaveDayManagementSearchBox";
import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { gridSpacing } from "../../utils/store/constant";
import Axios from "../../utils/Axios";

const scrollbar = {
  "::-webkit-scrollbar": {
    width: "5px",
  },
  ":hover::-webkit-scrollbar-thumb": {
    " -webkit-border-radius": "5px",
    borderRadius: "5px",
    background: "#f7ebf5",
  },
  "::-webkit-scrollbar-thumb:window-inactive": {
    background: "#f7ebf5",
  },
};


export default function LeaveDetailPage(props) {
  const { _id } = useParams();
  const [open, setOpen] = useState(false);
  const [isFocusPopup, setFocusPopup] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [user, setUser] = useState([]);


  useEffect(() => {
    getOneLeaveDay(_id);
  }, [_id]);

  const getOneLeaveDay = async (_id) => {
    if (_id !== undefined) {
      setShowLoading(() => true);
      const response = await Axios.LeaveDay.getLeaveDays(_id);
      if (response.status === 200) {
        setShowLoading(() => false);
        setUser(response.data.listItem);
      } else {
        setShowLoading(() => false);
        setUser(null);
      }
    }
  };

  const handleOpen = (event) => {
    searchAll();
    setOpen(event.currentTarget);
  };

  const searchAll = async () => {
    const response = await Axios.LeaveDay.getAllUserWithTimekeepingPermission(0, 30, -1, "", "");
    if (response.status === 200) {
      setSearchList(response.data.listItem);
    } else {
    }
  };

  const handleSearch = async (e) => {
    const response = await Axios.LeaveDay.getAllUserWithTimekeepingPermission(
      0,
      100,
      -1,
      e.target.value,
      ""
    );
    if (response.status === 200) {
      setSearchList(response.data.listItem);
    } else {
    }
  };

  const handleClose = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
    }
  };

  const handleClose2 = (event) => {
    if (!isFocusPopup) {
      setOpen(false);
      setFocusPopup(false);
    } else {
      setFocusPopup(false);
    }
  }


  return (
    <>
      <Helmet>
        <title> Quản lý ngày phép </title>
      </Helmet>
    <div style={{width:'100%'}}>
      <Box onFocus={handleOpen} onBlur={handleClose} >
        <SearchInput onInput={handleSearch} sx={{ mt: 14 }} />
        <Card
          className="cardSearch"
          hidden={!open}
          sx={{
            position: "fixed",
            p: 0,
            borderRadius: 2,
            boxShadow: "0 0 8px #976235",
            maxHeight: "45vh",
            zIndex: 99999,
          }}
        >
          <Box
            onBlur={handleClose}
            onMouseEnter={() => setFocusPopup(true)}
            onMouseLeave={handleClose2}
          >
            <CardHeader
              title={
                <Typography fontWeight={600} fontSize={16} >
                  {"Danh sách tìm kiếm"}
                </Typography>
              }
              sx={{ pb: 2, height: 24 }}
            />
            <Divider />
            <CardContent
              sx={{
                p: "0 !important",
                maxHeight: "calc(40vh - (14*2px))",
                overflow: "auto",
                ...scrollbar,
              }}
            >
              {searchList.length !== 0 ? "" : <span style={{ marginLeft: '5%', pb: 2 }}>( Thông tin tìm kiếm không có! )<p></p></span>}
              {searchList?.map((searchData, index) => (
                <LeaveDayManagementSearchBox key={index} searchData={searchData} />
              ))}
            </CardContent>
          </Box>
        </Card>
      </Box>
      <p></p>
      <p >&nbsp;</p>
      <Box
        sx={{
          width: "95%",
          borderRadius: "12px",
          boxShadow: 5,
          padding: 2,
          color:localStorage.getItem("theme")==="dark"?"white":'black',
          margin:'auto'
        }}
      >
        <Grid item xs={12} sm={12} md={12} >
          <Box sx={{ fontWeight: 900, mb: 2, textAlign: 'center', fontSize: 25, fontFamily: "-moz-initial" }}>Họ tên: <span>{user[0]?.userId?.fullname}</span></Box>
          <Divider sx={{ mb: 1.5 }} />
        </Grid>
        <div
          style={{
            maxHeight: "600px",
            overflowY: "auto",
          }}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                {user.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} sx={{ textAlign: 'center', mb: 3, fontSize: '20px', }}>
                    <Box sx={{ fontWeight: 900, mt: 0.2, fontFamily: "-moz-initial" }}>Năm:  <span >{item.year}</span></Box>
                    <Box sx={{ ml: 2, fontWeight: 900, mt: 0.8, fontFamily: "-moz-initial" }}> Nghỉ phép hàng năm: ( <span >{item.annualLeaveDay}</span> )</Box>
                    <Box sx={{ ml: 2, fontWeight: 900, mt: 0.8, fontFamily: "-moz-initial" }}> Số ngày đã nghỉ phép:( <span >{item.numberLeaveDay}</span> )</Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>

      </Box>
      </div>


      <Backdrop
        sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50%", margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="primary" sx={{ zIndex: 9 }} />
      </Backdrop>
    </>
  );
}

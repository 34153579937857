import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

export default function TimekeepingSearchBox({ searchData, setOpen, handleTimekeeping }) {
  const { user } = searchData;
  const customization = useSelector((state) => state.customization);

  return (
    <>
      <Box
        className={customization.mode}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            cursor: 'pointer'
          }}
          className="searchBox"
          onClick={(event) => handleTimekeeping(event, searchData) & setOpen(false)}
        >
          <Box display={"flex"} maxWidth="70%">
            <Box sx={{ maxWidth: "99%", ml: 2, }}>
              <Typography
                sx={{ fontWeight: 600 }}
                variant="subtitle2"
                noWrap
                fontSize={16}
              >
                {user.fullname}
              </Typography>

              <Typography variant="body3" noWrap>
                <Box display={"flex"} maxWidth="150%">
                  <Box sx={{ maxWidth: "135%", }}>
                    <Typography
                      noWrap
                      fontSize={16}
                    >
                      {user.email}
                    </Typography>

                  </Box>
                </Box>

              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

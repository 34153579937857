// assets
import { IconUsers, IconLayoutGridAdd, IconBrowserCheck, IconDiscountCheck, IconAperture, IconReplace, IconClockPlay, IconTent, IconBrandAsana, IconBrandVinted, IconFileReport, IconFileInfo, IconAdjustmentsAlt } from '@tabler/icons-react';

// constant
const icons = {
    IconUsers, IconLayoutGridAdd, IconBrowserCheck, IconDiscountCheck, IconAperture, IconReplace, IconClockPlay, IconTent, IconBrandAsana, IconBrandVinted, IconFileReport, IconFileInfo, IconAdjustmentsAlt
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    type: 'group',
    children: [
        {
            id: 'quan-tri-thong-tin',
            title: 'Quản trị thông tin',
            type: 'collapse',
            icon: icons.IconFileInfo,
            children: [
                {
                    id: 'quan-ly-du-an',
                    title: 'Quản lý dự án',
                    type: 'item',
                    url: '/quan-ly-du-an',
                    icon: icons.IconLayoutGridAdd,
                    breadcrumbs: false
                },
                {
                    id: 'quan-ly-cham-cong',
                    title: 'Chấm công',
                    type: 'item',
                    url: '/quan-ly-cham-cong',
                    icon: icons.IconDiscountCheck,
                    breadcrumbs: false
                },


                {
                    id: 'quan-ly-du-an-sale',
                    title: 'Quản lý dự án sale',
                    type: 'item',
                    url: '/quan-ly-du-an-sale',
                    icon: icons.IconBrandVinted,
                    breadcrumbs: false
                },
                {
                    id: 'quan-ly-tien-do',
                    title: 'Quản lý tiến độ',
                    type: 'item',
                    url: '/quan-ly-tien-do',
                    icon: icons.IconBrandAsana,
                    breadcrumbs: false
                },
            ],
        },
        {
            id: 'quan-tri-he-thong',
            title: 'Quản trị hệ thống',
            type: 'collapse',
            icon: icons.IconAdjustmentsAlt,
            children: [
                {
                    id: 'quan-ly-nguoi-dung',
                    title: 'Quản lý người dùng',
                    type: 'item',
                    url: '/quan-ly-nguoi-dung',
                    icon: icons.IconUsers,
                    breadcrumbs: false
                },
                {
                    id: 'quan-ly-chuc-vu',
                    title: 'Quản lý chức vụ',
                    type: 'item',
                    url: '/quan-ly-chuc-vu',
                    icon: icons.IconReplace,
                    breadcrumbs: false
                },
                {
                    id: 'quan-ly-ngay-le',
                    title: 'Quản lý ngày lễ',
                    type: 'item',
                    url: '/quan-ly-ngay-le',
                    icon: icons.IconTent,
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'nhan-su',
            title: 'Nhân sự',
            type: 'collapse',
            icon: icons.IconFileReport,
            children: [
                {
                    id: 'quan-ly-phe-duyet',
                    title: 'Phê duyệt',
                    type: 'item',
                    url: '/quan-ly-phe-duyet',
                    icon: icons.IconClockPlay,
                    breadcrumbs: false
                },
                {
                    id: 'bao-cao-cham-cong',
                    title: 'Báo cáo chấm công',
                    type: 'item',
                    url: '/bao-cao-cham-cong',
                    icon: icons.IconBrowserCheck,
                    breadcrumbs: false
                },
                {
                    id: 'quan-ly-ngay-phep',
                    title: 'Quản lý ngày phép',
                    type: 'item',
                    url: '/quan-ly-ngay-phep',
                    icon: icons.IconAperture,
                    breadcrumbs: false
                },
               
            ]
        },
    ]
};

export default utilities;
import Axios from "./../../../../utils/Axios/Axios";


class Holiday {

  static getAll = async (page, limit, sortOrder, search, sortProperty) =>
    Axios.get("/api/v1/holiday", {
      page, limit, sortOrder, search, sortProperty
    });

    static create = async (data) =>
    Axios.post("/api/v1/holiday", data);

    static update = async (id,data) => Axios.put("/api/v1/holiday/"+id,data);


    static delete = async (data) => Axios.delete("/api/v1/holiday",data);

}

export default Holiday;
import { combineReducers } from 'redux';

// reducer import
import { customizationReducer, customizationReducerApprove, customizationReducerListRole } from './customizationReducer';


// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    userLoginDetail: customizationReducerListRole,
    approve: customizationReducerApprove
});

export default reducer;
